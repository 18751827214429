import { AgmCoreModule } from "@agm/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CsvModule } from "@ctrl/ngx-csv";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { QuillModule } from "ngx-quill";
import { SharedModule } from "./../../shared/shared.module";
import { PaymentsComponent } from "./components/payments/payments.component";
import { PaymentsRoutingModule } from "./payments-routing.module";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		PaymentsRoutingModule,
		SharedModule,
		NgxDatatableModule,
		HttpClientModule,
		CsvModule,
		QuillModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		AgmCoreModule.forRoot({
			apiKey: "AIzaSyDa1UayxKz_79DUT2nmE2wHAuBwGdZVL3Y", // Muhimma Admin Account
			// apiKey: "AIzaSyDP8ctBV6hZ-3zSmr7enXzQTHn5jFd_guc",
			libraries: ["drawing"],
		}),
	],
	declarations: [PaymentsComponent],
	exports: [PaymentsComponent],
})
export class PaymentsModule {}
