import { SettingService } from "./../services/setting.service";
import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { ProjectService } from "../services/projectService";
import { ToastrService } from "ngx-toastr";
import { Page } from "../model/page";
import { Setting } from "../model/Setting";

@Component({
	selector: "app-settings",
	templateUrl: "./settings.component.html",
	styleUrls: ["./settings.component.css"],
})
export class SettingsComponent implements OnInit {
	loadingIndicator = true;
	message: any = "";
	model: any = {
		id: 0,
		name: "",
		country_id: null,
		valuear: "",
		valueen: "",
		created_at: new Date(),
		updated_at: new Date(),
		is_deleted: false,
	};
	rows: any[];
	temp = [];
	settings: Setting[];
	id: number = 0;
	country: number = 0;
	innerWidth = 0;
	constructor(
		private settingService: SettingService,
		private toastr: ToastrService
	) {
		this.model = this.GetSetting(0);
	}

	ngOnInit() {
		this.innerWidth = window.innerWidth;
		this.GetSettings();
	}
	GetSettings() {
		this.settingService
			.getSettings()
			.then((data) => {
				this.rows = data;
				this.temp = data;
				this.settings = data;
				this.loadingIndicator = false;
			})
			.catch((e) => this.toastr.error("Could Not Retrive Settings!"));
	}

	filter() {
		this.rows =
			this.country == 0
				? this.settings
				: this.settings.filter((s) => s.country_id == this.country);
	}

	GetSetting(id) {
		//console.log(id);
		if (id === 0)
			this.model = {
				id: 0,
				name: "",
				country_id: null,
				valuear: "",
				valueen: "",
				created_at: new Date(),
				updated_at: new Date(),
				is_deleted: false,
			};
		else this.model = this.rows.find((r) => r.id === id);
	}

	postForm() {
		this.settingService
			.saveSetting(this.model)
			.then((data) => {
				this.model.id = data["id"];
				this.toastr.success("Saved Successfully!");
			})
			.catch((err) => {
				this.toastr.error("Error in saving setting");
				console.error(err);
			});
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();

		// filter our data
		const temp = this.temp.filter(function (d) {
			return d.name.toLowerCase().indexOf(val) !== -1 || !val;
		});

		// update the rows
		this.rows = temp;
	}
}
