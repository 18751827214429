// import { Sprint } from './../model/sprint';
// import { Sprint } from 'src/app/model/sprint';
import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeStyle, SafeUrl } from "@angular/platform-browser";
import { Sprint } from "../../model/sprintModel";
// import { Page} from '../model/page';
// import { ProjectService } from './../services/projectService';
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SprintService } from "../../service/sprintService";

@Component({
	selector: "app-sprint-detail",
	templateUrl: "./sprint-detail.component.html",
	styleUrls: ["./sprint-detail.component.css"],
})
export class SprintDetailComponent implements OnInit {
	Sprint: Sprint = null;
	url: SafeUrl = null;
	email = "";
	backgroundImage: SafeStyle = null;
	loading: any = true;
	id: any = "";
	innerWidth = 0;
	taskIds = [];
	constructor(
		private sprintService: SprintService,
		private activatedRoute: ActivatedRoute,
		private sanitizer: DomSanitizer,
		private toastr: ToastrService,
		private router: Router
	) {}

	ngOnInit() {
		this.innerWidth = window.innerWidth;
		this.activatedRoute.queryParams.subscribe((params) => {
			this.id = this.activatedRoute.snapshot.paramMap.get("id");
			console.log(this.id);
			this.GetSprint();
		});
	} // end on init
	private GetSprint() {
		this.sprintService.getSprint(this.email, this.id).then((sprint) => {
			console.log("sprint>>", sprint);

			if (sprint.finishes_at == "infinity")
				sprint.finishes_at = new Date(2020, 1, 1);

			this.Sprint = sprint;
			var getID: any = this.Sprint.id;
			localStorage.setItem("localSprintID", getID);

			localStorage.setItem("saveTotalQuota", sprint.max_submission_count);

			if (
				this.Sprint.backgroundurl === null ||
				this.Sprint.backgroundurl === ""
			)
				this.Sprint.backgroundurl = "/assets/images/bg.png";

			if (this.Sprint.iconurl === null || this.Sprint.iconurl === "")
				this.Sprint.iconurl = "/assets/images/icon.png";

			this.backgroundImage = this.sanitizer.bypassSecurityTrustStyle(
				"url(" + this.Sprint.backgroundurl + ")"
			);
			console.log(this.backgroundImage);
			this.url = this.sanitizer.bypassSecurityTrustUrl(
				this.Sprint.backgroundurl
			);
			this.loading = false;

			this.Sprint.tasks.filter((task) => {
				this.taskIds.push(task.id);
			});
		});
	}
	rewardSaved() {
		this.GetSprint();
	}
	getWidthTotals(submitted, accepted, rejected, a) {
		let width = 0;
		let total = accepted * 1 + rejected * 1 + submitted * 1;
		if (total === 0) return 0;
		if (a === 1) width = submitted / total; //;
		if (a === 2) width = accepted / total; //;
		if (a === 3) width = rejected / total; //;

		width = Math.ceil(width * 100);
		return width;
	}
	getStatus(status) {
		if (status === 0)
			return '<i class="fas fa-circle text-primary"></i> Draft';

		if (status === 1)
			return '<i class="fas fa-circle text-success"></i> Available';
		if (status === 2)
			return '<i class="fas fa-circle text-danger"></i> Not Available';
	}
	getCounty(country) {
		if (country === 206)
			return '<img src="/assets/icons/uae-round.png" width="22px"> United Arab Emirates';
		if (country === 166)
			return '<img src="/assets/icons/ksa-round.png" width="22px"> Saudi Arabia';
		if (country === 58)
			return '<img src="/assets/icons/egypt-round.png" width="22px"> Egypt';
	}
	stripHtml(text) {
		return text ? String(text).replace(/<[^>]+>/gm, "") : "";
	}
	changeStatus(status) {
		this.Sprint.status = status;
		this.sprintService
			.saveProject(this.Sprint)
			.then((data) => {
				// this.Sprint = data;
				this.GetSprint();
				if (this.Sprint.id !== undefined && this.Sprint.id !== null) {
					//this.router.navigate(['/sprints', this.Sprint.id])
				}

				this.toastr.success(
					this.Sprint.name + " was saved successfully!!!"
				);
			})
			.catch((err) => {
				this.toastr.success(
					"Error in saving Sprint: " + JSON.stringify(err)
				);
				console.log(err);
			});
	}
	deleteProject() {
		if (confirm("Are you sure to delete " + this.Sprint.name)) {
			this.Sprint.is_deleted = true;
			this.sprintService
				.saveProject(this.Sprint)
				.then((data) => {
					this.toastr.success(
						this.Sprint.name + " was deleted successfully!!!"
					);
					this.router.navigate(["/sprints"]);
					this.Sprint = data;
				})
				.catch((err) => {
					this.toastr.success(
						"Error in deleting Sprint: " + JSON.stringify(err)
					);
					console.log(err);
				});
		} //end confirm
	} //end delete function

	inerWindth() {
		this.innerWidth = window.innerWidth;
		return this.innerWidth;
	}
}
//this.toastr.info(JSON.stringify(question));
// if(this.model.rewards === undefined || this.model.rewards === null) this.model.questions = [];
// if(question.id != null && question.id !== 0) this.model.questions.splice(this.model.questions.findIndex(q=>q.id=== question.id), 1, question);
// else this.model.questions.push(question);
//
