import { AgentsComponent } from "./agents/agents.component";
import { DemographicsComponent } from "./demographics/demographics.component";
import { TagsComponent } from "./tags/tags.component";
import { TomtomComponent } from "./tomtom/tomtom.component";
// import { WorkersComponent } from './workers/workers.component';
// import { WorkersListComponent } from './workers/workers-list/workerslist.component';
import { AreaComponent } from "./area/area.component";
import { AreasComponent } from "./areas/areas.component";
import { SettingsComponent } from "./settings/settings.component";
// import { WorkerComponent } from './workers/worker/worker.component';
// import { PaymentRequestsComponent } from './modules/payment/components/paymentrequests/paymentrequests.component';
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AccountsComponent } from "./accounts/accounts.component";
import { LoginComponent } from "./login/login.component";
import { SignupComponent } from "./signup/signup.component";
import { HomeComponent } from "./home/home.component";
import { AuthGuard } from "./auth/auth.guard";
import { TransactionsComponent } from "./Transactions/transactions.component";
// import { SubmissionComponent } from './submission/submission.component';
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ProfileComponent } from "./profile/profile.component";
import { ReportsadminComponent } from "./reportsadmin/reportsadmin.component";
// import { SprintsformComponent } from './modules/sprints/sprintsform.component';

const routes: Routes = [
	{ path: "", redirectTo: "home", pathMatch: "full" },
	{ path: "home", component: HomeComponent, canActivate: [AuthGuard] },
	// { path: 'sprints', component: SprintslistComponent, canActivate: [AuthGuard] },
	// { path: 'sprints/:id', component: SprintDetailComponent , canActivate: [AuthGuard]},
	// { path: 'sprints/:id/edit', component: SprinteditComponent, canActivate: [AuthGuard] },
	// { path: 'sprints/:id/task/:task', component: TaskEditComponent, canActivate: [AuthGuard] },
	// { path: 'sprints/:id/taskform/:form', component: TaskFormComponent, canActivate: [AuthGuard] },
	// { path: 'sprints/:id/submission/:submission', component: SubmissionComponent , canActivate: [AuthGuard]},
	// { path: 'worker/:id/edit', component: WorkerComponent, canActivate: [AuthGuard] },
	// { path: 'workers/:id/edit', component: WorkerComponent, canActivate: [AuthGuard] },
	{ path: "area", component: AreaComponent, canActivate: [AuthGuard] },
	{ path: "areas", component: AreasComponent, canActivate: [AuthGuard] },
	{
		path: "dashboard",
		component: DashboardComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "demographics",
		component: DemographicsComponent,
		canActivate: [AuthGuard],
	},

	{ path: "profile", component: ProfileComponent, canActivate: [AuthGuard] },
	{
		path: "settings",
		component: SettingsComponent,
		canActivate: [AuthGuard],
	},
	{ path: "tomtom", component: TomtomComponent, canActivate: [AuthGuard] },
	{ path: "tags", component: TagsComponent, canActivate: [AuthGuard] },
	{ path: "tags/:id", component: TagsComponent, canActivate: [AuthGuard] },

	{
		path: "reports",
		component: ReportsadminComponent,
		canActivate: [AuthGuard],
	},

	{ path: "login", component: LoginComponent },
	{ path: "signup", component: SignupComponent },
	// { path: 'workers', component: WorkersListComponent , canActivate: [AuthGuard]},
	{ path: "agents", component: AgentsComponent, canActivate: [AuthGuard] },
	// { path: 'users', component: WorkersListComponent , canActivate: [AuthGuard]},
	{
		path: "accounts",
		component: AccountsComponent,
		canActivate: [AuthGuard],
	},
	// { path: 'paymentrequests', component: PaymentRequestsComponent, canActivate: [AuthGuard] },
	{
		path: "transactions",
		component: TransactionsComponent,
		canActivate: [AuthGuard],
	},

	{
		path: "sprints",
		loadChildren: () =>
			import("./modules/sprints/sprints.module").then(
				(m) => m.SprintsformModule
			),
	},
	{
		path: "paymentrequests",
		loadChildren: () =>
			import("./modules/payment/payment.module").then(
				(m) => m.PaymentModule
			),
	},
	{
		path: "workers",
		loadChildren: () =>
			import("./modules/workers/workers.module").then(
				(m) => m.WorkersModule
			),
	},
	{
		path: "users",
		loadChildren: () =>
			import("./modules/workers/workers.module").then(
				(m) => m.WorkersModule
			),
	},
	{
		path: "surveys",
		loadChildren: () =>
			import("./modules/surveys/surveys.module").then(
				(m) => m.SurveysModule
			),
	},
	// {
	// 	path: "payments",
	// 	loadChildren: () =>
	// 		import("./modules/payments/payments.module").then(
	// 			(m) => m.PaymentsModule
	// 		),
	// },
	// {
	//   path: 'home', component: HomeComponent,
	//   children: [
	//     { path: '', component: HomeComponent, pathMatch: 'full' },
	//     { path: 'detail/:id', component: SprintComponent },
	//     { path: 'edit/:id', component: SprinteditComponent },
	//   ]
	// },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
