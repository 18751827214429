import { Component, OnInit } from '@angular/core';
import { TagService } from '../services/tag.service';
import { Tag } from '../model/tag';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.css']
})
export class InsightsComponent implements OnInit {

  tags: Tag[] = null;
  options = {};
  constructor(private _tagService: TagService) { }

  ngOnInit() {
    this.getData();
  }

  getData(){

    this._tagService.getTags().then(data=>{
      this.tags = data as Tag[];

    })
  }

}
