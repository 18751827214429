// import { WorkerItem } from './../model/WorkerItem';
import { WorkerItem } from 'src/app/model/WorkerItem';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
// import { PagedData } from '../model/paged-data';
import { PagedData } from 'src/app/model/paged-data';
// import { Page } from '../model/page';
import { Page } from 'src/app/model/page';
import Amplify, { API, Auth } from 'aws-amplify';
// import aws_exports from './../../aws-exports';
import aws_exports from 'src/aws-exports';
// import { AuthService } from '../auth/auth.service';
import { AuthService } from 'src/app/auth/auth.service';
// import { TransactionItem } from '../model/transactionItem';
import { TransactionItem } from 'src/app/model/transactionItem';
// import { SubmisssionItem } from '../model/submissionItem';
import { SubmisssionItem } from 'src/app/model/submissionItem';
// import { BulkEvaluation } from '../model/PaymentRequestForm';
import { BulkEvaluation } from 'src/app/model/PaymentRequestForm';
// import { Submission } from '../model/tomtom';
import { Submission } from 'src/app/model/tomtom';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ToastrService } from 'ngx-toastr';


/**
 * A server used to mock a paged data result from a server
 */
@Injectable({providedIn: "root"})
export class SubmissionReqService {


	mediaFile:any;
  constructor(private auth: AuthService, public loader: LoadingBarService, public toastr: ToastrService) {

  }
  /**
   * A method that mocks a paged server response
   * @param page The selected page
   * @returns {any} An observable containing the employee data
   */
  // public getResults(page: Page, email: string, worker_id: number, status:number, type: number ): Observable<PagedData<SubmisssionItem>> {
  //     return of(this.getPagedData(page, email, worker_id, status, type));
  // }
  public getById(email: string, id: number): Promise<any> {
    const apiName = 'api_node_1';
    const path = `submission?email=${email}&id=${id}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {
      API.get(apiName, path, myInit)
        .then(response => {
          const data = response.data;
          if (data.response_code == 200) {
            this.loader.complete();
            resolve(data.data);
          }
          else
          {
            return this.toastr.error(data.message);
          }
        })
        .catch(error => {
          console.log('Error in invoiking API:' + error);
          reject(error);
        });
    }); // end promoise
    return promise;
  }
  public getResult(page: Page, email: any, worker_id: number, status: number, type: number, project: number, reportable: boolean, from: Date, to: Date): Promise<any> {
    const pagedData = new PagedData<SubmisssionItem>();
    if(page.size === null || page.size === undefined)page.size=100;
    const apiName = 'api_node_1';
    
    let s = (status ? '&status=' + status : '') + (worker_id ? '&worker=' + worker_id : '') +
      (project ? '&project=' + project : '') + (reportable ? '&reportable=' + reportable : '') +
      (from ? '&from=' + from : '') + (to ? '&to=' + to : '') + (type ? '&type=' + type : '');
    const path = `submissions?1=1${s}&limit=${page.size}&offset=${page.pageNumber * page.size}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    this.loader.start();

    const promise = new Promise((resolve, reject) => {
      API.get(apiName, path, myInit)
        .then(response => {
          const data = response.data;
          this.loader.complete();
          if (data.response_code == 200) {
            page.totalElements = data.data.count;
            page.totalPages = page.totalElements / page.size;
            const start = page.pageNumber * page.size;
            const end = Math.min((start + page.size), page.totalElements);
            pagedData.page = page;
            pagedData.data = data.data.data;
            console.log("submission details = >",pagedData);

            resolve(pagedData);
          }
          else
          {
            return this.toastr.error(data.message);
          }
        })
        .catch(error => {
          console.log('Error in invoiking API:' + error);
          reject(error);
        });
    }); // end promoise
    return promise;
  } // end getresult

  //   public getTomTomResults(page: Page,worker_id: number, status:number, project: number ): Observable<PagedData<Submission>> {
  //     return of(this.getTomtomPagedData(page, worker_id, status, project));
  // }



  // private getPagedData(page: Page, email: string, worker_id: number, staus:number, type: number ): PagedData<SubmisssionItem> {
  //   const pagedData = new PagedData<SubmisssionItem>();
  //   const apiName = 'api';
  //   let s = (status ? '&status=' + status : '') + (worker_id ? '&worker='+worker_id: '')+(type ? '&type='+type: '');
  //   const path = `submissions?email=${email}${s}&limit=${page.size}&offset=${page.pageNumber * page.size}`;      const myInit = {
  //         headers: {}, // OPTIONAL
  //         response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  //         queryStringParameters: {}
  //       };
  //   API.get(apiName, path, myInit)
  //   .then(response => {
  //     const data = response.data;
  //     page.totalElements = data.count;
  //     page.totalPages = page.totalElements / page.size;
  //     const start = page.pageNumber * page.size;
  //     const end = Math.min((start + page.size), page.totalElements);
  //     pagedData.data = data.data;
  //   })
  //   .catch(error => {
  //     console.log('Error in invoiking API:' + error);
  //   });
  //     pagedData.page = page;
  //     return pagedData;
  // }


  public getSubmissionTelly(id) {
    const apiName = 'api_node_2';
    const path = `sprint/task-quota-usage?id=${id}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    const promise = new Promise((resolve, reject) => {
      this.loader.start();

      API.get(apiName, path, myInit)
        .then(response => {
          console.log(response)
          const data = response.data;
          if (data.response_code == 200) {
            this.loader.complete();
            resolve(data);
          }
          else
          {
            return this.toastr.error(data.error);
          }
        })
        .catch(error => {
          this.toastr.error(error);
          reject(error);
        });
    }); // end promoise
    return promise;
  }



  public saveSubmissions(form: BulkEvaluation) {
    const apiName = 'api_node_1';
    const path = `submissions`;
    const myInit = {
      body: form,
      headers: {}
    };
    const promise = new Promise((resolve, reject) => {
      this.loader.start();

      API.post(apiName, path, myInit)
        .then(response => {
          const data = response;
          console.log("success message check =>", data);
          if (data.response_code == 200) {
            this.loader.complete();
            resolve(data.data);

          }
          else
          {
            return this.toastr.error(data.message);
          }
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }



  public saveSubmission(id: number, agent_id: number, status: number) {
    const apiName = 'api';
    const path = `submission?submission=${id}&agent=${agent_id}&status=${status}`;
    const myInit = {
      //   body: form,
      headers: {}
    };
    const promise = new Promise((resolve, reject) => {
      this.loader.start();

      API.put(apiName, path, myInit)
        .then(response => {
          const data = response.data;
          this.loader.complete();
          resolve('OK');
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }

  public exportIntoSpss(ids) {
    const apiName = 'api_node_2';
    const path = `submission/export`;
    const myInit = {
      body: ids,
      headers: {}
    };
    const promise = new Promise((resolve, reject) => {
      this.loader.start();

      API.post(apiName, path, myInit)
        .then(response => {
          const data = response;
          console.log("success message check =>", data);
          if (data.response_code == 200) {
            this.loader.complete();
            resolve(data.data);

          }
          else
          {
            return this.toastr.error(data.message);
          }
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }


}
