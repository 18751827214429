// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const { version } = require("../../package.json");

export const environment = {
	production: false,

	// api_node_1: "http://localhost:3000/node1-devt-v1/",
	// api_node_1: "http://localhost:3000/node1-dev-v1/",
	// api_node_1: "http://localhost:3000/node1-prod-v3/",
	// api_node_1: "https://api.muhimmaplatform.com/node1-devt-v1/",
	// api_node_1: "https://api.muhimmaplatform.com/node1-dev-v1/",
	api_node_1: "https://api.muhimmaplatform.com/node1-prod-v3/",

	// api_node_2: "http://localhost:4000/node2-devt-v1/",
	// api_node_2: "http://localhost:4000/node2-dev-v1/",
	// api_node_2: "http://localhost:4000/node2-prod-v3/",
	// api_node_2: "https://api.muhimmaplatform.com/node2-devt-v1/",
	// api_node_2: "https://api.muhimmaplatform.com/node2-dev-v1/",
	api_node_2: "https://api.muhimmaplatform.com/node2-prod-v3/",
	version,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
