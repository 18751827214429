import { User } from './../model/user';
// import { AccountItem } from './../model/AccountItem';
import { AccountItem } from '../model/accountItem';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PagedData } from '../model/paged-data';
import { Page } from '../model/page';
import Amplify, { API, Auth } from 'aws-amplify';
import aws_exports from './../../aws-exports';
import { AuthService } from '../auth/auth.service';
import { ToastrService } from 'ngx-toastr';


/**
 * A server used to mock a paged data result from a server
 */
@Injectable()
export class AccountService {
    constructor(private auth: AuthService, public toastr: ToastrService) {

    }
    /**
     * A method that mocks a paged server response
     * @param page The selected page
     * @returns {any} An observable containing the employee data
     */
    public getResults(page: Page, search?: any): Observable<PagedData<AccountItem>> {
        return of(this.getPagedData(page, search));
    }


    public getResult(page: Page, search: string): Promise<any> {
      
      const pagedData = new PagedData<AccountItem>();
      const apiName = 'api_node_1';
      const path = `accounts?email=${search}&limit=${page.size}&offset=${page.pageNumber * page.size}`;
      const myInit = {
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {}
          };
      const promise = new Promise((resolve, reject) => {
          API.get(apiName, path, myInit)
          .then(response => {
            const data = response.data;
            if (data.response_code == 200) {
              page.totalElements = data.data.count;
              page.totalPages = page.totalElements / page.size;
              const start = page.pageNumber * page.size;
              const end = Math.min((start + page.size), page.totalElements);
              pagedData.page = page;
              pagedData.data = data.data.data;
              resolve(pagedData);
            }
            else
            {
              return this.toastr.error(data.message);
            }
          })
          .catch(error => {
            this.toastr.error('Error in invoiking API:' + error);
            reject(error);
          });
      }); // end promoise
      return promise;
    } // end getresult

    /**
     * Package companyData into a PagedData object based on the selected Page
     * @param page The page data used to get the selected data from companyData
     * @returns {PagedData<CorporateEmployee>} An array of the selected data and page
     */
    private getPagedData(page: Page, search): PagedData<AccountItem> {
      const pagedData = new PagedData<AccountItem>();
      const apiName = 'api';
      const path = `workers?search=${search}&limit=${page.size}&offsert=${page.pageNumber * page.size}`;
      const myInit = {
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {}
          };
      API.get(apiName, path, myInit)
      .then(response => {
        const data = response.data;
        page.totalElements = data.count;
        page.totalPages = page.totalElements / page.size;
        const start = page.pageNumber * page.size;
        const end = Math.min((start + page.size), page.totalElements);
        pagedData.data = data.data;
      })
      .catch(error => {
        console.log('Error in invoiking API:' + error);
      });
        pagedData.page = page;
        return pagedData;
    }
    public saveAccount(form: AccountItem) {
      const apiName = 'api';
      const path = `accounts`;
      const myInit = {
        body: form,
        headers: {}
    };
      const promise = new Promise((resolve, reject) => {
        API.post(apiName, path, myInit)
          .then(response => {
            const data = response;
            if (data.response_code == 200) {
              resolve(data.data);
            }
            else
            {
              this.toastr.error(data.message);
            }
          })
          .catch(error => {
            this.toastr.error(error);
            reject(error);
          });
      }); // end promoise
      return promise;
    }
    public saveCustomer(form: User) {
      const apiName = 'api_node_2';
      const path = `accounts/user`;
      const myInit = {
        body: form,
        headers: {}
    };
      const promise = new Promise((resolve, reject) => {
        API.post(apiName, path, myInit)
          .then(response => {
            const data = response;
            if (data.response_code == 200) {
              resolve(data.data);
            }
            else
            {
              return this.toastr.error(data.message);
            }
          })
          .catch(error => {
            this.toastr.error(error);
            reject(error);
          });
      }); // end promoise
      return promise;
    }
}
