import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './../auth/auth.service';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  public signupForm: FormGroup;
  public confirmationForm: FormGroup;
  public successfullySignup: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.signupForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      phone: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      birthDate: ['', Validators.required]
    });
    this.confirmationForm = this.fb.group({
      email: ['', Validators.required],
      confirmationCode: ['', Validators.required]
    });
  }
  loginWithFacebook() {
    const url =
      // tslint:disable-next-line:max-line-length
      'https://frontendportal.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Facebook&redirect_uri=https://localhost:4200&response_type=CODE&client_id=6rkscc08nrb2puv0690hgjhcnm&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile';
    window.location.assign(url);
  }
  onSubmitSignup(value: any) {
    console.log(value);
    const email = value.email,
      password = value.password,
      phone = value.phone;

    this.auth
      .signUp(
        email,
        password,
        phone,
        value.birthDate,
        value.firstName,
        value.lastName,
        value.gender
      )
      .subscribe(
        result => {
          this.successfullySignup = true;
        },
        error => {
          console.log(error);
        }
      );
  }

  onSubmitConfirmation(value: any) {
    const email = value.email,
      confirmationCode = value.confirmationCode;
    this.auth.confirmSignUp(email, confirmationCode).subscribe(
      result => {
        this.router.navigate(['/login']);
      },
      error => {
        console.log(error);
      }
    );
  }
}
