import {
	Component,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Submission, User } from "../../models/survey";
import { SurveyService } from "../../services/survey.service";
import { SubmissionReqService } from "./../../../sprints/components/submissionFile/service/submissionReqService";
import { MultimediaModelComponent } from "./../../../sprints/components/submissionFile/submission/multimedia-model/multimedia-model.component";
@Component({
	selector: "app-surveys",
	templateUrl: "./surveys.component.html",
	styleUrls: ["./surveys.component.css"],
})
export class SurveysComponent implements OnInit {
	difference: number = 90;
	from: Date = new Date(Date.now() - this.difference * 24 * 60 * 60 * 1000);
	to: Date = new Date();
	maxDate: Date = new Date();
	data: any = null;
	users: User[] = [];
	userId: number;
	user: string = "";
	usersColspan: number = 0;
	userSectionTableHeadings;
	tasks: any = [];
	taskId: number;
	task: string = "";
	tasksColspan: number = 0;
	taskSectionTableHeadings;
	submissions: Submission[] = [];
	submissionsBasedOnUserIDAndTaskID: Submission[] = [];
	submissionsBasedOnUserTaskAndTaskFormID: any = [];
	questionsBasedOnUserTaskTaskFormAndSubmissionID: any = [];
	taskForms;
	isDisabled: boolean = false;
	limit: number = 200;
	offset: number = 0;
	size: number = 0;
	range: number = 9;
	limits: number[] = [];
	// limits: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

	dataGroupByTaskFormSubmissionAnQuestionsId: any = [];

	rows = [];
	tableData: any = null;
	csvData: any = [];
	questionsAndAnswers: any = [];

	multimedia: any;
	toggleMap: boolean = false;
	latitude: number = 0;
	longitude: number = 0;

	searchQuery: string = "";

	constructor(
		private surveyService: SurveyService,
		private toastr: ToastrService,
		private modalService: NgbModal,
		private submissionService: SubmissionReqService
	) {}

	@ViewChild("outlet", { read: ViewContainerRef })
	outletRef: ViewContainerRef;
	@ViewChild("content", { read: TemplateRef }) contentRef: TemplateRef<any>;

	ngOnInit() {
		this.limits = this.generateNnumbersOfArrayWithDefineMultiple(5, 200);
		this.getAllData();
	}

	search(searchValue: string) {
		this.searchQuery = searchValue;
		this.offset = 1;
		this.getAllData();
	}

	rerender() {
		this.outletRef.clear();
		this.outletRef.createEmbeddedView(this.contentRef);
	}

	getAllData() {
		let page = Math.floor(this.offset / this.limit);
		page = page + 1;

		this.surveyService
			.getAllUsersAndSprintsData(
				encodeURIComponent(this.searchQuery.trim()),
				this.limit,
				page,
				this.dateFormatter(this.from),
				this.dateFormatter(this.to)
			)
			.then((res) => {
				if (Object.keys(res).length > 0) {
					this.setInitialData(res);
					this.toastr.success(`Data fetched successfully!`);
				}
			})
			.catch((error) => {
				this.toastr.error(`Error while making api call: ${error}`);
			});
	}

	setInitialData(res) {
		this.data = res;
		const { workers, tasks, submissions, workersCount } = this.data;
		this.size = workersCount;
		this.users = workers;
		this.tasks = tasks;
		this.submissions = submissions;
		if (this.users !== null) {
			this.userSectionTableHeadings =
				this.users && this.users.length > 0 && this.users[0];

			this.userSectionTableHeadings = Object.keys(
				this.userSectionTableHeadings
			);

			this.userSectionTableHeadings.shift();

			this.usersColspan = Object.keys(
				this.userSectionTableHeadings
			).length;
		}

		if (this.tasks !== null) {
			this.tasksColspan = this.tasks.length;

			this.taskSectionTableHeadings = this.tasks.map((task) => {
				return task.task_name;
			});
		}

		if (this.users !== null) {
			const csvFileHeaders = [
				"id",
				...this.userSectionTableHeadings,
				...this.taskSectionTableHeadings,
			];

			this.csvData = [];
			this.csvData.push(csvFileHeaders);

			const csvFileBody = [];

			this.users.map((user) => {
				const demographics = [
					user.id,
					...this.getUserObjectValues(user),
				];
				if (this.tasks !== null && this.tasks.length > 0) {
					let hasSubmissions = [];
					let count = 0;
					this.tasks.map((task) => {
						count++;
						hasSubmissions.push(
							this.checkUserHasSubmissions(user.id, task.task_id)
								.length > 0
								? "Yes"
								: "No"
						);
					});
					this.csvData.push([...demographics, ...hasSubmissions]);
				} else {
					this.csvData.push([
						user.id,
						"",
						...this.getUserObjectValues(user),
					]);
				}
			});
		}

		this.rerender();
	}

	answersJoinOrNot(question, type) {
		if (question.length > 0) {
			return question.map((ques) => ques[type]).join(", ");
		} else {
			return question[type];
		}
	}

	getAnswer(question) {
		if (question[0].geo_answer && question[0].question_type === 34) {
			return JSON.parse(question[0].geo_answer);
		} else if (question[0].raw_answer && question[0].question_type === 34) {
			return JSON.parse(question[0].raw_answer);
		} else if (question[0].raw_answer && question[0].question_type !== 34) {
			// console.log(question, "raw_answer");
			return this.answersJoinOrNot(question, "raw_answer");
		} else if (
			question[0].selection_answer &&
			question[0].question_type !== 34
		) {
			// console.log(question, "selection_answer");
			return this.answersJoinOrNot(question, "selection_answer");
		} else if (question[0].geo_answer && question[0].question_type !== 34) {
			// console.log(question, "geo_answer");
			return this.answersJoinOrNot(question, "geo_answer");
		} else if (
			!question[0].geo_answer &&
			!question[0].raw_answer &&
			!question[0].selection_answer
		) {
			return null;
		}
	}

	getUrls(question) {
		if (question[0].question_type === 31) {
			return question.map((ques) => {
				return ques.raw_answer;
			});
		} else if (question[0].question_type === 32) {
			return question.map((ques) => {
				return "../../../../assets/images/dummy_audio.png";
			});
		} else if (question[0].question_type === 33) {
			return question.map((ques) => {
				return "../../../../assets/images/video_icon.png";
			});
		}
	}

	getMediaFile(url, type) {
		this.multimedia = "";

		this.multimedia = {
			type: type,
			url: url,
		};

		const modalRef = this.modalService.open(MultimediaModelComponent, {
			ariaLabelledBy: "modal-basic-title",
			windowClass: "add-library-Modal",
			backdrop: "static",
			keyboard: false,
		});
		modalRef.componentInstance.id = this.multimedia;
		modalRef.result.then(
			() => {},
			() => {}
		);
		this.submissionService.mediaFile = this.multimedia;
	}

	displayLocation(lat, long) {
		this.latitude = Number(lat);
		this.longitude = Number(long);
		this.toggleMap = !this.toggleMap;
	}

	checkUserHasSubmissions(userId, taskId) {
		if (
			this.submissions !== null &&
			this.tasks !== null &&
			userId &&
			taskId
		) {
			const submissions = this.submissions.filter((submission) => {
				if (
					submission.worker_id == userId &&
					submission.task_id == taskId
				) {
					return submission;
				}
			});

			return submissions;
		} else {
			return [];
		}
	}

	showSubmissionsModal(user, task) {
		this.userId = user["id"];
		this.taskId = task["task_id"];
		this.user = user["name"].trim();
		this.task = task["task_name"].trim();

		this.submissionsBasedOnUserTaskAndTaskFormID = [];
		this.questionsBasedOnUserTaskTaskFormAndSubmissionID = [];
		this.dataGroupByTaskFormSubmissionAnQuestionsId = [];

		if (
			this.submissions !== null &&
			this.tasks !== null &&
			this.userId &&
			this.taskId
		) {
			this.submissionsBasedOnUserIDAndTaskID =
				this.checkUserHasSubmissions(this.userId, this.taskId);

			if (
				this.submissionsBasedOnUserIDAndTaskID &&
				this.submissionsBasedOnUserIDAndTaskID.length > 0
			) {
				this.taskForms = this.groupBy(
					this.submissionsBasedOnUserIDAndTaskID,
					"task_form_id"
				);
				let questionsGroupBy;
				Object.values(this.taskForms).map((tasks) => {
					const submissionsGroupByTaskFromId = this.groupBy(
						tasks,
						"submission_id"
					);

					this.submissionsBasedOnUserTaskAndTaskFormID.push({
						[`${tasks[0].task_form_name}`]:
							submissionsGroupByTaskFromId,
					});

					Object.entries(submissionsGroupByTaskFromId).map(
						([key, value]) => {
							const questionsGroupBySubmissionId = this.groupBy(
								value,
								"question_id"
							);
							this.questionsBasedOnUserTaskTaskFormAndSubmissionID.push(
								{
									[key]: questionsGroupBySubmissionId,
								}
							);
							questionsGroupBy = {
								[`${tasks[0].task_form_name}`]:
									this
										.questionsBasedOnUserTaskTaskFormAndSubmissionID,
							};
						}
					);
					this.dataGroupByTaskFormSubmissionAnQuestionsId.push(
						questionsGroupBy
					);
				});

				console.log(
					"dataGroupByTaskFormSubmissionAnQuestionsId",
					this.dataGroupByTaskFormSubmissionAnQuestionsId
				);
			}
		}
	}

	groupBy(array, key) {
		if (Array.isArray(array) && array.length > 0) {
			return array.reduce(function (item, index) {
				(item[index[key]] = item[index[key]] || []).push(index);
				return item;
			}, {});
		}
	}

	getUserObjectValues(user: User) {
		if (user) {
			let worker = Object.values(user);
			worker.shift();
			return worker;
		}
	}

	onPageChange(offset: number) {
		this.offset = offset;
		this.getAllData();
	}

	filter() {
		this.offset = 1;
		this.getAllData();
	}

	handleRecordsPerPage(limit) {
		this.offset = 1;
		this.limit = limit;
		this.getAllData();
	}

	generateNnumbersOfArrayWithDefineMultiple(range, multiple) {
		// [...Array(5).keys()]
		return Array.from(Array(range).keys()).map((item) => {
			item = item + 1;
			return multiple * item;
		});
	}

	unique(array) {
		if (Array.isArray(array) && array.length > 0) {
			const uniqueIds = [];
			const unique = array.filter((element) => {
				const isDuplicate = uniqueIds.includes(element.id);
				if (!isDuplicate) {
					uniqueIds.push(element.id);
					return true;
				}
				return false;
			});
			return unique;
		}
	}

	onDateChange(event, startOrEnd) {
		function datediff(first, second) {
			return Math.round((second - first) / (1000 * 60 * 60 * 24));
		}

		function parseDate(str) {
			let date = str.split("/");
			return new Date(date[2], date[0] - 1, date[1]);
		}

		if (startOrEnd === "start") {
			this.from = event;
		} else {
			this.to = event;
		}

		const difference = datediff(
			parseDate(this.from.toLocaleDateString()),
			parseDate(this.to.toLocaleDateString())
		);

		// if (difference > this.difference) {
		// 	this.isDisabled = true;
		// 	this.toastr.warning(
		// 		`You select ${difference} days please select date range between or exact ${this.difference} days!`
		// 	);
		// }
		if (new Date(this.from) > new Date(this.to)) {
			this.isDisabled = true;
			this.toastr.warning(`End date must be greater than start date!`);
		} else {
			this.isDisabled = false;
		}
	}

	dateFormatter(date: Date) {
		if (date) {
			return date
				.toISOString()
				.split("T")
				.map((d, i) => {
					return i === date.toISOString().split("T").length - 1
						? d.slice(0, -1)
						: d;
				})
				.join(" ");
		}
	}
}
