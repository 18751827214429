import { ReportService } from './../services/report.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ProjectService } from '../services/projectService';
import { ToastrService } from 'ngx-toastr';
import { Report } from '../model/Report';
import { Sprint } from '../model/sprint';
import { ViewEncapsulation, ViewChild, ElementRef, PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  @Input() rows: any[];
  @Input() projectId: any;
  @Input() admin: boolean = false;

  message: any = '';
  model: Report = null;
  _sprint : Sprint  = null;
  id: number =0;
  @Input() sprintId: number;
  @Output('ReportSaved') valueChange: EventEmitter<Report> = new EventEmitter<Report>();



  constructor(private reportService: ReportService,private  toastr: ToastrService) { }

  ngOnInit() {
    this.GetRecord(0);
  }
  GetRecord(id){
if(id === 0) this.model = { id: 0, title: '', description:'', url:'', status:0, reportable_id: this.sprintId,
                                 reportable_type:'Project', creator_id : null, creator_type:'Agent', attributes:null, created_at: new Date(), updated_at : new Date(), is_deleted:false};
else this.model= this.rows.find(r=>r.id === id);
}
postForm(){
  this.model.reportable_id  = this.projectId;
  this.model.reportable_type= 'Project';
  this.reportService.saveReport(this.model)
    .then((data) => {
      this.model.id = data['id'];
      console.log('Saved..' + data);
      this.toastr.success('Saved Successfully!');
      this.valueChange.emit(this.model);
    })
    .catch(err => {
      this.toastr.error('Error in saving reward');
      console.error(err);
    } );
}
}
