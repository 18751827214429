// import { SubmissionService } from './../services/submissionService';
import { SubmissionService } from 'src/app/services/submissionService';
import { SubmissionReqService } from '../service/submissionReqService';
// import { BulkEvaluation } from './../model/PaymentRequestForm';
import { BulkEvaluation } from 'src/app/model/PaymentRequestForm';

import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
// import { Page } from '../model/page';
// import { Page } from 'src/app/model/page';
import { Page } from '../model/pageSubmissionModel';
import { CsvModule } from '@ctrl/ngx-csv';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SprintService } from '../../../service/sprintService';

@Component({
	selector: 'app-submissions',
	templateUrl: './submissionsrequest.component.html',
	styleUrls: ['./submissionsrequest.component.css']
})
export class SubmissionsRequestComponent implements OnInit {
	@Input() projectId: number;
	@Input() taskIds;
	// projectId: number;
	workerId = null;
	rewardId = null;
	@ViewChild('closeModal') closeModal: ElementRef
	ids = [];

	page = new Page();
	selected = [];
	range = "0";
	approvedArray = 0;
	idscommas = null;
	expanded: any = {};
	Submission: any = null;
	previous = 0;
	next = 0;
	@ViewChild('myTable') table: any;
	status = 1;
	reportable = -1;
	newstatus = 2;
	message = "";
	from = null;
	to = null;
	rows = [];
	count = 0;
	loadingIndicator: boolean = true;
	reorderable: boolean = true;
	email = 'affan@muhimmaapp.com';
	quotaData: any = [];
	loading: any = true;
	shouldredirect: boolean = false;
	constructor(private activatedRoute: ActivatedRoute, private submissionService: SubmissionReqService, router: Router, private toastr: ToastrService, public sprintService: SprintService) {
		this.page.pageNumber = 0;
		this.page.size = 100;
		this.projectId = parseInt(localStorage.getItem("localSprintID"));
	}

	ngOnInit() {
		this.setPage({ offset: 0, size: 100 });

	} // end on init
	filter() {

		this.selected = [];
		this.loadingIndicator = true;
		const status = (this.status == -1 ? null : this.status);
		const reportable = (this.reportable == -1 ? null : this.reportable == 1);
		this.submissionService.getResult(this.page, this.email, this.workerId, status, null,
			this.projectId, reportable, this.from, this.to).then(pagedData => {
				this.page = pagedData.page;
				this.rows = pagedData.data ? pagedData.data : [];
				console.log("rows data submission =>", this.rows);

				this.count = pagedData.page.totalElements;
				this.loadingIndicator = false;

				let tableid = document.getElementById("details");
				tableid.setAttribute("class", "collapse show");
			});

		// this.submissionService.getResult(this.page, this.email, this.workerId, 2, null,
		//   this.projectId, reportable, this.from, this.to).then(pagedData => {
		//     this.approvedArray = 0;
		//     this.selected = [];
		//     console.log(pagedData);

		//     if (pagedData.data != null) {
		//       if (pagedData.data.length != 0) {
		//         pagedData.data.forEach(elementData => {
		//           if (elementData.status == 2) {
		//             this.approvedArray = this.approvedArray + 1;
		//           }
		//         });
		//       }
		//     }

		//   });
		console.log(this.approvedArray)
	}
	toggleExpandRow(row) {
		console.log('Toggled Expand Row!', row);
		this.table.rowDetail.toggleExpandRow(row);
	}

	onDetailToggle(event) {
		console.log('Detail Toggled', event);
	}
	getSubmission(id) {
		this.loading = true;
		this.submissionService.getById(this.email, id).then(submission => {
			this.Submission = submission.data[0];

			this.Submission.answers.forEach(modelAnswer => {
				if (modelAnswer.other && modelAnswer.answer) {

					if (modelAnswer.question_type === 34)
						modelAnswer.answer = modelAnswer.answer;
					else
						modelAnswer.answer = modelAnswer.answer + ", " + modelAnswer.other;

				}
				else if (modelAnswer.other && !modelAnswer.answer)
					modelAnswer.answer = modelAnswer.other;
			});
			this.loading = false;
		});
	}
	mapids() {
		this.ids = [];
		this.selected.map(s => this.ids.push(s.id));
		this.idscommas = this.ids.join(',');
	}
	id;
	postForm() {
		if (this.idscommas === null || this.idscommas === '') return;
		this.ids = this.idscommas.split(',');

		const totalQuota = localStorage.getItem("saveTotalQuota");

		const count = parseInt(totalQuota);

		// if (this.newstatus == 2) {
		//   console.log(this.approvedArray)
		//   const getLength = this.approvedArray + this.ids.length;
		//   console.log(getLength);
		//   if (getLength <= count) {
		//     let form = new BulkEvaluation(this.ids, this.newstatus, this.message, 1000, this.rewardId);
		//     this.submissionService.saveSubmissions(form);
		//     this.filter();

		//   }
		//   else
		//   {
		//     return this.toastr.warning("Can not exceed set quota for the task");
		//   }
		// }
		// else
		// {
		//   let form = new BulkEvaluation(this.ids, this.newstatus, this.message, 1000, this.rewardId);
		//   this.submissionService.saveSubmissions(form);
		//   this.filter();
		// }
		let form = new BulkEvaluation(this.ids, this.newstatus, this.message, 1000, this.rewardId);
		this.submissionService.saveSubmissions(form).then(x => {
			let value: any = x;

			console.log(value)

			if (value.response_code == 200) {
				this.toastr.success(value.message);
			}
			else if (value.response_code == 422) {
				this.toastr.error(value.message);
			}
			this.filter();
		});
	}

	onSelect({ selected }) {
		//console.log('Select Event', selected, this.selected);

		this.selected.splice(0, this.selected.length);
		this.selected.push(...selected);
		console.log("Slect Value", this.selected.length);
	}

	onActivate(event) {
		//console.log('Activate Event', event);
	}
	setPage(pageInfo) {
		console.log(pageInfo);
		this.page.size = pageInfo.size;
		this.page.pageNumber = pageInfo.offset;
		this.filter();
	}//end setPage


	getId(id, status) {
		//console.log(id,status);

		if (status === 0) return '<i class="fas fa-circle text-dark"></i> ' + id;
		if (status === 1) return '<i class="fas fa-circle text-primary"></i> ' + id;
		if (status === 2) return '<i class="fas fa-circle text-success"></i> ' + id;
		if (status === 3) return '<i class="fas fa-circle text-danger"></i> ' + id;
		if (status === 4) return '<i class="fas fa-circle text-warning"></i> ' + id;
		if (status === 5) return '<i class="fas fa-circle text-muted"></i> ' + id;
		return '<span class="badge badge-info">-</span>';
	}
	getStatus(status) {
		if (status === 0) return '<span class="badge badge-warning">In Progress</span>';
		if (status === 1) return '<span class="badge badge-success">Approved</span>';
		if (status === 2) return '<span class="badge badge-danger">Rejected</span>';
		if (status === 3) return '<span class="badge badge-primary">Completed</span>';

		return '<span class="badge badge-info">-</span>';
	}

	setDateRange() {
		let dt = new Date();
		let month = dt.getMonth(), year = dt.getFullYear();
		if (this.range === "0") this.from = new Date(year, month, 1);
		if (this.range === "0") this.to = new Date(year, month + 1, 0);
		if (this.range === "1") this.from = new Date(year, month - 1, 1);
		if (this.range === "1") this.to = new Date(year, month + 1, 0);
		if (this.range === "2") this.from = new Date(year, month - 3, 1);
		if (this.range === "2") this.to = new Date(year, month + 1, 0);
		if (this.range === "3") this.from = new Date(year, 1, 1);
		if (this.range === "3") this.to = new Date(year + 1, 1, 0);
		this.filter();
	}
	openModal(a) {
		document.getElementById("openModalButton").click();

	}
	getSubmissionStatus(status) {
		if (status === 0) return '<span class="badge badge-light">Draft</span>';
		if (status === 1) return '<span class="badge badge-warning">Submitted</span>';
		if (status === 2) return '<span class="badge badge-success">Approved</span>';
		if (status === 3) return '<span class="badge badge-danger">Rejected</span>';
		if (status === 4) return '<span class="badge badge-secondary">Withdrawn</span>';

		return '<span class="badge badge-info">-</span>';
	}
	submissionSaved(question) {

		console.log('emitted the value now the popup should close' + this.closeModal.nativeElement);
		this.closeModal.nativeElement.click();
		this.filter();

	}

	submissionTally() {
		const reportable = (this.reportable == -1 ? null : this.reportable == 1);
		this.submissionService.getSubmissionTelly(this.projectId).then(quotaData => {
			console.log(quotaData)
			this.quotaData = quotaData;
			this.quotaData = this.quotaData.data;

			this.quotaData.forEach(elementData => {
				if (elementData.data.age.length != 0) {
					elementData.data.age.forEach(elementAge => {
						let [letf, string] = elementAge.label.split("[");
						let [string2, right] = string.split("]");
						let [value1, value2] = string2.split(",");
						elementAge.label = value1 + " - " + value2;
					});
				}

				//   if (elementData.data.gender.length != 0) {
				//     elementData.data.gender.forEach(elementGender => {

				// 		elementGender.label.forEach(label => {
				// 			if (label == '0') {
				// 				label = "Male";
				// 			}
				// 			else if (label == '1') {
				// 				label = "Female";
				// 			}
				// 			else if (label == '2') {
				// 				label = "All";
				// 			}
				// 		});

				//     });
				//   }

			});

		},
			error => {
				this.toastr.error("Something went wrong, Please check the internet connection")
			}
		);
	}
	exportSpss() {
		console.log(this.selected);
		let id = [];
		id = this.selected.map(x => {
			return x.id;
		});
		if (this.taskIds.length) {
			this.exportAndDownloadSubmissions(id);

		}
		else {
			return this.toastr.error("there is no task in this sprint")
		}

	}

	handleExportAndDownload(type:number ,  filename:string) {
		console.log(this.selected);
		let id = [];
		id = this.selected.map(x => {
			return x.id;
		});
		if (this.taskIds.length) {
			this.exportAndDownloadSubmissions(id ,type ,  filename  ) ;

		}
		else {
			return this.toastr.error("there is no task in this sprint")
		}

	}

	exportAndDownloadSubmissions(id:any , type=0 ,  fileName = "submission.sav" ) {
		const submissionid = {
			submission_id: id,
			task_id: this.taskIds,
			status: this.status,
			type
		}
		this.submissionService.exportIntoSpss(submissionid).then((response: any) => {
			console.log(response);
			const link = document.createElement("a");
			link.href = response.Location;
			link.download = fileName;
			link.click();
			this.selected = [];

		},
			error => {
				this.toastr.error("Something went wrong, Please check the internet connection")
			}
		);
	}

}
