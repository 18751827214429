import { WorkerItem } from './../model/WorkerItem';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PagedData } from '../model/paged-data';
import { Page } from '../model/page';
import Amplify, { API, Auth } from 'aws-amplify';
import aws_exports from './../../aws-exports';
import { AuthService } from '../auth/auth.service';
import { TransactionItem } from '../model/transactionItem';
import { TransactionForm } from '../model/TransactionForm';
import { ToastrService } from 'ngx-toastr';


/**
 * A server used to mock a paged data result from a server
 */
@Injectable()
export class TransactionService {
    constructor(private auth: AuthService, public toastr: ToastrService) {

    }
    /**
     * A method that mocks a paged server response
     * @param page The selected page
     * @returns {any} An observable containing the employee data
     */
    public getResults(page: Page, email: string, worker_id: number, status:number, type: number ): Observable<PagedData<TransactionItem>> {
        return of(this.getPagedData(page, email, worker_id, status, type));
    }


    public getResult(page: Page, email: string, worker_id: number, staus:number, type: number ): Promise<any> {
      const pagedData = new PagedData<TransactionItem>();
      const apiName = 'api_node_1';
      let s = (status ? '&status=' + status : '') + (worker_id ? '&worker='+worker_id: '')+(type ? '&type='+type: '');
      const path = `transactions?email=${email}${s}&limit=${page.size}&offset=${page.pageNumber * page.size}`;
      const myInit = {
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {}
          };
      const promise = new Promise((resolve, reject) => {
          API.get(apiName, path, myInit)
          .then(response => {
            const data = response.data;
            if (data.response_code == 200) {
              page.totalElements = data.data.count;
              page.totalPages = page.totalElements / page.size;
              const start = page.pageNumber * page.size;
              const end = Math.min((start + page.size), page.totalElements);
              pagedData.page = page;
              pagedData.data = data.data.data;
              resolve(pagedData);
            }
            else
            {
              return this.toastr.error(data.message);
            }
          })
          .catch(error => {
            this.toastr.error(error);
            reject(error);
          });
      }); // end promoise
      return promise;
    } // end getresult
    public saveTransaction(form: TransactionForm) {
      const apiName = 'api_node_2';
      const path = `transactions`;
      const myInit = {
        body: form,
        headers: {}
    };
      const promise = new Promise((resolve, reject) => {
        API.post(apiName, path, myInit)
          .then(response => {
            const data = response.data;
            resolve('OK');
          })
          .catch(error => {
            console.log(error.response);
            reject(error);
          });
      }); // end promoise
      return promise;
    }
    /**
     * Package companyData into a PagedData object based on the selected Page
     * @param page The page data used to get the selected data from companyData
     * @returns {PagedData<CorporateEmployee>} An array of the selected data and page
     */
    private getPagedData(page: Page, email: string, worker_id: number, staus:number, type: number ): PagedData<TransactionItem> {
      const pagedData = new PagedData<TransactionItem>();
      const apiName = 'api_node_1';
      let s = (status ? '&status=' + status : '') + (worker_id ? '&worker='+worker_id: '')+(type ? '&type='+type: '');
      const path = `transactions?email=${email}${s}&limit=${page.size}&offset=${page.pageNumber * page.size}`;      const myInit = {
            headers: {}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {}
          };
      API.get(apiName, path, myInit)
      .then(response => {
        const data = response.data;
        page.totalElements = data.count;
        page.totalPages = page.totalElements / page.size;
        const start = page.pageNumber * page.size;
        const end = Math.min((start + page.size), page.totalElements);
        pagedData.data = data.data;
      })
      .catch(error => {
        console.log('Error in invoiking API:' + error);
      });
        pagedData.page = page;
        return pagedData;
    }

}
