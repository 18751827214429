import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import {
	UploaderOptions,
	UploadFile,
	UploadInput,
	UploadOutput,
} from "ngx-uploader";
import { base64Response } from "src/app/model/Base64Reponse";
import { S3Response } from "src/app/model/S3Response";
import { Tag } from "src/app/model/tag";
import { GoogleObj, GoogleService } from "src/app/services/google.services";
import { ProjectService } from "src/app/services/projectService";
import { Form, Opt, Question, Type } from "../../model/sprintModel";
import { SprintService } from "../../service/sprintService";
declare var $: any;

@Component({
	selector: "app-question",
	templateUrl: "./question.component.html",
	styles: [
		`
			.custom-control {
				display: inline-block;
				margin-left: 5px;
			}
		`,
	],
	styleUrls: ["./question.component.css"],
})
export class QuestionComponent implements OnInit, OnChanges {
	uploadUrlForQuestionImage =
		"https://bu0vboz957.execute-api.us-east-2.amazonaws.com/development/file?name=";
	uploadUrl =
		"https://bu0vboz957.execute-api.us-east-2.amazonaws.com/development/file?name=";
	bulkoptions: any = "";
	loading = false;
	_model: Question = null;

	options: UploaderOptions;
	uploadInput: EventEmitter<UploadInput>;
	humanizeBytes: Function;
	files: UploadFile[];
	imageFile: UploadFile[];
	backroundFile: UploadFile;
	questionImageFile: UploadFile;
	iconFile: UploadFile;
	dragOver: boolean;
	imageUrl: string;
	questionImageUrl: string;
	optionId: any = 0;
	minNumericVal = 1;
	maxNumericVal = 10;
	minfieldNumericVal = 1;
	maxfieldNumericVal = 10;
	requiredFieldLength: any = [];
	checkSpontCond;
	getMultiJump = [];
	getMultiTerminate = [];
	disableTypeCondition = false;
	parrentArray = [];
	preConditionArray = [];
	drilldownQuestions = [];
	projectId: number;
	sprintTagList: any = [];
	_tags: Tag[] = [];
	sprintParentTagList: any = [];

	select_deselect_Array = [
		{ id: "none", name: "none" },
		{ id: "All of the above", name: "All of the above" },
		{ id: "None of the above", name: "None of the above" },
	];

	multi_loic_matrix = [
		// {id: "none", name: "none"},
		{ id: "row_select", name: "Row Wise" },
		{ id: "column_select", name: "Column Wise" },
	];
	question_category = [
		{ id: 0, name: "None" },
		{ id: 1, name: "Current Usage" },
		{ id: 2, name: "Tag" },
	];
	@Output("QuestionSaved") valueChange: EventEmitter<Question> =
		new EventEmitter<Question>();

	@Input("Question")
	set model(value: Question) {
		this._model = value;
	}
	get model() {
		if (this._model === null || this._model === undefined) {
			this._model = {
				question_image: "",
				files: [],
				tags: [],
				name: "",
				task_form_id: 0,
				question_type: null,
				question_category: 0,
				is_visible_client: false,
				max_answer_points: 0,
				constraints: {
					is_required: false,
					limit: { type: Type.Character, min: 10 },
					precondition: [],
					termination: [],
					jumps: [],
					validations: { minOpt: null, maxOpt: null },
					logic_selection: "row_select",
					files: null,
				},
				// description: '',
				id: 0,
				opts: [],
				row_names: [],
				column_names: [],
				jump_condition: null,
				terminate_condition: null,
				multiple_jump_condition: null,
				multiple_terminate_condition: null,
				label: null,
				labelar: null,
				position: 100,
				answer_points: 0,
				is_required: false,
				is_deleted: false,
				is_random: false,
				is_drilldown: false,
				is_drilldown_rowwise: false,
				is_drilldown_columnwise: false,
				is_spontaneous: false,
				is_cu_child: false,
				min_value: 0,
				max_value: 0,
				min_field_value: 0,
				max_field_value: 0,
				cu_parent_id: null,
				is_dd_child: false,
				dd_parent_id: 0,
				created_at: new Date(),
				updated_at: new Date(),
				is_tag_parent: false,
				is_tag_child: false,
				tag_condition: [],
				sprint_id: this.projectId,
			};
			// tslint:disable-next-line:comment-format
		} //end if
		return this._model;
	}

	@Input("SprintID")
	set sprintID(id) {
		this.projectId = id;
	}
	@Input("SprintTag")
	set setTag(value) {
		this.sprintTagList = value;
		this._tags = value;
	}

	Options: Opt;

	public googleObj: GoogleObj = new GoogleObj();
	public key: string;
	public result = "";

	@Input("Types") Types: any[];
	@Input("TaskForm") TaskForm: Form;
	// tslint:disable-next-line:max-line-length
	constructor(
		private projectService: ProjectService,
		private sprintService: SprintService,
		private _google: GoogleService,
		private http: HttpClient,
		private toastr: ToastrService
	) {
		this.disableTypeCondition = false;
	}
	translate() {
		if (this._model.label == null) {
			this._model.label = "";
		}
		this.doTranslate(this._model.label).then((d) => {
			this._model.labelar = d.toString();
		});
		if (this.model.opts != null) {
			this.model.opts.forEach((element) => {
				this.doTranslate(element.label).then((d) => {
					element.labelar = d.toString();
				});
			});
		}
		if (this.model.row_names != null) {
			this.model.row_names.forEach((element) => {
				this.doTranslate(element.name).then((d) => {
					element.name_ar = d.toString();
				});
			});
		}
		if (this.model.column_names != null) {
			this.model.column_names.forEach((element) => {
				this.doTranslate(element.name).then((d) => {
					element.name_ar = d.toString();
				});
			});
		}
	}
	doTranslate(key) {
		return new Promise((resolve, reject) => {
			this._google.translate(this.googleObj, key).subscribe(
				(res: any) => {
					resolve(res.data.translations[0].translatedText);
				},
				(err) => {
					console.log(err);
					reject(err);
				}
			);
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.TaskForm != null) {
			if (this.sprintService.parentQuestionTagID) {
				this.getParentTagLists(this.sprintService.parentQuestionTagID);
			}
			if (this.TaskForm.questions != null) {
				if (this.TaskForm.questions.length != 0) {
					this.parrentArray = [];
					this.preConditionArray = [];
					this.drilldownQuestions = [];
					this.TaskForm.questions.forEach((elementQuestion) => {
						if (
							elementQuestion.question_category == 1 &&
							elementQuestion.is_cu_child == false &&
							elementQuestion.position < this.model.position
						) {
							this.parrentArray.push(elementQuestion);
						}

						if (
							elementQuestion.question_type != 2 &&
							elementQuestion.question_type != 3 &&
							elementQuestion.question_type != 29
						) {
							if (
								elementQuestion.position < this._model.position
							) {
								this.preConditionArray.push(elementQuestion);

								if (elementQuestion.is_drilldown) {
									this.drilldownQuestions.push(
										elementQuestion
									);
								}
							}
						}
					});
				}
			}
		}

		if (this._model) {
			this.getConditionArray = [];
			if (
				this._model.row_names != null &&
				this._model.column_names != null
			) {
				if (
					this._model.row_names.length > 0 &&
					this._model.column_names.length > 0
				) {
					var count =
						this._model.row_names.length *
						this._model.column_names.length;
					this._model.row_names.forEach((elementRow) => {
						if (!elementRow.is_deleted) {
							this._model.column_names.forEach(
								(elementColumn) => {
									if (!elementColumn.is_deleted) {
										var name =
											elementRow.name +
											elementColumn.name;

										this.getConditionArray.push({
											name: name,
										});
									}
								}
							);
						}
					});
				}

				if (
					this._model.multiple_jump_condition != null &&
					this._model.multiple_jump_condition != []
				) {
					if (this._model.multiple_jump_condition.length > 0) {
						this._model.multiple_jump_condition.forEach(
							(element) => {
								var arrayOpt = element.opt_array;
								var saveArray = [];
								for (
									let index = 0;
									index < arrayOpt.length;
									index++
								) {
									saveArray.push(
										arrayOpt[index].row_name +
											"__" +
											arrayOpt[index].column_name
									);
								}
								element.opt_array = saveArray;
							}
						);
					}
				}

				if (
					this._model.multiple_terminate_condition != null &&
					this._model.multiple_terminate_condition != []
				) {
					if (this._model.multiple_terminate_condition.length > 0) {
						this._model.multiple_terminate_condition.forEach(
							(element) => {
								var arrayOpt = element.opt_array;
								var saveArray = [];
								for (
									let index = 0;
									index < arrayOpt.length;
									index++
								) {
									saveArray.push(
										arrayOpt[index].row_name +
											"__" +
											arrayOpt[index].column_name
									);
								}
								element.opt_array = saveArray;
							}
						);
					}
				}
			}

			this.disableTypeCondition = false;

			this.bulkoptions = "";

			if (this._model.id != 0) {
				this.getMultiConditionOptionArray = [];
				this.disablePreCondValRule = [];
				this.getMultiOptionArray = [];

				this.disableTypeCondition = true;

				if (
					this._model.constraints.precondition != null &&
					this._model.constraints.precondition != []
				) {
					if (this._model.constraints.precondition.length != 0) {
						var getAllQuestions =
							this.sprintService.getAllQuestions;

						this._model.constraints.precondition.forEach(
							(precondition, indexPre) => {
								var arrayOpt = [];
								for (
									let index = 0;
									index < getAllQuestions.length;
									index++
								) {
									if (
										precondition.indexNo ==
										getAllQuestions[index].id
									) {
										if (
											getAllQuestions[index]
												.question_type != 28
										) {
											getAllQuestions[index].opts.forEach(
												(elementOpt) => {
													if (
														!elementOpt.is_deleted
													) {
														arrayOpt.push(
															elementOpt.label
														);
													}
												}
											);
										}
										if (
											getAllQuestions[index]
												.question_type == 28
										) {
											getAllQuestions[
												index
											].row_names.forEach(
												(elementRow) => {
													if (
														!elementRow.is_deleted
													) {
														getAllQuestions[
															index
														].column_names.forEach(
															(elementColumn) => {
																if (
																	!elementColumn.is_deleted
																) {
																	arrayOpt.push(
																		elementRow.name +
																			elementColumn.name
																	);
																}
															}
														);
													}
												}
											);
										}

										if (
											getAllQuestions[index]
												.question_type == 4
										) {
											this.disablePreCondValRule[
												indexPre
											] = true;
										}
									}
								}
								this.getMultiOptionArray[indexPre] = arrayOpt;
							}
						);
					}
				}

				if (
					(this._model.constraints.termination != null &&
						this._model.constraints.termination != []) ||
					(this._model.constraints.jumps != null &&
						this._model.constraints.jumps != [])
				) {
					if (
						this._model.constraints.termination.length != 0 ||
						this._model.constraints.jumps.length
					) {
						this.getMultiConditionOptionArray = [];
						if (this._model.opts != null) {
							if (this.model.opts.length != 0) {
								this.model.opts.forEach((elementOPt) => {
									if (!elementOPt.is_deleted) {
										this.getMultiConditionOptionArray.push({
											id: elementOPt.label,
											name: elementOPt.label,
										});
									}
								});
							}
						}
					}
				}

				if (this._model.constraints.validations == null) {
					this._model.constraints.validations = {
						minOpt: null,
						maxOpt: null,
					};
				}

				if (this._model.opts != null) {
					if (this._model.opts.length > 0) {
						let temperyArray = [];
						this._model.opts.forEach((elementOpt) => {
							if (!elementOpt.is_deleted) {
								temperyArray.push(elementOpt);
							}
						});
						this.requiredFieldLength = new Array<number>(
							temperyArray.length
						);
						// this.requiredFieldLength = dummyOptArray
					}
				}
			} else if (this._model.id == 0) {
				this.getMultiConditionOptionArray = [];
				this.getMultiOptionArray = [];
				this.disablePreCondValRule = [];
				this.requiredFieldLength = [];
			}
		}
	}
	ngOnInit() {
		// console.log(this._model);
		// console.warn("from init " + this.Types.length);
		// console.warn(
		// 	"list",
		// 	this.Types.forEach((element) => {
		// 		console.log(element);
		// 	})
		// );
		// console.dir(this.TaskForm);
	}
	onSubmit() {
		console.log("Question Form Submitted!");
	}
	showOptions() {
		if (
			this.model.question_type === 0 ||
			this.model.question_type === 4 ||
			this.model.question_type === 30
		) {
			this.model.is_drilldown_rowwise = false;
			this.model.is_spontaneous = false;
			this.model.row_names = null;
			this.model.column_names = null;
			this.getConditionArray = [];
			if (
				this.model.constraints.termination != null &&
				this.model.constraints.termination != []
			) {
				if (this.model.constraints.termination.length != 0) {
					if (this.model.question_type == 4) {
						this.model.constraints.termination.forEach(
							(elementTermination) => {
								if (elementTermination.rule != "none") {
									elementTermination.rule = "none";
								}
								if (elementTermination.options.length > 1) {
									elementTermination.options.length = 1;
								}
							}
						);
					} else {
						this.model.constraints.termination.forEach(
							(elementTermination) => {
								if (elementTermination.rule == "none") {
									elementTermination.rule = "";
								}
							}
						);
					}
				}
			}

			if (this.model.question_type != 0) {
				this.model.constraints.jumps = [];
			}
			return true;
		}
		return false;
	}
	logicMatrixOption() {
		//console.log("questionType", this.model.question_type);
		if (this.model.question_type === 28) {
			this.model.is_spontaneous = false;
			this.model.opts = null;
			return true;
		}
		return false;
	}

	numericFieldOption() {
		if (this.model.question_type === 2) {
			this.model.row_names = null;
			this.model.column_names = null;
			this.model.is_drilldown_rowwise = false;
			this.getConditionArray = [];
			// if (this.model.question_category != 2) {
			// 	this.model.question_category = 0;
			// }
			// this.model.is_cu_child = false;
			return true;
		}
		return false;
	}
	paragraphFieldOption() {
		if (this.model.question_type === 3) {
			this.model.row_names = null;
			this.model.column_names = null;
			this.model.is_drilldown_rowwise = false;
			this.getConditionArray = [];
			// if (this.model.question_category != 2) {
			// 	this.model.question_category = 0;
			// }
			// this.model.is_cu_child = false;
			return true;
		}
		return false;
	}

	showSliderInputOptions() {
		if (this.model.question_type === 29) {
			this.model.row_names = null;
			this.model.column_names = null;
			this.model.is_drilldown_rowwise = false;
			this.getConditionArray = [];
			if (this.model.question_category != 2) {
				this.model.question_category = 0;
			}
			this.model.is_cu_child = false;
			return true;
		}
		return false;
	}
	showContraints() {
		if (this.model.question_type === 5 || this.model.question_type === 9) {
			return true;
		}
		return false;
	}

	postForm() {
		if (
			this.model.label !== null &&
			this.model.label !== "" &&
			this.model.labelar !== null &&
			this.model.labelar !== ""
		) {
			if (this.model.is_cu_child) {
				if (this.model.cu_parent_id == null) {
					var dismiss = document.getElementById("submitQuestions");
					dismiss.removeAttribute("data-dismiss");
					this.toastr.warning(
						"Please select the Parent Current Usage Question"
					);
					return false;
				}

				let key = this.model.label.match(/cu_id/g);
				if (!key && this.model.id == 0) {
					var dismiss = document.getElementById("submitQuestions");
					dismiss.removeAttribute("data-dismiss");
					this.toastr.warning("Please add the uniquie key");
					return false;
				}
			}

			if (this.model.question_type != 28) {
				this.model.constraints.logic_selection = "none";

				if (this.model.question_type == 2) {
					if (this.model.min_value > this.model.max_value) {
						var dismiss =
							document.getElementById("submitQuestions");
						dismiss.removeAttribute("data-dismiss");
						return this.toastr.warning(
							"Minimunm Value must be less than Maximum Value"
						);
					}
				}

				if (
					(this.model.question_type == 2 ||
						this.model.question_type == 3) &&
					this.model.is_spontaneous
				) {
					if (!this.model.min_field_value) {
						var dismiss =
							document.getElementById("submitQuestions");
						dismiss.removeAttribute("data-dismiss");
						return this.toastr.warning(
							"Minimunm Value Field is required"
						);
					}
					if (!this.model.max_field_value) {
						var dismiss =
							document.getElementById("submitQuestions");
						dismiss.removeAttribute("data-dismiss");
						return this.toastr.warning(
							"Maximunm Value Field is required"
						);
					}
					if (
						this.model.min_field_value > this.model.max_field_value
					) {
						var dismiss =
							document.getElementById("submitQuestions");
						dismiss.removeAttribute("data-dismiss");
						return this.toastr.warning(
							"Minimunm Value Fields must be less than Maximum Value Field"
						);
					}

					if (
						this.model.min_field_value < 1 ||
						this.model.max_field_value < 1
					) {
						var dismiss =
							document.getElementById("submitQuestions");
						dismiss.removeAttribute("data-dismiss");
						return this.toastr.warning(
							"Required Input Fields must be greather than 0"
						);
					}

					if (this.model.max_field_value == 1) {
						var dismiss =
							document.getElementById("submitQuestions");
						dismiss.removeAttribute("data-dismiss");
						return this.toastr.warning(
							"Maximum Input Fields must be greather than 1"
						);
					}
				} else if (
					(this.model.question_type == 2 ||
						this.model.question_type == 3) &&
					!this.model.is_spontaneous
				) {
					this.model.min_field_value = 1;
					this.model.max_field_value = 1;
				}

				if (this.model.question_type == 29) {
					if (
						this.model.min_value == null ||
						this.model.max_value == null
					) {
						var dismiss =
							document.getElementById("submitQuestions");
						dismiss.removeAttribute("data-dismiss");
						this.toastr.warning(
							"Please Input the Min and Max Value"
						);
						return false;
					} else if (this.model.min_value > this.model.max_value) {
						var dismiss =
							document.getElementById("submitQuestions");
						dismiss.removeAttribute("data-dismiss");
						this.toastr.warning(
							"Min value must be greater than Max Value"
						);
						return false;
					}

					if (this.model.constraints.validations.minOpt != null) {
						if (
							this.model.constraints.validations.minOpt <
								this.model.min_value ||
							this.model.constraints.validations.minOpt >
								this.model.max_value
						) {
							var dismiss =
								document.getElementById("submitQuestions");
							dismiss.removeAttribute("data-dismiss");
							this.toastr.warning(
								"Min validate value must be between " +
									this.model.min_value +
									" and " +
									this.model.max_value
							);
							return false;
						}
					}
					if (this.model.constraints.validations.maxOpt != null) {
						if (
							this.model.constraints.validations.maxOpt <
								this.model.min_value ||
							this.model.constraints.validations.maxOpt >
								this.model.max_value
						) {
							var dismiss =
								document.getElementById("submitQuestions");
							dismiss.removeAttribute("data-dismiss");
							this.toastr.warning(
								"Max validate value must be between " +
									this.model.min_value +
									" and " +
									this.model.max_value
							);
							return false;
						}
					}
					if (
						this.model.constraints.validations.minOpt != null &&
						this.model.constraints.validations.maxOpt != null
					) {
						if (
							this.model.constraints.validations.minOpt >
							this.model.constraints.validations.maxOpt
						) {
							var dismiss =
								document.getElementById("submitQuestions");
							dismiss.removeAttribute("data-dismiss");
							this.toastr.warning(
								"Min validate value must be less than Max validate value"
							);
							return false;
						}
					}
				}
			} else if (this.model.question_type == 28) {
				// Check required validation
				let condCheck = false;
				let requiredCount = 0;
				let message = "";
				if (
					this.model.row_names == null ||
					this.model.row_names.length == 0
				) {
					condCheck = true;
					message = "Please add thne minimum 1 row";
				}
				if (
					this.model.column_names == null ||
					this.model.column_names.length == 0
				) {
					condCheck = true;
					message = "Please add thne minimum 1 column";
				}

				if (!condCheck) {
					this.model.row_names.forEach((elementRow) => {
						if (!condCheck && !elementRow.is_deleted) {
							if (elementRow.is_required) {
								requiredCount++;

								if (elementRow.min == 0) {
									condCheck = true;
									message =
										"Min value must not be equal to None in the case of Required Check true in the row (" +
										elementRow.name +
										")";
								}

								if (!condCheck) {
									if (
										elementRow.min != 0 &&
										elementRow.max != 0
									) {
										if (elementRow.min > elementRow.max) {
											condCheck = true;
											message =
												"Min value must be less than or equal to Max value in the row (" +
												elementRow.name +
												")";
										}
									}
								}
							} else {
								if (elementRow.min != 0) {
									condCheck = true;
									message =
										"Min value must be equal to None in the case of Required Check false in the row (" +
										elementRow.name +
										")";
								}
							}
						}
					});

					this.model.column_names.forEach((elementColumn) => {
						if (!condCheck && !elementColumn.is_deleted) {
							if (elementColumn.is_required) {
								requiredCount++;

								if (elementColumn.min == 0) {
									condCheck = true;
									message =
										"Min value must not be equal to None in the case of Required Check true in the coloumn (" +
										elementColumn.name +
										")";
								}

								if (!condCheck) {
									if (
										elementColumn.min != 0 &&
										elementColumn.max != 0
									) {
										if (
											elementColumn.min >
											elementColumn.max
										) {
											condCheck = true;
											message =
												"Min value must be less than or equal to Max value in the column (" +
												elementColumn.name +
												")";
										}
									}
								}
							} else {
								if (elementColumn.min != 0) {
									condCheck = true;
									message =
										"Min value must be equal to None in the case of Required Check false in the column (" +
										elementColumn.name +
										")";
								}
							}
						}
					});

					if (this.model.constraints.is_required) {
						if (requiredCount == 0) {
							condCheck = true;
							if (
								this.model.constraints.logic_selection ==
								"row_select"
							) {
								message =
									"Please check the minimum one reuired field in any row";
							} else {
								message =
									"Please check the minimum one reuired field in any column";
							}
						}
					} else {
						if (requiredCount != 0) {
							condCheck = true;
							if (
								this.model.constraints.logic_selection ==
								"row_select"
							) {
								message =
									"Please check the Question level reuired field or uncheck the all row level required field";
							} else {
								message =
									"Please check the Question level reuired field or uncheck the all column level required field";
							}
						}
					}
				}

				//Row and Column Min and max value validation check error message
				if (condCheck) {
					var dismiss = document.getElementById("submitQuestions");
					dismiss.removeAttribute("data-dismiss");
					this.toastr.warning(message);
					return false;
				}
			}

			// remove Single Selection termination and jump for logic matrix

			// Multi Logic Answers;

			let bolCheckV: any = false,
				getShowMessage: any = "";
			[bolCheckV, getShowMessage] = this.multiLogicCondition(false);

			if (this.model.is_dd_child) {
				if (this.model.dd_parent_id == 0) {
					bolCheckV = true;
					getShowMessage =
						"Please Select the Parent Drilldown Question";
				}
			}

			// Current Usage Codition Check
			if (this.model.question_category == 1) {
				if (
					this.model.question_type != 0 &&
					this.model.question_type != 4 &&
					this.model.question_type != 28
				) {
					bolCheckV = true;
					getShowMessage =
						"Current Usage Category not implement to this question type";
				}
			}

			// Tag Implementation Codition Check
			if (this.model.question_category == 2 && !bolCheckV) {
				[bolCheckV, getShowMessage] = this.tagImplementaion();
			}

			//Show Error Message in case of Multi Logic Condition
			if (bolCheckV == true) {
				var dismiss = document.getElementById("submitQuestions");
				dismiss.removeAttribute("data-dismiss");
				if (getShowMessage != "") {
					return this.toastr.warning(getShowMessage);
				} else {
					return this.toastr.warning(
						"please fill the all Condition fields"
					);
				}
			}

			//Jump ID setting
			// this.jumpIdToIndexSetting();

			//Precondition Check
			if (
				this.model.constraints.precondition != null &&
				Array.isArray(this.model.constraints.precondition)
			) {
				if (this.model.constraints.precondition.length != 0) {
					var arrayCount = [];
					let bolCheck: any = false;

					this.model.constraints.precondition.forEach(
						(elementPreCondition, key) => {
							if (elementPreCondition.indexNo == 0) {
								bolCheck = true;
							} else if (
								elementPreCondition.options.length == 0
							) {
								bolCheck = true;
							} else if (elementPreCondition.rule == "") {
								if (elementPreCondition.options.length > 1) {
									bolCheck = true;
								}
							}

							if (elementPreCondition.options.length == 1) {
								elementPreCondition.rule = "OR";
							}
						}
					);

					if (bolCheck == true) {
						var dismiss =
							document.getElementById("submitQuestions");
						dismiss.removeAttribute("data-dismiss");
						return this.toastr.warning(
							"please fill the all precondition fields"
						);
					}
				}
			}

			this.model.task_form_id = this.TaskForm.id;
			// this.model.questionImage = this.questionImageUrl;
			var dismiss = document.getElementById("submitQuestions");
			dismiss.setAttribute("data-dismiss", "modal");

			this.projectService
				.saveQuestion(this.model)
				.then((data) => {
					this.valueChange.emit(this.model);
					this.getConditionArray = [];
				})
				.catch((err) => {
					console.error("Error in saving questions: " + err);
				});
			//  this.model.opts = [];
		} else {
			var dismiss = document.getElementById("submitQuestions");
			dismiss.removeAttribute("data-dismiss");
			if (this.model.label == null || this.model.label == "") {
				this.toastr.error("Please enter english text");
			} else {
				this.toastr.error("Please enter arabic text");
			}
		}
	}
	compareFn(c1, c2): boolean {
		// return  c1.position === c2.position;
		return c1 && c2 ? c1.position === c2.position : c1 === c2;
	}
	deleteQuestion() {
		if (confirm("Are you sure to delete " + this.model.label)) {
			this.model.task_form_id = this.TaskForm.id;
			this.model.is_deleted = true;
			this.projectService
				.saveQuestion(this.model)
				.then((data) => {
					this.valueChange.emit(this.model);
				})
				.catch((err) => {
					console.error("Error in saving questions: " + err);
				});
		}
	}
	addOption() {
		this.getConditionArray = [];
		let p = 1;
		if (this.model.opts === null || this.model.opts === undefined) {
			this.model.opts = [];
		}
		if (this.model.opts.length > 0) {
			p = this.model.opts.length + 1;
		}

		this.requiredFieldLength.push(new Array<number>(1));

		this.model.opts.push({
			id: 0,
			created_at: new Date(),
			updated_at: new Date(),
			jump_ids: [],
			label: "",
			labelar: "",
			answer_points: 0,
			select_deselect_label: "none",
			position: p,
			question_id: this.model.id,
			optional_label: "",
			has_extra_payload: false,
			option_image: null,
			tag_id: null,
			quota: null,
		});
	}

	addRowOption() {
		let p = 1;
		if (
			this.model.row_names === null ||
			this.model.row_names === undefined
		) {
			this.model.row_names = [];
			if (this.model.constraints.logic_selection == "row_select") {
				this.model.constraints.is_required = false;
			}
		}
		if (this.model.row_names.length > 0) {
			p = this.model.row_names.length + 1;
		}
		this.model.row_names.push({
			id: 0,
			name: "",
			name_ar: "",
			is_random: false,
			is_multiselect: false,
			is_required: false,
			select_deselect_label: "",
			min: 0,
			max: 0,
			is_deleted: false,
		});
	}
	addColumnOption() {
		let pp = 1;
		if (
			this.model.column_names === null ||
			this.model.column_names === undefined
		) {
			this.model.column_names = [];
			if (this.model.constraints.logic_selection == "column_select") {
				this.model.constraints.is_required = false;
			}
		}
		if (this.model.column_names.length > 0) {
			pp = this.model.column_names.length + 1;
		}
		this.model.column_names.push({
			id: 0,
			name: "",
			name_ar: "",
			is_random: false,
			is_multiselect: false,
			is_required: false,
			select_deselect_label: "",
			min: 0,
			max: 0,
			is_deleted: false,
		});
	}
	addMultiJumpCondition() {
		let ppp = 1;
		if (
			this.model.constraints.jumps === null ||
			this.model.constraints.jumps === undefined
		) {
			this.model.constraints.jumps = [];
		}
		if (this.model.constraints.jumps.length > 0) {
			ppp = this.model.constraints.jumps.length + 1;
		}
		this.model.constraints.jumps.push({
			indexNo: 0,
			rule: "",
			options: [],
			range: null,
		});

		// }
	}

	addMultiTerminateCondition() {
		let ppp = 1;
		if (
			this.model.constraints.termination === null ||
			this.model.constraints.termination === undefined
		) {
			this.model.constraints.termination = [];
		}
		if (this.model.constraints.termination.length > 0) {
			ppp = this.model.constraints.termination.length + 1;
		}
		if (this.model.question_type == 0 || this.model.question_type == 28) {
			this.model.constraints.termination.push({
				rule: "",
				options: [],
				range: null,
				terminateRule: "terminate",
				min: null,
				max: null,
			});
		} else {
			if (this.model.constraints.termination.length == 0) {
				this.model.constraints.termination.push({
					rule: "",
					options: [],
					range: null,
					terminateRule: "terminate",
					min: null,
					max: null,
				});
			} else {
				if (
					this.model.constraints.termination[0].terminateRule ==
					"terminate"
				) {
					this.model.constraints.termination.push({
						rule: "",
						options: [],
						range: null,
						terminateRule: "terminate",
						min: null,
						max: null,
					});
				} else {
					return this.toastr.warning(
						"Can not apply any other termination condition with 'Not Terminate'"
					);
				}
			}
		}

		// }
	}

	addNumberTerminateCondition() {
		let ppp = 1;
		if (
			this.model.constraints.termination === null ||
			this.model.constraints.termination === undefined
		) {
			this.model.constraints.termination = [];
		}
		if (this.model.constraints.termination.length > 0) {
			ppp = this.model.constraints.termination.length + 1;
		}

		this.model.constraints.termination.push({
			rule: "",
			options: null,
			range: [],
			terminateRule: "terminate",
			min: null,
			max: null,
		});
	}
	addMultiJumpNumberCondition() {
		let ppp = 1;
		if (
			this.model.constraints.jumps === null ||
			this.model.constraints.jumps === undefined
		) {
			this.model.constraints.jumps = [];
		}

		if (this.model.constraints.jumps.length > 0) {
			ppp = this.model.constraints.jumps.length + 1;
		}

		this.model.constraints.jumps.push({
			indexNo: null,
			rule: "",
			options: null,
			range: [],
			// {"min":null,"max":null,"equal":null,"greaterThen":null,"lessThen":null,"greaterEqual":null,"lessThenEqual":null}
		});
	}

	addRangeJumpConditions(index) {
		let ppp = 1;
		if (
			this.model.constraints.jumps[index].range === null ||
			this.model.constraints.jumps[index].range === undefined
		) {
			this.model.constraints.jumps[index].range = [];
		}

		if (this.model.constraints.jumps[index].range.length > 0) {
			ppp = this.model.constraints.jumps[index].range.length + 1;
		}

		this.model.constraints.jumps[index].range.push({
			condition: "",
			value: null,
			// {"min":null,"max":null,"equal":null,"greaterThen":null,"lessThen":null,"greaterEqual":null,"lessThenEqual":null}
		});
	}

	addRangeTerminationConditions(index) {
		let ppp = 1;
		if (
			this.model.constraints.termination[index].range === null ||
			this.model.constraints.termination[index].range === undefined
		) {
			this.model.constraints.termination[index].range = [];
		}

		if (this.model.constraints.termination[index].range.length > 0) {
			ppp = this.model.constraints.termination[index].range.length + 1;
		}

		this.model.constraints.termination[index].range.push({
			condition: "",
			value: null,
			// {"min":null,"max":null,"equal":null,"greaterThen":null,"lessThen":null,"greaterEqual":null,"lessThenEqual":null}
		});
	}

	addPreCondtionOpt() {
		let pp = 1;
		this.disablePreCondVal.push(false);
		if (
			this.model.constraints.precondition === null ||
			this.model.constraints.precondition === undefined
		) {
			this.model.constraints.precondition = [];
		}
		if (this.model.constraints.precondition.length > 0) {
			pp = this.model.constraints.precondition.length + 1;
		}
		this.model.constraints.precondition.push({
			indexNo: 0,
			rule: "",
			options: [],
		});
	}
	@ViewChild("backgroundFileInput") fileInput: ElementRef;

	selectDropdown(event): void {
		let [num, stringVal] = event.target.value.split(": ");

		if (
			stringVal == "All of the above" ||
			stringVal == "None of the above"
		) {
			this.model.constraints.validations.minOpt = null;
			this.model.constraints.validations.maxOpt = null;
		}
	}

	onUploadOutput(output: UploadOutput, c: string, option: Opt, index): void {
		switch (output.type) {
			case "allAddedToQueue":
				const file = c === "icon" ? this.iconFile : this.backroundFile;

				const name = file ? file.name : new Date().getTime() + ".jpeg";
				const reader = new FileReader();
				// tslint:disable-next-line:comment-format
				//name = this.model.name.replace(/\s/g, '') + name;

				this.getBase64fromFile(file.nativeFile).then(
					(d: base64Response) => {
						const headers = new HttpHeaders().set(
							"Content-Type",
							"multipart/form-data"
						);
						headers.set("Accept", "application/json");
						// tslint:disable-next-line:max-line-length
						if (c === "icon") {
							this.model.iconurl =
								"../../assets/images/loading.gif";
						} else {
							this.model.backroundFile =
								"../../assets/images/loading.gif";
						}
						this.http
							.post(this.uploadUrl + name, d.body, { headers })
							.subscribe(
								(data: S3Response) => {
									if (c === "icon") {
										this.model.iconurl = data.Location;
									} else {
										this.model.backroundFile =
											data.Location;
									}
									if (
										this.model.opts &&
										this.model.opts.length > 0
									) {
										// check
									} else {
										this.model.opts = [];
									}
									//this.model.opts.forEach((val,index)=>{})
									this.model.opts = this.model.opts.map(
										(x) => {
											if (x.id === option.id) {
												option.option_image =
													data.Location;
											}
											return x;
										}
									);

									// if(this.model.optsRow && this.model.optsRow.length > 0)
									// {
									//   // check
									// }
									// else{
									//   this.model.optsRow = [];
									// }
									// //this.model.opts.forEach((val,index)=>{})
									// this.model.optsRow = this.model.optsRow.map((x) =>
									// {

									//   if (x.id === option.id){
									//     option.option_image = data.Location;
									//   }
									//   return x;
									// });

									// if(this.model.optsColumn && this.model.optsColumn.length > 0)
									// {
									//   // check
									// }
									// else{
									//   this.model.optsColumn = [];
									// }
									// //this.model.opts.forEach((val,index)=>{})
									// this.model.optsColumn = this.model.optsColumn.map((x) =>
									// {

									//   if (x.id === option.id){
									//     option.option_image = data.Location;
									//   }
									//   return x;
									// });

									// this.imageUrl = data.Location;

									//

									// this.model.files.push(file);
									this.toastr.success("File Uploaded");
								},
								(error) => {
									console.log(
										"Error  in uploaing file: " + error
									);
								}
							);
					}
				);
				break;
			case "addedToQueue":
				if (typeof output.file !== "undefined") {
					if (c === "icon") {
						this.iconFile = output.file;
					} else {
						this.backroundFile = output.file;
					}
				}
				break;
			case "uploading":
				if (typeof output.file !== "undefined") {
					// update current data in files array for uploading file
					// tslint:disable-next-line:no-shadowed-variable
					const index = this.files.findIndex(
						(file) =>
							typeof output.file !== "undefined" &&
							file.id === output.file.id
					);
					this.files[index] = output.file;
				}
				break;
			case "removed":
				// remove file from array when removed
				// tslint:disable-next-line:no-shadowed-variable
				this.files = this.files.filter(
					(file: UploadFile) => file !== output.file
				);
				break;
			case "dragOver":
				this.dragOver = true;
				break;
			case "dragOut":
			case "drop":
				this.dragOver = false;
				break;
			case "done":
				// tslint:disable-next-line:max-line-length
				if (c === "icon") {
					this.model.iconurl = output.file.response.Location;
				} else {
					this.model.backroundFile = output.file.response.Location;
				}
				// The file is downloaded
				////////////////////////////////////////////////////////////////////////
				if (
					this.model.files === null ||
					this.model.files.length === 0 ||
					this.model.files.find(
						(f) => f.upload_type === (c === "icon" ? 0 : 1)
					) === undefined
				) {
					this.model.files.push({
						id: null,
						created_at: new Date(),
						uploadable_type: "Project",
						uploadable_id: this.model.id,
						upload_type: c === "icon" ? 0 : 1,
						url: output.file.response.Location,
						is_deleted: false,
						updated_at: new Date(),
					});
				} else {
					this.model.files.find(
						(f) => f.upload_type === (c === "icon" ? 0 : 1)
					).url = output.file.response.Location;
				}
				break;
		}
	}
	processBulk() {
		let p = 1;
		if (this.model.opts === null || this.model.opts === undefined) {
			this.model.opts = [];
		}
		if (this.model.opts.length > 0) {
			p = this.model.opts.length + 1;
		}

		if (this.bulkoptions == "") {
			this.toastr.warning("Please enter the comma separated text");
			return false;
		}
		const texts = this.bulkoptions.split(",");

		texts.forEach((element) => {
			this.model.opts.push({
				id: 0,
				created_at: new Date(),
				updated_at: new Date(),
				jump_ids: [],
				label: element,
				labelar: "",
				answer_points: 0,
				select_deselect_label: "none",
				position: p,
				question_id: this.model.id,
				optional_label: "",
				has_extra_payload: false,
				option_image: null,
			});

			++p;
			this.requiredFieldLength.push(new Array<number>(1));
		});

		this.bulkoptions = "";
	}

	getBase64fromFile(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				console.log(`getBase64fromFile success.`);
				const spliced = reader.result.toString().split(",");
				const header = spliced[0];
				spliced.shift();
				resolve({
					header: header,
					body: spliced.join(""),
				});
			};
			reader.onerror = (err) => {
				console.log(`getBase64fromFile failed.`);
				reject(err);
			};
		});
	}

	onUploadPicture(output: UploadOutput, c: string): void {
		switch (output.type) {
			case "allAddedToQueue":
				const file =
					c === "icon" ? this.iconFile : this.questionImageFile;

				let name = file ? file.name : new Date().getTime() + ".jpeg";
				const reader = new FileReader();
				// name = this.model.name.replace(/\s/g, '') + name;

				this.getBase64fromFile(file.nativeFile).then(
					(d: base64Response) => {
						const headers = new HttpHeaders().set(
							"Content-Type",
							"multipart/form-data"
						);
						headers.set("Accept", "application/json");
						// tslint:disable-next-line:max-line-length
						if (c === "icon") {
							this.model.iconurl =
								"../../assets/images/loading.gif";
						} else {
							this.model.question_image =
								"../../assets/images/loading.gif";
						}
						this.http
							.post(
								this.uploadUrlForQuestionImage + name,
								d.body,
								{ headers }
							)
							.subscribe(
								(data: S3Response) => {
									if (c === "icon") {
										this.model.iconurl = data.Location;
									} else {
										this.model.question_image =
											data.Location;
									}
									this.questionImageUrl = data.Location;
									this.toastr.success("File Uploaded");
								},
								(error) => {
									console.log(
										"Error  in uploaing file: " + error
									);
								}
							);
					}
				);
				break;
			case "addedToQueue":
				if (typeof output.file !== "undefined") {
					if (c === "icon") {
						this.iconFile = output.file;
					} else {
						this.questionImageFile = output.file;
					}
				}
				break;
			case "uploading":
				if (typeof output.file !== "undefined") {
					// update current data in files array for uploading file
					const index = this.files.findIndex(
						(file) =>
							typeof output.file !== "undefined" &&
							file.id === output.file.id
					);
					this.files[index] = output.file;
				}
				break;
			case "removed":
				// remove file from array when removed
				this.files = this.files.filter(
					(file: UploadFile) => file !== output.file
				);
				break;
			case "dragOver":
				this.dragOver = true;
				break;
			case "dragOut":
			case "drop":
				this.dragOver = false;
				break;
			case "done":
				// tslint:disable-next-line:max-line-length
				if (c === "icon") {
					this.model.iconurl = output.file.response.Location;
				} else {
					this.model.question_image = output.file.response.Location;
				}
				// The file is downloaded
				////////////////////////////////////////////////////////////////////////
				if (
					this.model.files === null ||
					this.model.files.length === 0 ||
					this.model.files.find(
						(f) => f.upload_type === (c === "icon" ? 0 : 1)
					) === undefined
				) {
					this.model.files.push({
						id: null,
						created_at: new Date(),
						uploadable_type: "Project",
						uploadable_id: this.model.id,
						upload_type: c === "icon" ? 0 : 1,
						url: output.file.response.Location,
						is_deleted: false,
						updated_at: new Date(),
					});
				} else {
					this.model.files.find(
						(f) => f.upload_type === (c === "icon" ? 0 : 1)
					).url = output.file.response.Location;
				}
				break;
		}
	}
	/////////////////////////////////////////////////////////////////////

	////////////////////////////////////////////////////////////////////

	getDrillValue(event) {}
	changeSpontaneous(event) {
		this.checkSpontCond = event.target.checked;
		if (event.target.checked == false) {
			this.model.min_field_value = 1;
			this.model.max_field_value = 1;
		}
	}
	changeTags(event) {
		if (event.target.checked) {
			this.model.is_tag_parent = false;
			if (this.model.opts != null) {
				if (this.model.opts.length != 0) {
					this.model.opts.forEach((elementOptions) => {
						elementOptions.tag_id = null;
						elementOptions.quota = null;
					});
				}
			}
		} else {
			this.model.is_tag_parent = true;
		}
	}
	checkAllFieldsInput = false;
	getConditionArray = [];
	saveLogicMatrixOption() {
		this.getConditionArray = [];
		this.model.multiple_terminate_condition = [];
		this.model.multiple_jump_condition = [];
		this.checkAllFieldsInput = false;

		var getRowValue = 0;
		var getColumnValue = 0;
		this.model.row_names.forEach((element) => {
			if (!element.is_deleted) {
				if (element.name != "") {
					getRowValue++;
				} else {
					this.checkAllFieldsInput = true;
					return this.toastr.warning(
						"Please Fill the all row English titles"
					);
				}
			}
		});
		this.model.column_names.forEach((element) => {
			if (!element.is_deleted) {
				if (element.name != "") {
					getColumnValue++;
				} else {
					this.checkAllFieldsInput = true;
					return this.toastr.warning(
						"Please Fill the all column English titles"
					);
				}
			}
		});

		if (this.checkAllFieldsInput == false) {
			this.model.row_names.forEach((elementRow) => {
				if (!elementRow.is_deleted) {
					this.model.column_names.forEach((elementColumn) => {
						if (!elementColumn.is_deleted) {
							this.getConditionArray.push({
								name: elementRow.name + elementColumn.name,
							});
						}
					});
				}
			});
		}
	}

	changeDrillOption(event) {
		if (this.model.question_type === 28) {
			this.model.is_drilldown_rowwise = event.target.checked;
		} else {
			this.model.is_drilldown_rowwise = false;
		}
	}
	changeDrillRowWise(event) {
		// if (event.target.checked == false) {
		// 	this.model.is_drilldown = false;
		// }
	}

	changeDrillChildOption(event) {
		if (!event.target.checked) {
			this.model.dd_parent_id = 0;
		}
	}

	changeQuestionRequired(event) {
		// if (this.model.question_type == 28) {
		//   if (event.target.checked) {
		//     if (this.model.constraints.logic_selection == "row_select") {
		//       if (this.model.row_names == null || this.model.row_names.length == 0) {
		//         event.target.checked = false;
		//         return this.toastr.warning("Please add the rows first");
		//       }
		//       let requiredCheck = false;
		//       this.model.row_names.forEach(elementRow => {
		//         if (elementRow.is_required) {
		//           requiredCheck = true;
		//         }
		//       });
		//       if (!requiredCheck) {
		//         event.target.checked = false;
		//         return this.toastr.warning("Minimum one check is required in any row");
		//       }
		//     }
		//     else
		//     {
		//       if (this.model.column_names == null || this.model.column_names.length == 0) {
		//         event.target.checked = false;
		//         return this.toastr.warning("Please add the column first");
		//       }
		//       let requiredCheck = false;
		//       this.model.column_names.forEach(elementcolumn => {
		//         if (elementcolumn.is_required) {
		//           requiredCheck = true;
		//         }
		//       });
		//       if (!requiredCheck) {
		//         event.target.checked = false;
		//         return this.toastr.warning("Minimum one check is required in any column");
		//       }
		//     }
		//   }
		//   else
		//   {
		//     if (this.model.constraints.logic_selection == "row_select") {
		//       if (this.model.row_names != null || this.model.row_names.length != 0) {
		//         let requiredCheck = false;
		//         this.model.row_names.forEach(elementRow => {
		//           if (elementRow.is_required) {
		//             requiredCheck = true;
		//           }
		//         });
		//         if (requiredCheck) {
		//           event.target.checked = true;
		//           return this.toastr.warning("Please uncheck the required field in all rows");
		//         }
		//       }
		//     }
		//     else
		//     {
		//       if (this.model.column_names != null || this.model.column_names.length != 0) {
		//         let requiredCheck = false;
		//         this.model.column_names.forEach(elementColumn => {
		//           if (elementColumn.is_required) {
		//             requiredCheck = true;
		//           }
		//         });
		//         if (requiredCheck) {
		//           event.target.checked = true;
		//           return this.toastr.warning("Please uncheck the required field in all columns");
		//         }
		//       }
		//     }
		//   }
		// }
	}

	getMultiOptionArray = [];

	disablePreCondVal = [];
	disablePreCondValRule = [];
	getPreCondQuestion(event, indexFront) {
		var [space, id] = event.target.value.split(" ");
		if (id != 0) {
			var getAllQuestions = this.sprintService.getAllQuestions;

			// this.getMultiOptionArray = [];
			this.disablePreCondValRule[indexFront] = false;
			var arrayValue = [];
			var condCheck = false;
			for (let index = 0; index < getAllQuestions.length; index++) {
				if (getAllQuestions[index].id == id) {
					if (
						getAllQuestions[index].question_type != 28 &&
						getAllQuestions[index].question_type != 2 &&
						getAllQuestions[index].question_type != 3
					) {
						this.disablePreCondVal[indexFront] = false;
						if (getAllQuestions[index].opts.length != 0) {
							getAllQuestions[index].opts.forEach((element) => {
								if (!element.is_deleted) {
									arrayValue.push(element.label);
								}
							});
						}
					}

					if (getAllQuestions[index].question_type == 28) {
						this.disablePreCondVal[indexFront] = false;
						if (
							getAllQuestions[index].row_names.length != 0 &&
							getAllQuestions[index].column_names.length != 0
						) {
							getAllQuestions[index].row_names.forEach(
								(elementRow) => {
									if (!elementRow.is_deleted) {
										getAllQuestions[
											index
										].column_names.forEach(
											(elementColumn) => {
												if (!elementColumn.is_deleted) {
													arrayValue.push(
														elementRow.name +
															elementColumn.name
													);
												}
											}
										);
									}
								}
							);
						}
					}

					if (
						getAllQuestions[index].question_type == 2 ||
						getAllQuestions[index].question_type == 3
					) {
						this.disablePreCondVal[indexFront] = true;
						this.getMultiOptionArray[indexFront] = null;
						this.model.constraints.precondition[
							indexFront
						].options = null;
						condCheck = true;
						this.toastr.warning(
							"Pre Condition is not applicable in this question"
						);
					}

					if (getAllQuestions[index].question_type == 4) {
						this.disablePreCondValRule[indexFront] = true;
						this.model.constraints.precondition[indexFront].rule =
							"OR";
					}
				}
			}
			if (condCheck == false) {
				this.getMultiOptionArray[indexFront] = arrayValue;
			}
		} else {
			this.getMultiOptionArray[indexFront] = [];
		}
	}

	getMultiConditionOptionArray = [];
	SavemultiCondtionOption() {
		this.getMultiConditionOptionArray = [];
		if (this.model.opts != null) {
			if (this.model.opts.length != 0) {
				var arrayOPt = [];
				var bolCon = false;
				this.model.opts.forEach((elementOpt) => {
					if (bolCon == false) {
						if (!elementOpt.is_deleted) {
							if (elementOpt.label == "") {
								bolCon = true;
							} else {
								arrayOPt.push({
									id: elementOpt.label,
									name: elementOpt.label,
								});
							}
						}
					}
				});

				if (bolCon == false) {
					this.getMultiConditionOptionArray = arrayOPt;
				} else {
					this.toastr.warning(
						"Please fill the all option english labels"
					);
				}
			}
		}
	}

	changeQuestionCategory(event) {
		if (event[0].value == 0) {
			this.model.is_cu_child = false;
			this.model.cu_parent_id = null;
		}

		if (event[0].value != 2) {
			if (this.model.opts != null) {
				this.model.opts.forEach((elementOpt) => {
					elementOpt.tag_id = null;
					elementOpt.quota = null;
				});
			}
			this.model.is_tag_child = false;
			this.model.is_tag_parent = false;
		} else if (event[0].value == 2) {
			if (this.model.question_type == 4) {
				this.model.is_tag_parent = true;
				this.model.is_tag_child = false;
			} else {
				this.model.is_tag_parent = false;
				this.model.is_tag_child = true;
			}
		}
	}

	changeQuestionType(event) {
		if (this.model.question_type != 4) {
			if (this.model.opts != null) {
				this.model.opts.forEach((elementOpt) => {
					elementOpt.tag_id = null;
					elementOpt.quota = null;
				});
			}
			this.model.is_tag_parent = false;
		}

		if (this.model.question_type == 4 && !this.model.is_tag_parent) {
			if (this.model.opts != null) {
				this.model.opts.forEach((elementOpt) => {
					elementOpt.tag_id = null;
					elementOpt.quota = null;
				});
			}
		}
	}

	numberOnly(event): boolean {
		// if (this.loginForm.value.ContactCode == "+966" || this.loginForm.value.ContactCode == "+971" || this.loginForm.value.ContactCode == "+962") {
		//   this.maxContactLength = 9;
		// } else if (this.loginForm.value.ContactCode == "+20") {
		//   this.maxContactLength = 10;
		// } else if (this.loginForm.value.ContactCode == "+965" || this.loginForm.value.ContactCode == "+968") {
		//   this.maxContactLength = 8;
		// }

		const charCode = event.which ? event.which : event.keyCode;

		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	checkValidateMultValue() {
		let disableMessage: any = false;
		this.model.opts.forEach((elementOPT) => {
			if (!elementOPT.is_deleted) {
				if (
					elementOPT.select_deselect_label == "All of the above" ||
					elementOPT.select_deselect_label == "None of the above"
				) {
					if (
						this.model.constraints.validations.minOpt > 0 ||
						this.model.constraints.validations.maxOpt > 0
					) {
						this.model.constraints.validations.minOpt = null;
						this.model.constraints.validations.maxOpt = null;
						disableMessage = true;
					}
				}
			}
		});
		if (disableMessage == true) {
			return this.toastr.warning(
				"Validation is apply only when Select All and Deselect All not selected"
			);
		}

		if (
			this.model.constraints.validations.minOpt != null &&
			this.model.constraints.validations.maxOpt != null
		) {
			if (
				this.model.constraints.validations.minOpt >
				this.model.constraints.validations.maxOpt
			) {
				this.model.constraints.validations.maxOpt = null;
				return this.toastr.warning(
					"Validation of maximum value must be greater than minimun value"
				);
			}
		}
	}

	ChangeTerminateValidation(index) {
		if (
			this.model.constraints.termination[index].min != null &&
			this.model.constraints.termination[index].max != null
		) {
			if (
				this.model.constraints.termination[index].min >
				this.model.constraints.termination[index].max
			) {
				this.model.constraints.termination[index].max = null;
				return this.toastr.warning(
					"Validation for termination of maximum value must be greater than minimun value"
				);
			}
		}
	}
	changeTerminationRule(index) {
		if (this.model.constraints.termination[index].rule == "AND") {
			this.model.constraints.termination[index].min = null;
			this.model.constraints.termination[index].max = null;
		}
	}

	changeTerminationExclusive(index) {
		if (this.model.constraints.termination.length > 1) {
			if (
				this.model.constraints.termination[index].terminateRule ==
				"notTerminate"
			) {
				this.model.constraints.termination[index].terminateRule =
					"terminate";
				return this.toastr.warning(
					"Can not select both Terminate' and 'Not Terminate' within the same question"
				);
			}
		}
	}

	multiLogicCondition(bolCheckV) {
		let showMessage = "";
		if (this.model.question_type == 0) {
			if (this.model.opts != null) {
				if (this.model.opts.length != 0) {
					this.model.opts.forEach((elementOPT) => {
						elementOPT.jump_ids = [];
						if (elementOPT.select_deselect_label == null) {
							elementOPT.select_deselect_label = "none";
						}

						if (
							elementOPT.labelar == "" &&
							!elementOPT.is_deleted
						) {
							bolCheckV = true;
							showMessage =
								"Please fill the all option Arabic titles";
						}
						if (elementOPT.label == "" && !elementOPT.is_deleted) {
							bolCheckV = true;
							showMessage =
								"Please fill the all option English titles";
						}
					});
				}
			}

			if (this.model.constraints.jumps != null) {
				if (this.model.constraints.jumps.length != 0) {
					this.model.constraints.jumps.forEach((elementJump) => {
						if (elementJump.options.length == 1) {
							elementJump.rule = "";
						}

						if (elementJump.options.length == 0) {
							bolCheckV = true;
						} else if (
							elementJump.options.length > 1 &&
							elementJump.rule == ""
						) {
							bolCheckV = true;
						} else if (elementJump.indexNo == 0) {
							bolCheckV = true;
						}

						elementJump.options.forEach(
							(elementCondOpt, getIndex) => {
								let condCheck = true;
								this.model.opts.forEach((elementQuesOpt) => {
									if (condCheck == true) {
										if (
											elementCondOpt ==
											elementQuesOpt.label
										) {
											condCheck = false;
										}
									}
								});
								if (condCheck) {
									bolCheckV = condCheck;
									showMessage =
										"Jump Options of " +
										elementCondOpt +
										" is not exit in the options";
									return false;
								}
							}
						);
					});
				}
			}

			if (this.model.constraints.termination != null) {
				if (this.model.constraints.termination.length != 0) {
					this.model.constraints.termination.forEach(
						(elementTermination) => {
							if (elementTermination.options.length == 1) {
								elementTermination.rule = "";
							}

							if (elementTermination.options.length == 0) {
								bolCheckV = true;
							} else if (
								elementTermination.options.length > 1 &&
								elementTermination.rule == ""
							) {
								bolCheckV = true;
							}

							elementTermination.options.forEach(
								(elementCondOpt) => {
									let condCheck = true;
									this.model.opts.forEach(
										(elementQuesOpt) => {
											if (condCheck == true) {
												if (
													elementCondOpt ==
													elementQuesOpt.label
												) {
													condCheck = false;
												}
											}
										}
									);
									if (condCheck) {
										bolCheckV = condCheck;
										showMessage =
											"Termination Options of " +
											elementCondOpt +
											" is not exit in the options";
										return false;
									}
								}
							);
						}
					);
				}
			}
		}

		if (
			this.model.question_type == 2 ||
			this.model.question_type == 3 ||
			this.model.question_type == 29
		) {
			if (this.model.opts != null) {
				if (this.model.opts.length != 0) {
					this.model.opts.forEach((elementOPT) => {
						elementOPT.select_deselect_label = "none";
					});
				}
			}

			if (this.model.constraints.termination != null) {
				if (this.model.constraints.termination.length != 0) {
					this.model.constraints.termination.forEach(
						(elementTermination) => {
							if (elementTermination.range.length == 1) {
								elementTermination.rule = "";
							}

							if (elementTermination.range.length == 0) {
								bolCheckV = true;
							} else if (
								elementTermination.range.length > 1 &&
								elementTermination.rule == ""
							) {
								bolCheckV = true;
							}

							if (elementTermination.range.length > 0) {
								elementTermination.range.forEach(
									(elementRangeV) => {
										if (
											elementRangeV.value == null ||
											elementRangeV.condition == ""
										) {
											bolCheckV = true;
										}
									}
								);
							}
						}
					);
				}
			}

			if (this.model.constraints.jumps != null) {
				if (this.model.constraints.jumps.length != 0) {
					this.model.constraints.jumps.forEach((elementJump) => {
						if (elementJump.range.length == 1) {
							elementJump.rule = "";
						}

						if (elementJump.range.length == 0) {
							bolCheckV = true;
						} else if (
							elementJump.range.length > 1 &&
							elementJump.rule == ""
						) {
							bolCheckV = true;
						} else if (elementJump.indexNo == 0) {
							bolCheckV = true;
						}

						if (elementJump.range.length > 0) {
							elementJump.range.forEach((elementRangeV) => {
								if (
									elementRangeV.value == null ||
									elementRangeV.condition == ""
								) {
									bolCheckV = true;
								}
							});
						}
					});
				}
			}
		}

		if (this.model.question_type == 4 || this.model.question_type == 30) {
			if (this.model.constraints.termination != null) {
				if (this.model.constraints.termination.length != 0) {
					this.model.constraints.termination.forEach(
						(elementTermination) => {
							elementTermination.rule = "";
							if (elementTermination.options.length == 0) {
								bolCheckV = true;
							}

							elementTermination.options.forEach(
								(elementCondOpt) => {
									let condCheck = true;
									this.model.opts.forEach(
										(elementQuesOpt) => {
											if (condCheck == true) {
												if (
													elementCondOpt ==
													elementQuesOpt.label
												) {
													condCheck = false;
												}
											}
										}
									);
									if (condCheck) {
										bolCheckV = condCheck;
										showMessage =
											"Termination Options of " +
											elementCondOpt +
											" is not exit in the options";
										return false;
									}
								}
							);
						}
					);
				}
			}

			if (this.model.opts != null) {
				this.model.opts.forEach((elementOPT) => {
					if (elementOPT.labelar == "" && !elementOPT.is_deleted) {
						bolCheckV = true;
						showMessage =
							"Please fill the all option Arabic titles";
					}
					if (elementOPT.label == "" && !elementOPT.is_deleted) {
						bolCheckV = true;
						showMessage =
							"Please fill the all option English titles";
					}
				});
			}

			if (this.model.is_drilldown) {
				bolCheckV = true;
				showMessage =
					"Drilldown is not acceptable in Single Selection, please uncheck";
			}
		}

		if (this.model.question_type == 28) {
			let dummyOPtionArray = [];
			let endCcond = false;
			if (
				this.model.row_names != null &&
				this.model.column_names != null
			) {
				this.model.row_names.forEach((element) => {
					if (element.name_ar == "" && !element.is_deleted) {
						bolCheckV = true;
						endCcond = true;
						showMessage = "Please Fill the all row Arabic titles";
					}
					if (element.name == "" && !element.is_deleted) {
						bolCheckV = true;
						endCcond = true;
						showMessage = "Please Fill the all row English titles";
					}
				});
				this.model.column_names.forEach((element) => {
					if (element.name_ar == "" && !element.is_deleted) {
						bolCheckV = true;
						endCcond = true;
						showMessage =
							"Please Fill the all column Arabic titles";
					}
					if (element.name == "" && !element.is_deleted) {
						bolCheckV = true;
						endCcond = true;
						showMessage =
							"Please Fill the all column English titles";
					}
				});

				this.model.row_names.forEach((elementRow) => {
					if (!elementRow.is_deleted) {
						this.model.column_names.forEach((elementColumn) => {
							if (!elementColumn.is_deleted) {
								dummyOPtionArray.push({
									name: elementRow.name + elementColumn.name,
								});
							}
						});
					}
				});
			}

			if (endCcond) {
				return [bolCheckV, showMessage];
			}

			if (this.model.constraints.jumps != null) {
				if (this.model.constraints.jumps.length != 0) {
					this.model.constraints.jumps.forEach((elementJump) => {
						if (elementJump.options.length == 1) {
							elementJump.rule = "";
						}

						if (elementJump.options.length == 0) {
							bolCheckV = true;
						} else if (
							elementJump.options.length > 1 &&
							elementJump.rule == ""
						) {
							bolCheckV = true;
						} else if (elementJump.indexNo == 0) {
							bolCheckV = true;
						}

						elementJump.options.forEach((elementCondOpt) => {
							let condCheck = true;
							dummyOPtionArray.forEach((elementQuesOpt) => {
								if (condCheck == true) {
									if (elementCondOpt == elementQuesOpt.name) {
										condCheck = false;
									}
								}
							});
							if (condCheck) {
								bolCheckV = condCheck;
								showMessage =
									"Jump Options of " +
									elementCondOpt +
									" is not exit in the options of ROw and Column";
								return false;
							}
						});
					});
				}
			}

			if (this.model.constraints.termination != null) {
				if (this.model.constraints.termination.length != 0) {
					this.model.constraints.termination.forEach(
						(elementTermination) => {
							if (elementTermination.options.length == 1) {
								elementTermination.rule = "";
							}

							if (elementTermination.options.length == 0) {
								bolCheckV = true;
							} else if (
								elementTermination.options.length > 1 &&
								elementTermination.rule == ""
							) {
								bolCheckV = true;
							}

							elementTermination.options.forEach(
								(elementCondOpt) => {
									let condCheck = true;
									dummyOPtionArray.forEach(
										(elementQuesOpt) => {
											if (condCheck == true) {
												if (
													elementCondOpt ==
													elementQuesOpt.name
												) {
													condCheck = false;
												}
											}
										}
									);
									if (condCheck) {
										bolCheckV = condCheck;
										showMessage =
											"Termination Options of " +
											elementCondOpt +
											" is not exit in the options of ROw and Column";
										return false;
									}
								}
							);
						}
					);
				}
			}
		}
		return [bolCheckV, showMessage];
	}

	changelogicMatrixSelection(event) {
		if (event[0].value == "row_select") {
			if (this.model.column_names) {
				this.model.column_names.forEach((elementColumn) => {
					elementColumn.is_multiselect = false;
					elementColumn.is_required = false;
					elementColumn.select_deselect_label = "";
					elementColumn.min = 0;
					elementColumn.max = 0;
				});
			}
		} else if (event[0].value == "column_select") {
			if (this.model.row_names) {
				this.model.row_names.forEach((elementRow) => {
					elementRow.is_multiselect = false;
					elementRow.is_required = false;
					elementRow.select_deselect_label = "";
					elementRow.min = 0;
					elementRow.max = 0;
				});
			}
		}
		// else if (event[0].value == "none") {
		//   if (this.model.column_names) {
		//     this.model.column_names.forEach(elementColumn => {
		//       elementColumn.is_multiselect = false;
		//       elementColumn.select_deselect_label = "";
		//       elementColumn.min = 0;
		//       elementColumn.max = 0;
		//     });
		//   }

		//   if (this.model.row_names) {
		//     this.model.row_names.forEach(elementRow => {
		//       elementRow.is_multiselect = false;
		//       elementRow.select_deselect_label = "";
		//       elementRow.min = 0;
		//       elementRow.max = 0;
		//     });
		//   }
		// }
	}

	changeColumnMulti(event, index) {
		if (!event.target.checked) {
			this.model.column_names[index].select_deselect_label = "";
			this.model.column_names[index].min = 0;
			this.model.column_names[index].max = 0;
		}
	}
	changeRowMulti(event, index) {
		if (!event.target.checked) {
			this.model.row_names[index].select_deselect_label = "";
			this.model.row_names[index].min = 0;
			this.model.row_names[index].max = 0;
		}
	}
	changeRowRequired(event, index) {
		if (event.target.checked) {
			this.model.row_names[index].select_deselect_label = "";
			this.model.row_names[index].min = 1;
			this.model.row_names[index].max = 0;

			if (!this.model.constraints.is_required) {
				this.model.constraints.is_required = true;
			}
		} else {
			this.model.row_names[index].select_deselect_label = "";
			this.model.row_names[index].min = 0;
			this.model.row_names[index].max = 0;
		}
	}
	changeColumnRequired(event, index) {
		if (event.target.checked) {
			this.model.column_names[index].select_deselect_label = "";
			this.model.column_names[index].min = 1;
			this.model.column_names[index].max = 0;

			if (!this.model.constraints.is_required) {
				this.model.constraints.is_required = true;
			}
		} else {
			this.model.column_names[index].select_deselect_label = "";
			this.model.column_names[index].min = 0;
			this.model.column_names[index].max = 0;
		}
	}

	ChangeRowValidation(index) {
		if (
			this.model.row_names[index].min != 0 &&
			this.model.row_names[index].max != 0
		) {
			if (
				this.model.row_names[index].min >
				this.model.row_names[index].max
			) {
				this.model.row_names[index].max = 0;
				this.model.row_names[index].min = 0;
				return this.toastr.warning(
					"Maximum value must be greater than Minimun value of " +
						this.model.row_names[index].name +
						" row"
				);
			}
		}
	}

	ChangeColumnValidation(index) {
		if (
			this.model.column_names[index].min != 0 &&
			this.model.column_names[index].max != 0
		) {
			if (
				this.model.column_names[index].min >
				this.model.column_names[index].max
			) {
				this.model.column_names[index].max = 0;
				this.model.column_names[index].min = 0;
				return this.toastr.warning(
					"Maximum value must be greater than Minimun value of " +
						this.model.column_names[index].name +
						" column"
				);
			}
		}
	}

	jumpIdToIndexSetting() {
		if (this.model.question_type == 4) {
			if (this.model.opts != null) {
				if (this.model.opts.length != 0) {
					this.model.opts.forEach((elementOPT) => {
						if (elementOPT.jump_ids.length != 0) {
							this.TaskForm.questions.forEach(
								(elementAllQues) => {
									if (
										elementAllQues.id ==
										elementOPT.jump_ids[0]
									) {
										elementOPT.jump_ids[0] =
											elementAllQues.position;
									}
								}
							);
						}
					});
				}
			}
		} else {
			if (this.model.constraints.jumps != null) {
				if (this.model.constraints.jumps.length != 0) {
					this.model.constraints.jumps.forEach((elementJump) => {
						this.TaskForm.questions.forEach((elementAllQues) => {
							if (elementJump.indexNo == elementAllQues.id) {
								elementJump.indexNo = elementAllQues.position;
							}
						});
					});
				}
			}
		}
	}

	multimediaUploads() {
		if (this.model.question_type == 31) {
			this.model.constraints.files.type = "image";
			this.model.constraints.files.duration = 0;
		} else if (this.model.question_type == 32) {
			this.model.constraints.files.type = "audio";
		} else if (this.model.question_type == 33) {
			this.model.constraints.files.type = "video";
		}
		if (
			this.model.question_type == 31 ||
			this.model.question_type == 32 ||
			this.model.question_type == 33
		) {
			return true;
		}
		return false;
	}
	tagImplementaion() {
		let Is_query = false;
		let message = "";

		if (
			this.model.is_tag_parent &&
			this.sprintService.parentQuestionTagID != null &&
			this.sprintService.parentQuestionTagID != 0 &&
			this.model.id == 0
		) {
			Is_query = true;
			message = "Do not create more than than 1 tag parent question";
		} else if (this.model.is_tag_parent && this.model.question_type != 4) {
			Is_query = true;
			message =
				"Parent tag category not implement except single Selection Question Type";
		} else if (
			this.model.is_tag_child &&
			!this.sprintService.parentQuestionTagID
		) {
			Is_query = true;
			message = "Please add the parent tag question first";
		} else if (
			this.model.is_tag_child &&
			this.model.tag_condition.length == 0
		) {
			Is_query = true;
			message = "Please add the atleast one parent tag";
		}

		if (!Is_query) {
			if (this.model.opts == null && this.model.is_tag_parent) {
				Is_query = true;
				message = "Please add the options";
			} else {
				if (this.model.is_tag_parent) {
					let isQuota = false;
					this.model.opts.forEach((elementOpt) => {
						if (!elementOpt.is_deleted) {
							if (elementOpt.tag_id == null) {
								Is_query = true;
								message =
									"Please select the tag against the all options";
							}

							if (elementOpt.quota != null) {
								isQuota = true;
							}
						}
					});

					if (isQuota) {
						let quotaCount = 0;
						let isQuotaError = true;
						this.model.opts.forEach((options) => {
							if (!options.is_deleted) {
								if (options.quota == null) {
									isQuotaError = false;
									Is_query = true;
									message =
										"Please add quota in all quota fields in tag section.";
								} else if (options.quota < 1) {
									isQuotaError = false;
									Is_query = true;
									message = "Quota must be greater than 1";
								} else {
									quotaCount = quotaCount + options.quota;
								}
							}
						});

						if (isQuotaError) {
							if (quotaCount != 100) {
								Is_query = true;
								message = "Quota must be equal to 100";
							}
						}
					}
				}
			}
		}

		return [Is_query, message];
	}

	getParentTagLists(id) {
		this.sprintService.ParentTagLists(id).then(
			(parentTags) => {
				this.sprintParentTagList = parentTags;
			},
			(err) => {
				this.toastr.error(err.message);
			}
		);
	}

	tagOptionSaved(option) {
		this.model.opts = option;
	}
}
