import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sprints',
  templateUrl: './sprints.component.html',
  styleUrls: ['./sprints.component.css']
})
export class SprintsformComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
