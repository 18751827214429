import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AmplifyAngularModule, AmplifyService } from "aws-amplify-angular";
import { NgxSelectModule } from "ngx-select-ex";
import { NgxUploaderModule } from "ngx-uploader";
import { AccountsComponent } from "./accounts/accounts.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthGuard } from "./auth/auth.guard";
import { AuthService } from "./auth/auth.service";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { SurveysModule } from "./modules/surveys/surveys.module";
import { AccountService } from "./services/accountService";
import { GoogleService } from "./services/google.services";
import { JQ_TOKEN } from "./services/jQuery.service";
import { MessagingSevice } from "./services/messagingService";
import { PaymentRequestService } from "./services/paymentRequestService";
import { ProjectService } from "./services/projectService";
import { Toastr, TOASTR_TOKEN } from "./services/toastr.service";
import { WorkerService } from "./services/workerService";
import { SignupComponent } from "./signup/signup.component";
import { UsersComponent } from "./users/users.component";
// import { PaymentRequestsComponent } from './payment/paymentrequests/paymentrequests.component';
import { CsvModule } from "@ctrl/ngx-csv";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { SubmissionService } from "./services/submissionService";
import { TransactionService } from "./services/transactionService";
import { TransactionsComponent } from "./Transactions/transactions.component";
// import { SprintComponent } from './sprint/sprint.component';
// import { SubmissionsComponent } from './submissions/submissions.component';
// import { RewardsComponent } from './reward/rewards/rewards.component';
// import { TasksComponent } from './tasks/tasks.component';
// import { SprinteditComponent } from './sprintedit/sprintedit.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { Ng5SliderModule } from "ng5-slider";
import { QuillModule } from "ngx-quill";
// import { TaskEditComponent } from './task-edit/task-edit.component';
// import { SprintsComponent } from './sprints/sprints.component';
// import { TaskFormComponent } from './task-form/task-form.component';
// import { QuestionComponent } from './components/question/question.component';
import { ModalTriggerDirective } from "./services/modalTrigger.directive";
import { SimpleModalComponent } from "./services/simpleModal.component";
// import { SubmissionComponent } from './submission/submission.component';
// import { WorkerComponent } from './workers/worker/worker.component';
import { AgmCoreModule } from "@agm/core";
import { ToastrModule } from "ngx-toastr";
import { AgentsComponent } from "./agents/agents.component";
import { AreaComponent } from "./area/area.component";
import { AreasComponent } from "./areas/areas.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ProfileComponent } from "./profile/profile.component";
import { FileUploadComponent } from "./services/FileUploadComponent";
import { SettingsComponent } from "./settings/settings.component";
// import { WorkersListComponent } from './workers/workers-list/workerslist.component';
import { DatePipe } from "@angular/common";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { AgWordCloudModule } from "angular4-word-cloud";
import { NgxSortableModule } from "ngx-sortable";
import { AgentComponent } from "./agent/agent.component";
import { ReportsadminComponent } from "./reportsadmin/reportsadmin.component";
import { TomtomComponent } from "./tomtom/tomtom.component";
import { SprintsformModule } from "./modules/sprints/sprints.module";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { TagCloudModule } from "angular-tag-cloud-module";
import { TreeModule } from "angular-tree-component";
import { DemographicsComponent } from "./demographics/demographics.component";
import { InsightsComponent } from "./insights/insights.component";
import { LoaderComponent } from "./loader/loader.component";
import { PaymentModule } from "./modules/payment/payment.module";
import { WorkersModule } from "./modules/workers/workers.module";
import { TagcloudComponent } from "./tagcloud/tagcloud.component";
import { TagsComponent } from "./tags/tags.component";
import { PaymentsModule } from "./modules/payments/payments.module";

let toastr: Toastr = window["toastr"];

let jQuery = window["$"];

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}
@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		SignupComponent,
		HomeComponent,
		UsersComponent,
		AccountsComponent,
		// SafePipe ,
		// PaymentRequestsComponent,
		TransactionsComponent,

		//  SubmissionsRequestComponent,
		// RewardsComponent,
		// TaskformsComponent,
		// TasksComponent,

		// TaskEditComponent,

		// TaskFormComponent,
		// QuestionComponent,
		ModalTriggerDirective,
		SimpleModalComponent,
		// SubmissionComponent,
		// ReportComponent,
		// WorkerComponent,
		FileUploadComponent,
		AreaComponent,
		AreasComponent,
		DashboardComponent,
		ProfileComponent,
		SettingsComponent,
		AgentsComponent,
		// WorkersListComponent,
		// ReportsComponent,
		ReportsadminComponent,
		AgentComponent,
		LoaderComponent,
		TomtomComponent,
		// SearchPipe,
		// SortbyPipe,
		TagsComponent,
		TagcloudComponent,
		InsightsComponent,
		DemographicsComponent,
	],
	imports: [
		NgxDatatableModule,
		TagCloudModule,
		AgWordCloudModule.forRoot(),
		BrowserModule,
		AmplifyAngularModule,
		HttpClientModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		CsvModule,
		NgxSelectModule,
		NgxUploaderModule,
		OwlDateTimeModule,
		NgxSortableModule,
		OwlNativeDateTimeModule,
		BrowserAnimationsModule,
		QuillModule,
		LoadingBarHttpClientModule,
		LoadingBarRouterModule,
		Ng5SliderModule,
		SprintsformModule,
		WorkersModule,
		SurveysModule,
		PaymentModule,
		PaymentsModule,
		//TagCloudModule,
		TreeModule.forRoot(),
		NgbModule.forRoot(),
		ToastrModule.forRoot(), // ToastrModule added
		AgmCoreModule.forRoot({
			apiKey: "AIzaSyDa1UayxKz_79DUT2nmE2wHAuBwGdZVL3Y", // Muhimma Admin Account
			// apiKey: "AIzaSyDP8ctBV6hZ-3zSmr7enXzQTHn5jFd_guc",
			libraries: ["drawing"],
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	providers: [
		AmplifyService,
		AuthService,
		AuthGuard,
		WorkerService,
		AccountService,
		ProjectService,
		PaymentRequestService,
		SubmissionService,
		DatePipe,
		TransactionService,
		MessagingSevice,
		GoogleService,
		{ provide: TOASTR_TOKEN, useValue: toastr },
		{ provide: JQ_TOKEN, useValue: jQuery },
	],

	bootstrap: [AppComponent],
	exports: [],
})
export class AppModule {}
