import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { User } from "../model/user";
import { AgentService } from "./../services/agent.service";
import { UserService } from "./../services/user.service";
@Component({
	selector: "app-agents",
	templateUrl: "./agents.component.html",
	styleUrls: ["./agents.component.css"],
})
export class AgentsComponent implements OnInit {
	loadingIndicator = true;
	message: any = "";
	model: any = {
		id: 0,
		first_name: "",
		last_name: "",
		country_id: 206,
		phone_number: "+9715000000",
		created_at: new Date(),
		updated_at: new Date(),
		is_deleted: false,
	};
	rows: any[];
	users: User[];
	id: number = 0;
	country: number = 0;
	newPassword = "";
	emailPassword = "";
	valid: boolean = false;
	constructor(
		private agentService: AgentService,
		private toastr: ToastrService,
		private userService: UserService
	) {
		this.model = this.GetUser(0);
	}

	ngOnInit() {
		this.GetAgents();
	}
	GetAgents() {
		this.loadingIndicator = true;
		this.agentService
			.getAgents()
			.then((data) => {
				this.rows = data;
				console.log(this.rows);

				this.users = data;
				this.loadingIndicator = false;
			})
			.catch((e) => console.log("Could Not Retrive Agents!"));
	}

	filter() {
		this.rows =
			this.country == 0
				? this.users
				: this.users.filter((s) => s.country_id == this.country);
	}
	toggleUser(email, condition, id) {
		this.userService
			.toggleUser(email, condition, id)
			.then((data) => {
				this.toastr.success(
					`User was ${
						condition === 1 ? "blocked" : "unblocked"
					} successfully!`
				);
				//this.users.filter(u=>u.email === email).is_blocked = condition ===1;
				this.GetAgents();
			})
			.catch((e) => console.log("Operation was not completed"));
	}
	checkPassword() {
		//this.emailPassword.match(/[A-Z]/g)
		if (
			this.newPassword.match(/[a-z]/g) &&
			this.newPassword.match(/[A-Z]/g) &&
			this.newPassword.match(/[0-9]/g) &&
			this.newPassword.length >= 6
		)
			return true;
		else return false;
	}
	changePassword() {
		let dismiss = document.getElementById("dissModelCP");
		if (
			this.newPassword === null ||
			this.newPassword === "" ||
			this.newPassword === "" ||
			!this.checkPassword()
		) {
			this.toastr.error(
				"Password must be at least 6 characters wih 1 digit and 1 upper case"
			);
			dismiss.removeAttribute("data-dismiss");
			this.valid = false;
			return;
		}

		// let dismiss = document.getElementById("changePasswords");
		dismiss.setAttribute("data-dismiss", "modal");
		this.userService
			.resetPassword(this.emailPassword, this.newPassword, false)
			.then((data) => {
				this.toastr.success(`Password was changed successfully!`);
			})
			.catch((e) => console.log("Operation was not completed"));
	}
	setEmailPassword(email) {
		this.emailPassword = email;
		this.newPassword = "";
	}
	GetUser(id) {
		if (id === 0) {
			this.model = {
				id: 0,
				first_name: "",
				last_name: "",
				email: "",
				country_id: null,
				phone_number: "",
				created_at: new Date(),
				updated_at: new Date(),
				is_deleted: false,
			};
		} else {
			this.model = this.rows.find((r) => r.id === id);
		}
	}

	postForm() {
		this.agentService
			.saveAgent(this.model)
			.then((data) => {
				this.model.id = data["id"];

				this.toastr.success("Saved Successfully!");
			})
			.catch((err) => {
				console.error("Error in saving reward");
				console.error(err);
			});
	}
}
