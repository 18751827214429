import { HttpClient } from "@angular/common/http";
import {
	Component,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { PaymentService } from "../../services/payment.service";

@Component({
	selector: "app-payments",
	templateUrl: "./payments.component.html",
	styleUrls: ["./payments.component.css"],
})
export class PaymentsComponent implements OnInit {
	difference: number = 90;
	from: Date = new Date(Date.now() - this.difference * 24 * 60 * 60 * 1000);
	to: Date = new Date();
	maxDate: Date = new Date();
	data: any = null;
	headers = [];
	isDisabled: boolean = false;
	limit: number = 200;
	offset: number = 0;
	size: number = 0;
	range: number = 9;
	limits: number[] = [];
	csvData: any = [];
	searchQuery: string = "";

	constructor(
		private paymentService: PaymentService,
		private toastr: ToastrService,
		private http: HttpClient
	) {}

	@ViewChild("outlet", { read: ViewContainerRef })
	outletRef: ViewContainerRef;
	@ViewChild("content", { read: TemplateRef }) contentRef: TemplateRef<any>;

	ngOnInit() {
		this.limits = this.generateNnumbersOfArrayWithDefineMultiple(5, 200);
		this.getAllData();
	}

	search(searchValue: string) {
		this.searchQuery = searchValue;
		this.offset = 1;
		this.getAllData();
	}

	rerender() {
		this.outletRef.clear();
		this.outletRef.createEmbeddedView(this.contentRef);
	}

	getAllData() {
		let page = Math.floor(this.offset / this.limit);
		page = page + 1;
		console.log({
			limit: this.limit,
			page,
			searchQuery: this.searchQuery.trim(),
			from: this.dateFormatter(this.from),
			to: this.dateFormatter(this.to),
		});
		// this.paymentService
		// 	.getAllUsersAndSprintsData(
		// 		encodeURIComponent(this.searchQuery.trim()),
		// 		this.limit,
		// 		page,
		// 		this.dateFormatter(this.from),
		// 		this.dateFormatter(this.to)
		// 	)
		// 	.then((res) => {
		// 		if (Object.keys(res).length > 0) {
		// 			this.setInitialData(res);
		// 			this.toastr.success(`Data fetched successfully!`);
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		this.toastr.error(`Error while making api call: ${error}`);
		// 	});

		this.http.get("assets/payments.json").subscribe((res) => {
			this.setInitialData(res);
		});
	}

	setInitialData(res) {
		this.data = res;
		this.headers = Object.keys(this.data[0]);
		this.data = this.data.slice(0, 500);
		this.size = this.data.length;

		if (this.data !== null) {
			this.csvData = [];
			this.csvData.push(this.headers);
			this.data.map((d) => {
				this.csvData.push(Object.values(d));
			});
		}

		this.rerender();
	}

	answersJoinOrNot(question, type) {
		if (question.length > 0) {
			return question.map((ques) => ques[type]).join(", ");
		} else {
			return question[type];
		}
	}

	onPageChange(offset: number) {
		this.offset = offset;
		this.getAllData();
	}

	filter() {
		this.offset = 1;
		this.getAllData();
	}

	handleRecordsPerPage(limit) {
		this.offset = 1;
		this.limit = limit;
		this.getAllData();
	}

	generateNnumbersOfArrayWithDefineMultiple(range, multiple) {
		return Array.from(Array(range).keys()).map((item) => {
			item = item + 1;
			return multiple * item;
		});
	}

	onDateChange(event, startOrEnd) {
		function datediff(first, second) {
			return Math.round((second - first) / (1000 * 60 * 60 * 24));
		}

		function parseDate(str) {
			let date = str.split("/");
			return new Date(date[2], date[0] - 1, date[1]);
		}

		if (startOrEnd === "start") {
			this.from = event;
		} else {
			this.to = event;
		}

		const difference = datediff(
			parseDate(this.from.toLocaleDateString()),
			parseDate(this.to.toLocaleDateString())
		);

		// if (difference > this.difference) {
		// 	this.isDisabled = true;
		// 	this.toastr.warning(
		// 		`You select ${difference} days please select date range between or exact ${this.difference} days!`
		// 	);
		// }
		if (new Date(this.from) > new Date(this.to)) {
			this.isDisabled = true;
			this.toastr.warning(`End date must be greater than start date!`);
		} else {
			this.isDisabled = false;
		}
	}

	dateFormatter(date: Date) {
		if (date) {
			return date
				.toISOString()
				.split("T")
				.map((d, i) => {
					return i === date.toISOString().split("T").length - 1
						? d.slice(0, -1)
						: d;
				})
				.join(" ");
		}
	}
}
