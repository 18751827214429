import { BulkEvaluation } from './../model/PaymentRequestForm';
import { PaymentRequestItem } from './../model/PaymentRequestItem';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PagedData } from '../model/paged-data';
import { Page } from '../model/page';
import Amplify, { API, Auth } from 'aws-amplify';
import aws_exports from './../../aws-exports';
import { AuthService } from '../auth/auth.service';

/**
 * A server used to mock a paged data result from a server
 */
@Injectable()
export class PaymentRequestService {
    constructor(private auth: AuthService) {

    }
    /**
     * A method that mocks a paged server response
     * @param page The selected page
     * @returns {any} An observable containing the employee data
     */
    public getResults(page: Page, search?: any): Observable<PagedData<PaymentRequestItem>> {
        return of(this.getPagedData(page, search));
    }


    public getResult(page: Page, email: string, status: number, from: Date, to: Date): Promise<any> {
      const pagedData = new PagedData<PaymentRequestItem>();
      const apiName = 'api_node_1';
      const s = status ? '&status=' + status : '';
      if(page.size === null || page.size === undefined) page.size = 100;
      const path = `paymentrequests?email=${email}${s}&limit=${page.size}&offset=${page.pageNumber * page.size}`;
      const myInit = {
            headers: {},
            response: true,
            queryStringParameters: {}
          };
      const promise = new Promise((resolve, reject) => {
          API.get(apiName, path, myInit)
          .then(response => {
            const data = response.data;
            page.totalElements = data.count;
            page.totalPages = page.totalElements / page.size;
            const start = page.pageNumber * page.size;
            const end = Math.min((start + page.size), page.totalElements);
            pagedData.page = page;
            pagedData.data = data.data;
            resolve(pagedData);
          })
          .catch(error => {
            console.log('Error in invoiking API:' + error);
            reject(error);
          });
      }); // end promoise
      return promise;
    } // end getresult

    public savePaymentRequestForm(form: BulkEvaluation) {
      const apiName = 'api_node_1';
      const path = `paymentrequests`;
      const myInit = {
        body: form,
        headers: {}
    };
      const promise = new Promise((resolve, reject) => {
        API.put(apiName, path, myInit)
          .then(response => {
            const data = response.data;
            resolve('OK');
          })
          .catch(error => {
            console.log(error.response);
            reject(error);
          });
      }); // end promoise
      return promise;
    }

    /**
     * Package PaymentRequestItem into a PagedData object based on the selected Page
     * @param page The page data used to get the selected data from PaymentRequestItem
     * @returns {PagedData<PaymentRequestItem>} An array of the selected data and page
     */
    private getPagedData(page: Page, search): PagedData<PaymentRequestItem> {
      const pagedData = new PagedData<PaymentRequestItem>();
      const apiName = 'api_node_1';
      const path = `paymentrequests?email=${search}&limit=${page.size}&offsert=${page.pageNumber * page.size}`;
      const myInit = {
            headers: {},
            response: true,
            queryStringParameters: {}
          };
      API.get(apiName, path, myInit)
      .then(response => {
        const data = response.data;
        page.totalElements = data.count;
        page.totalPages = page.totalElements / page.size;
        const start = page.pageNumber * page.size;
        const end = Math.min((start + page.size), page.totalElements);
        pagedData.data = data.data;
      })
      .catch(error => {
        console.log('Error in invoiking API:' + error);
      });
        pagedData.page = page;
        return pagedData;
    }

}
