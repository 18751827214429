import { AgmCoreModule } from "@agm/core";
import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CsvModule } from "@ctrl/ngx-csv";
import { TranslateModule } from "@ngx-translate/core";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AmplifyAngularModule, AmplifyService } from "aws-amplify-angular";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { Ng5SliderModule } from "ng5-slider";
import { QuillModule } from "ngx-quill";
import { NgxSelectModule } from "ngx-select-ex";
import { NgxSortableModule } from "ngx-sortable";
import { NgxUploaderModule } from "ngx-uploader";
import { SearchPipe } from "../../search.pipe";
import { SortbyPipe } from "../../sortby.pipe";
import { WorkerComponent } from "./components/worker/worker.component";
import { WorkersListComponent } from "./components/workers-list/workerslist.component";
import { WorkersRoutingModule } from "./workers-routing.module";

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		NgxDatatableModule,
		NgxSelectModule,
		NgxUploaderModule,
		CsvModule,
		QuillModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		Ng5SliderModule,
		AmplifyAngularModule,
		TranslateModule,
		NgxSortableModule,
		AgmCoreModule.forRoot({
			apiKey: "AIzaSyDa1UayxKz_79DUT2nmE2wHAuBwGdZVL3Y", // Muhimma Admin Account
			// apiKey: "AIzaSyDP8ctBV6hZ-3zSmr7enXzQTHn5jFd_guc",
			libraries: ["drawing"],
		}),
		WorkersRoutingModule,
	],
	declarations: [
		WorkersListComponent,
		WorkerComponent,
		SearchPipe,
		SortbyPipe,
	],
	providers: [
		AmplifyService,
		// AuthService,
		// AuthGuard,
		// WorkerService,
		// AccountService,
		// ProjectService,
		// PaymentRequestService,
		// SubmissionService,
		DatePipe,
		// TransactionService, MessagingSevice, GoogleService,
		// { provide: TOASTR_TOKEN, useValue: toastr },
		// { provide: JQ_TOKEN, useValue: jQuery }
	],
	exports: [WorkersListComponent, WorkerComponent, SearchPipe, SortbyPipe],
})
export class WorkersModule {}
