import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import API from "@aws-amplify/api";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { bool } from "aws-sdk/clients/signer";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { User } from "../model/user";

@Injectable({
	providedIn: "root",
})
export class UserService {
	User: User = null;
	constructor(
		private auth: AuthService,
		public loader: LoadingBarService,
		public toastr: ToastrService,
		private httpClient: HttpClient
	) {}
	public getUser(email): Promise<any> {
		const apiName = "api_node_2";
		const path = `user?email=${email}`;
		const myInit = {
			headers: {}, // OPTIONAL
			response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
			queryStringParameters: {},
		};
		this.loader.start();
		const promise = new Promise((resolve, reject) => {
			API.get(apiName, path, myInit)
				.then((response) => {
					this.loader.complete();
					const data = response.data;
					try {
						this.User = data.db[0] as User;
						localStorage.setItem("user", JSON.stringify(this.User));
						this.loader.complete();
					} catch (x) {
						console.log("error in retrieving agent" + x);
						this.loader.complete();
					}

					resolve(this.User);
				})
				.catch((error) => {
					this.loader.start();

					console.log("Error in invoiking API:" + error);
					reject(error);
				});
		}); // end promoise
		return promise;
	} // end getUser

	public resetPassword(
		email: string,
		newpassword: string,
		forWorker: bool
	): Promise<any> {
		const apiName = "api_node_2";
		const path = `user/reset`;
		const myInit = {
			body: { email: email, password: newpassword, worker: forWorker },
			headers: {},
		};
		const promise = new Promise((resolve, reject) => {
			API.post(apiName, path, myInit)
				.then((response) => {
					const data = response;
					if (data.response_code == 200) {
						resolve(data);
					} else {
						return this.toastr.error(data.message);
					}
				})
				.catch((error) => {
					this.toastr.error(error);
					reject(error);
				});
		}); // end promoise
		return promise;
	}

	public toggleUser(
		email: string,
		condition: number,
		id: number
	): Promise<any> {
		const apiName = "api_node_2";
		const path = `user/toggle`;
		const myInit = {
			body: {
				email: email,
				condition: condition,
				id: id,
			},
			headers: {},
		};
		const promise = new Promise((resolve, reject) => {
			API.post(apiName, path, myInit)
				.then((response) => {
					const data = response;
					if (data.response_code == 200) {
						resolve(data.data);
					} else {
						return this.toastr.error(data.message);
					}
				})
				.catch((error) => {
					this.toastr.error(error);
					reject(error);
				});
		}); // end promoise
		return promise;
	}

	public deleteUser(id: number): Promise<any> {
		const apiName = "api_node_2";
		const path = `worker`;
		const myInit = {
			body: {
				id: id,
				reason_id: 5,
				is_comment: false,
				comment: "",
			},
			headers: {},
		};
		const promise = new Promise((resolve, reject) => {
			API.del(apiName, path, myInit)
				.then((response) => {
					const data = response;
					if (data.response_code == 200) {
						resolve(data.data);
					} else {
						return this.toastr.error(data.message);
					}
				})
				.catch((error) => {
					this.toastr.error(error);
					reject(error);
				});
		});
		return promise;
	}
	public recoverUser(
		id: number,
		email: string,
		phone_number: string
	): Promise<any> {
		const apiName = "api_node_2";
		// const path = `worker?id=${id}`;
		const path = `worker`;
		const myInit = {
			body: {
				id,
				email,
				phone_number,
			},
			headers: {},
		};
		const promise = new Promise((resolve, reject) => {
			API.put(apiName, path, myInit)
				.then((response) => {
					const data = response;
					if (data.response_code == 200) {
						resolve(data.data);
					} else {
						return this.toastr.error(data.message);
					}
				})
				.catch((error) => {
					this.toastr.error(error);
					reject(error);
				});
		});
		return promise;
	}

	// public deleteUser(
	// 	email: string,
	// 	forWorker: bool,
	// 	condition: number
	// ): Promise<any> {
	// 	const apiName = "api";
	// 	const path = `user/toggle`;
	// 	const myInit = {
	// 		body: { email: email, worker: forWorker, condition: condition },
	// 		headers: {},
	// 	};
	// 	const promise = new Promise((resolve, reject) => {
	// 		API.post(apiName, path, myInit)
	// 			.then((response) => {
	// 				const data = response;
	// 				resolve(data);
	// 			})
	// 			.catch((error) => {
	// 				console.log(error.response);
	// 				reject(error);
	// 			});
	// 	}); // end promoise
	// 	return promise;
	// }

	public toggleWorker(email: string, condition: number): Promise<any> {
		const apiName = "api_node_2";
		const path = `worker/toggle`;
		const myInit = {
			body: { email: email, condition: condition },
			headers: {},
		};
		const promise = new Promise((resolve, reject) => {
			API.post(apiName, path, myInit)
				.then((response) => {
					const data = response;
					resolve(data);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				});
		}); // end promoise
		return promise;
	}

	uploadCsv(csv) {
		// const httpOptions = {
		// 	headers: new HttpHeaders({
		// 	 "Content-Type": "multipart/form-data",
		// 	 'Authorization': 'bNLvlGInFS2Md39PyeiO8amVUhj5Wg'
		// 	})
		//   };
		const headers = new HttpHeaders({
			"Content-Type": "multipart/form-data",
			Authorization: "bNLvlGInFS2Md39PyeiO8amVUhj5Wg",
		});
		// headers.set("Accept", "application/json");
		const apiName = environment.api_node_2;
		const path = `worker/bulk-upload`;
		return this.httpClient.post(apiName + path, csv, { headers });
	}
}
