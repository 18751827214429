import { Report } from "./Report";

// To parse this data:
//
//   import { Convert, Sprint } from "./file";
//
//   const sprint = Convert.toSprint(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

// To parse this data:
//
//   import { Convert } from "./file";
//
//   const sprint = Convert.toSprint(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface Sprint {
  id:                              number;
  tag?:                             string;
  name:                            string;
  forms:                           Form[] | null;
  rules?:                           any[] | null;
  tasks?:                           Task[] | null;
  namear?:                          string;
  status?:                         number;
  area_id?:                         number | null;
  iconurl?:                         string;
  backgroundurl?:                   string;
  dashboardurl?:                   string;
  files:                           File[];
  reports?:                        Report[];
  rewards?:                        Reward[];
  is_pilot?:                        boolean;
  priority?:                        number;
  account_id?:                     number;
  country_id?:                      number;
  created_at?:                      Date;
  extra_text?:                      string;
  is_deleted?:                      boolean|null;
  started_at?:                      Date;
  updated_at?:                      Date;
  description?:                     string;
  finished_at?:                      Date | null;
  schedulings?:                     Scheduling[]  | null;
  system_name?:                     string;
  total_spent?:                     number;
  is_community?:                    boolean;
  project_type?:                    number;
  customer_name?:                   string;
  descriptionar?:                   string;
  account_deal_id?:                 number | null;
  latest_activity?:                 Date;
  project_group_id?:                string;
  short_description?:               string;
  allowed_worker_ids?:              any[] | null;
  is_auto_acceptable?:              boolean;
  max_submission_count?:            number;
  short_description_ar?:            string;
  disallowed_worker_ids?:           any[] | null;
  active_submissions_count?:        number;
  default_task_description?:        string;
  system_short_description?:        string;
  customer_short_description?:      string;
  max_submission_count_per_worker?: number;
  rejected?: number;
  accepted?: number;
  submitted?: number;

}

export interface Form {
  id:                number;
  tasks?:             Task[] | null;
  title:             string;
  titlear:           string;
  questions?:         Question[] | null;
  created_at:        Date;
  is_deleted?:        boolean | null;
  is_visible?:        boolean;
  project_id:        number;
  updated_at?:        Date;
  is_scorable?:       boolean;
  complete_duration?: number;
}
export interface Targeting {
  id:                number;
  os:             number;
  gender:             number;
  min:             number;
  max:             number;

  created_at:        Date;
  project_id:        number;
  updated_at?:        Date;

}
export interface Question {
  id:                number;
  tags?:               number[];
  opts?:              Opt[] | null;
  shortlabel?:             string;
  label?:             string;
  labelar?:           string;
  position?:          number;
  is_random?:         boolean;
  is_drilldown?:      boolean;
  created_at?:        Date;
  is_deleted?:        boolean | null;
  is_required?:        boolean | null;
  updated_at?:        Date;
  constraints?:       Constraints | null;
  description?:       string;
  tag?:       string;
  iconurl?:                         string;
  backroundFile?:                   string;
  question_image?:                   string;
  task_form_id?:      number;
  answer_points?:     number;
  question_type?:     number;
  is_visible_client?: boolean;
  max_answer_points?: number;
  name: string;
  files: File[];
}

export interface Constraints {
  limit:       Limit;
  is_required: boolean;
}

export interface Limit {
  type: Type;
  min?: number;
}

export enum Type {
  Word = "word",
  Character = "character",

}

export interface Opt {
  id:                number;
  label:             string;
  labelar:           string;
  jump_ids:          number[] | null;
  position:          number;
  created_at:        Date;
  is_deleted?:        boolean | null;
  updated_at:        Date;
  question_id:       number;
  answer_points:     number;
  optional_label:    string;
  has_extra_payload: boolean;
  option_image: string;
}

export interface Task {
  id:                              number;
  title:                           string;
  status?:                          number;
  address?:                         string;
  area_id?:                         number;
  titlear?:                         string;
  location?:                        string;
  priority?:                        number;
  reward_id?:                       number;
  created_at?:                      Date;
  is_deleted?:                      boolean|null;
  updated_at?:                      Date;
  custom_info?:                     string;
  description?:                     string;
  claim_radius?:                    number;
  task_form_id?:                    number;
  descriptionar?:                   string;
  related_task_ids?:                any[];
  short_description?:               string;
  submission_radius?:               number;
  allowed_worker_ids?:              number[];
  max_submission_count?:            number;
  short_description_ar?:            string;
  disallowed_worker_ids?:           any[];
  nationalities?:                   any[];
    hard_prerequisite_ids?:           number[];
  soft_prerequisite_ids?:           any[];
  active_submissions_count?:        number;
  max_submission_count_per_worker?: number;
  amount?:                         number;
  currency_id?:                    number;
  os:                               number;
  gender:                           number;
  min_age:                              number;
  max_age:                              number;
}

export interface Reward {
  id:          number;
  cost?:        number | null;
  title:       string;
  amount?:      number;
  is_shown?:    boolean;
  created_at?:  Date;
  is_deleted?:  boolean|null;
  project_id:  number;
  updated_at?:  Date;
  currency_id: number;
}
export interface File {
  id:              number;
  url:             string;
  created_at:      Date;
  is_deleted:      boolean|null;
  updated_at:      Date;
  upload_type:     number;
  uploadable_id:   number;
  uploadable_type: string;
}
export interface Scheduling {
  id:                     number;
  starts_at:              Date;
  created_at:             Date;
  project_id?:             number;
  updated_at:             Date;
  finishes_at:            Date;
  available_to?:           number;
  available_from?:         number;
  available_on_friday?:    boolean;
  available_on_monday?:    boolean;
  available_on_sunday?:    boolean;
  available_on_tuesday?:   boolean;
  available_on_saturday?:  boolean;
  available_on_thursday?:  boolean;
  available_on_wednesday?: boolean;
}
// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export namespace Convert {
  export function toSprint(json: string): Sprint {
      return cast(JSON.parse(json), r("Sprint"));
  }

  export function sprintToJson(value: Sprint): string {
      return JSON.stringify(uncast(value, r("Sprint")), null, 2);
  }

  function invalidValue(typ: any, val: any): never {
      throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`);
  }

  function jsonToJSProps(typ: any): any {
      if (typ.jsonToJS === undefined) {
          var map: any = {};
          typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
          typ.jsonToJS = map;
      }
      return typ.jsonToJS;
  }

  function jsToJSONProps(typ: any): any {
      if (typ.jsToJSON === undefined) {
          var map: any = {};
          typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
          typ.jsToJSON = map;
      }
      return typ.jsToJSON;
  }

  function transform(val: any, typ: any, getProps: any): any {
      function transformPrimitive(typ: string, val: any): any {
          if(val === null) return val;
          if(val === undefined) return typ;
          if (typeof typ === typeof val) return val;
          return invalidValue(typ, val);
      }

      function transformUnion(typs: any[], val: any): any {
          // val must validate against one typ in typs
          var l = typs.length;
          for (var i = 0; i < l; i++) {
              var typ = typs[i];
              try {
                  return transform(val, typ, getProps);
              } catch (_) {}
          }
          return invalidValue(typs, val);
      }

      function transformEnum(cases: string[], val: any): any {
          if (cases.indexOf(val) !== -1) return val;
          return invalidValue(cases, val);
      }

      function transformArray(typ: any, val: any): any {
          // val must be an array with no invalid elements
          if (!Array.isArray(val)) val = [];
          return val.map(el => transform(el, typ, getProps));
      }

      function transformDate(typ: any, val: any): any {
          if (val === null) {
              return null;
          }
          const d = new Date(val);
          if (isNaN(d.valueOf())) {
              return invalidValue("Date", val);
          }
          return d;
      }

      function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
          if (val === null || typeof val !== "object" || Array.isArray(val)) {
              return invalidValue("object", val);
          }
          var result: any = {};
          Object.getOwnPropertyNames(props).forEach(key => {
              const prop = props[key];
              const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
              result[prop.key] = transform(v, prop.typ, getProps);
          });
          Object.getOwnPropertyNames(val).forEach(key => {
              if (!Object.prototype.hasOwnProperty.call(props, key)) {
                  result[key] = transform(val[key], additional, getProps);
              }
          });
          return result;
      }

      if (typ === "any") return val;
      if (typ === null) {
          if (val === null || val === true) return val;
          return invalidValue(typ, val);
      }
      if (typ === false) {
        return val;

      //  return invalidValue(typ, val);
      }
      while (typeof typ === "object" && typ.ref !== undefined) {
          typ = typeMap[typ.ref];
      }
      if (Array.isArray(typ)) return transformEnum(typ, val);
      if (typeof typ === "object") {
          return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
              : typ.hasOwnProperty("arrayItems")    ? transformArray(typ.arrayItems, val)
              : typ.hasOwnProperty("props")         ? transformObject(getProps(typ), typ.additional, val)
              : invalidValue(typ, val);
      }
      // Numbers can be parsed by Date but shouldn't be.
      if (typ === Date && typeof val !== "number") return transformDate(typ, val);
      return transformPrimitive(typ, val);
  }

  function cast<T>(val: any, typ: any): T {
      return transform(val, typ, jsonToJSProps);
  }

  function uncast<T>(val: T, typ: any): any {
      return transform(val, typ, jsToJSONProps);
  }

  function a(typ: any) {
      return { arrayItems: typ };
  }

  function u(...typs: any[]) {
      return { unionMembers: typs };
  }

  function o(props: any[], additional: any) {
      return { props, additional };
  }

  function m(additional: any) {
      return { props: [], additional };
  }

  function r(name: string) {
      return { ref: name };
  }

  const typeMap: any = {
    "Sprint": o([
        { json: "id", js: "id", typ: 0 },
        { json: "tag", js: "tag", typ: "" },
        { json: "name", js: "name", typ: "" },
        { json: "forms", js: "forms", typ: a(r("Form")) },
        { json: "rules", js: "rules", typ: null },
        { json: "tasks", js: "tasks", typ: a(r("Task")) },
        { json: "namear", js: "namear", typ: "" },
        { json: "status", js: "status", typ: 0 },
        { json: "area_id", js: "area_id", typ: 0 },
        { json: "iconurl", js: "iconurl", typ: "" },
        { json: "backgroundurl", js: "backgroundurl", typ: "" },
        { json: "dashboardurl", js: "dashboardurl", typ: "" },
        { json: "rewards", js: "rewards", typ: a(r("Reward")) },
        { json: "is_pilot", js: "is_pilot", typ: true },
        { json: "priority", js: "priority", typ: 0 },
        { json: "account_id", js: "account_id", typ: 0 },
        { json: "country_id", js: "country_id", typ: 0 },
        { json: "created_at", js: "created_at", typ: Date },
        { json: "extra_text", js: "extra_text", typ: "" },
        { json: "is_deleted", js: "is_deleted", typ:  u(true, null)  },
        { json: "started_at", js: "started_at", typ: Date },
        { json: "updated_at", js: "updated_at", typ: Date },
        { json: "description", js: "description", typ: "" },
        { json: "finished_at", js: "finished_at", typ: u(Date, null) },
        { json: "schedulings", js: "schedulings", typ: a(r("Scheduling")) },
        { json: "system_name", js: "system_name", typ: "" },
        { json: "total_spent", js: "total_spent", typ: 0 },
        { json: "is_community", js: "is_community", typ: true },
        { json: "project_type", js: "project_type", typ: 0 },
        { json: "customer_name", js: "customer_name", typ: "" },
        { json: "descriptionar", js: "descriptionar", typ: "" },
        { json: "account_deal_id", js: "account_deal_id", typ: null },
        { json: "latest_activity", js: "latest_activity", typ: Date },
        { json: "project_group_id", js: "project_group_id", typ: null },
        { json: "short_description", js: "short_description", typ: "" },
        { json: "allowed_worker_ids", js: "allowed_worker_ids", typ: a("any") },
        { json: "is_auto_acceptable", js: "is_auto_acceptable", typ: true },
        { json: "max_submission_count", js: "max_submission_count", typ: 0 },
        { json: "short_description_ar", js: "short_description_ar", typ: "" },
        { json: "disallowed_worker_ids", js: "disallowed_worker_ids", typ: a("any") },
        { json: "active_submissions_count", js: "active_submissions_count", typ: 0 },
        { json: "default_task_description", js: "default_task_description", typ: "" },
        { json: "system_short_description", js: "system_short_description", typ: "" },
        { json: "customer_short_description", js: "customer_short_description", typ: "" },
        { json: "max_submission_count_per_worker", js: "max_submission_count_per_worker", typ: 0 },
        { json: "rejected", js: "rejected", typ: 0 },
        { json: "accepted", js: "accepted", typ: 0 },
        { json: "submitted", js: "submitted", typ: 0 },

    ], false),
    "File": o([
      { json: "id", js: "id", typ: 0 },
      { json: "url", js: "url", typ: "" },
      { json: "created_at", js: "created_at", typ: Date },
      { json: "is_deleted", js: "is_deleted", typ: u(true, null)  },
      { json: "updated_at", js: "updated_at", typ: Date },
      { json: "upload_type", js: "upload_type", typ: 0 },
      { json: "uploadable_id", js: "uploadable_id", typ: 0 },
      { json: "uploadable_type", js: "uploadable_type", typ: "" },
  ], false),
    "Form": o([
        { json: "id", js: "id", typ: 0 },
        { json: "tasks", js: "tasks", typ: a(r("Task")) },
        { json: "title", js: "title", typ: "" },
        { json: "titlear", js: "titlear", typ: "" },
        { json: "questions", js: "questions", typ: u(a(r("Question")), null) },
        { json: "created_at", js: "created_at", typ: Date },
        { json: "is_deleted", js: "is_deleted", typ: u(true, null) },
        { json: "is_visible", js: "is_visible", typ: u(true, null) },
        { json: "project_id", js: "project_id", typ: 0 },
        { json: "updated_at", js: "updated_at", typ: Date },
        { json: "is_scorable", js: "is_scorable", typ: u(true, null) },
        { json: "complete_duration", js: "complete_duration", typ: 0 },
    ], false),
    "Question": o([
        { json: "id", js: "id", typ: 0 },
        { json: "opts", js: "opts", typ: u(a(r("Opt")), null) },
        { json: "label", js: "label", typ: "" },
        { json: "labelar", js: "labelar", typ: "" },
        { json: "position", js: "position", typ: 0 },
        { json: "is_random", js: "is_random", typ: u(true, null) },
        { json: "is_required", js: "is_required", typ: u(true, null) },

        { json: "created_at", js: "created_at", typ: Date },
        { json: "is_deleted", js: "is_deleted", typ: u(true, null) },
        { json: "updated_at", js: "updated_at", typ: Date },
        { json: "constraints", js: "constraints", typ: u(r("Constraints"), null) },
        { json: "description", js: "description", typ: "" },
        { json: "task_form_id", js: "task_form_id", typ: 0 },
        { json: "answer_points", js: "answer_points", typ: 0 },
        { json: "question_type", js: "question_type", typ: 0 },
        { json: "is_visible_client", js: "is_visible_client", typ: u(true, null) },
        { json: "max_answer_points", js: "max_answer_points", typ: 0 },
    ], false),
    "Constraints": o([
        { json: "limit", js: "limit", typ: r("Limit") },
        { json: "is_required", js: "is_required", typ: u(true, null) },
    ], false),
    "Limit": o([
        { json: "type", js: "type", typ: r("Type") },
        { json: "min", js: "min", typ: u(0, 0) },
    ], false),
    "Opt": o([
        { json: "id", js: "id", typ: 0 },
        { json: "label", js: "label", typ: "" },
        { json: "labelar", js: "labelar", typ: "" },
        { json: "jump_ids", js: "jump_ids", typ: a(0) },
        { json: "position", js: "position", typ: 0 },
        { json: "created_at", js: "created_at", typ: Date },
        { json: "is_deleted", js: "is_deleted", typ: u(true, null) },
        { json: "updated_at", js: "updated_at", typ: Date },
        { json: "question_id", js: "question_id", typ: 0 },
        { json: "answer_points", js: "answer_points", typ: 0 },
        { json: "optional_label", js: "optional_label", typ: "" },
        { json: "has_extra_payload", js: "has_extra_payload", typ: u(true, null) },
    ], false),
    "Task": o([
        { json: "id", js: "id", typ: 0 },
        { json: "title", js: "title", typ: "" },
        { json: "status", js: "status", typ: 0 },
        { json: "address", js: "address", typ: "" },
        { json: "area_id", js: "area_id", typ: 0 },
        { json: "titlear", js: "titlear", typ: "" },
        { json: "location", js: "location", typ: "" },
        { json: "priority", js: "priority", typ: 0 },
        { json: "reward_id", js: "reward_id", typ: 0 },
        { json: "created_at", js: "created_at", typ: Date },
        { json: "is_deleted", js: "is_deleted", typ: u(true, null) },
        { json: "updated_at", js: "updated_at", typ: Date },
        { json: "custom_info", js: "custom_info", typ: "" },
        { json: "description", js: "description", typ: "" },
        { json: "claim_radius", js: "claim_radius", typ: 0 },
        { json: "task_form_id", js: "task_form_id", typ: 0 },
        { json: "descriptionar", js: "descriptionar", typ: "" },
        { json: "related_task_ids", js: "related_task_ids", typ: a("any") },
        { json: "short_description", js: "short_description", typ: "" },
        { json: "submission_radius", js: "submission_radius", typ: 0 },
        { json: "allowed_worker_ids", js: "allowed_worker_ids", typ: a(0) },
        { json: "max_submission_count", js: "max_submission_count", typ: 0 },
        { json: "short_description_ar", js: "short_description_ar", typ: "" },
        { json: "disallowed_worker_ids", js: "disallowed_worker_ids", typ: a("any") },
         { json: "nationalities", js: "nationalities", typ: a("any") },
       { json: "hard_prerequisite_ids", js: "hard_prerequisite_ids", typ: a(0) },
        { json: "soft_prerequisite_ids", js: "soft_prerequisite_ids", typ: a("any") },
        { json: "active_submissions_count", js: "active_submissions_count", typ: 0 },
        { json: "max_submission_count_per_worker", js: "max_submission_count_per_worker", typ: 0 },
        { json: "amount", js: "amount", typ: u(0, 0) },
        { json: "gender", js: "gender", typ: u(0, 0) },
        { json: "os", js: "os", typ: u(0, 0) },
        { json: "min_age", js: "min_age", typ: u(0, 0) },
        { json: "max_age", js: "max_age", typ: u(0, 0) },

        { json: "currency_id", js: "currency_id", typ: u(0, 0) },
    ], false),
    "Reward": o([
        { json: "id", js: "id", typ: 0 },
        { json: "cost", js: "cost", typ: u(0, null) },
        { json: "title", js: "title", typ: "" },
        { json: "amount", js: "amount", typ: 0 },
        { json: "is_shown", js: "is_shown", typ: true },
        { json: "created_at", js: "created_at", typ: Date },
        { json: "is_deleted", js: "is_deleted", typ: u(true, null) },
        { json: "project_id", js: "project_id", typ: 0 },
        { json: "updated_at", js: "updated_at", typ: Date },
        { json: "currency_id", js: "currency_id", typ: 0 },
    ], false),
    "Scheduling": o([
        { json: "id", js: "id", typ: 0 },
        { json: "starts_at", js: "starts_at", typ: Date },
        { json: "created_at", js: "created_at", typ: Date },
        { json: "project_id", js: "project_id", typ: 0 },
        { json: "updated_at", js: "updated_at", typ: Date },
        { json: "finishes_at", js: "finishes_at", typ: Date },
        { json: "available_to", js: "available_to", typ: 0 },
        { json: "available_from", js: "available_from", typ: 0 },
        { json: "available_on_friday", js: "available_on_friday", typ: u(true, null) },
        { json: "available_on_monday", js: "available_on_monday", typ: u(true, null) },
        { json: "available_on_sunday", js: "available_on_sunday", typ: u(true, null) },
        { json: "available_on_tuesday", js: "available_on_tuesday", typ: u(true, null) },
        { json: "available_on_saturday", js: "available_on_saturday", typ: u(true, null) },
        { json: "available_on_thursday", js: "available_on_thursday", typ: u(true, null) },
        { json: "available_on_wednesday", js: "available_on_wednesday", typ: u(true, null) },
    ], false),
    "Type": [
        "character","word"
    ],
};
}
