import { Component, OnInit, Input } from '@angular/core';
//import { CloudOptions, CloudData } from 'angular-tag-cloud-module';

@Component({
  selector: 'app-tasks',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css']
})
export class TasksComponent implements OnInit {
  @Input() rows: any[];

  constructor() { }

  ngOnInit() {
  }
  getStatus(status){
    if(status===0) return '<span class="badge badge-success">Available</span>';
    if(status===1) return '<span class="badge badge-danger">Not Available</span>';


    return '<span class="badge badge-info">-</span>';
  }
}
