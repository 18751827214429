import { FormControl, FormGroup, Validators } from "@angular/forms";
// import { File } from './../model/sprint';
// import { S3Response } from './../model/S3Response';
// import { AccountService } from './../services/accountService';
import { Component, EventEmitter, OnInit } from "@angular/core";
import { AccountService } from "src/app/services/accountService";
// import { Sprint, File as Upload } from '../model/sprint';

// import { Worker } from '../model/worker';

// import { Page } from '../model/page';
import { Page } from "src/app/modules/sprints/components/submissionFile/model/pageSubmissionModel";
// import { ProjectService } from '../services/projectService';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { Options } from "ng5-slider";
import {
	humanizeBytes,
	UploaderOptions,
	UploadFile,
	UploadInput,
	UploadOutput,
} from "ngx-uploader";
// import { base64Response } from '../model/Base64Reponse';
import { base64Response } from "src/app/model/Base64Reponse";
// import { BreadCrumb } from '../model/BreadCrumb';
import { ToastrService } from "ngx-toastr";
import { BreadCrumb } from "src/app/model/BreadCrumb";
// import { WorkerService } from '../services/workerService';
import { WorkersService } from "../../service/workersService";
import { environment } from "src/environments/environment";
// import { SortbyPipe } from '../sortby.pipe';
import { ResponseType } from "src/app/modules/workers/model/Workers";

@Component({
	selector: "app-worker",
	templateUrl: "./worker.component.html",
	styles: ["agm-map { height: 300px; /* height is required */ }"],
})
export class WorkerComponent implements OnInit {
	genders = [
		{ id: 0, name: "Male" },
		{ id: 1, name: "Female" },
	];
	selectedGender: 1;

	uploadUrl: string = "https://gateway.backendapis.net/node/file?name=";
	crumbs: BreadCrumb[];

	options: UploaderOptions;
	formData: FormData;
	files: UploadFile[];
	backroundFile: UploadFile;
	iconFile: UploadFile;
	uploadInput: EventEmitter<UploadInput>;
	humanizeBytes: Function;
	dragOver: boolean;
	submitted = false;
	schedule = false;
	availability = false;
	minValue: number = 1;
	maxValue: number = 99;
	allowed = null;
	disallowed = null;
	from = null;
	to = null;
	nationalities: any;
	sliderOptions: Options = {
		floor: 0,
		ceil: 100,
		step: 5,
	};
	accounts = [];
	countries: any[] = [
		{
			id: 206,
			name: "  United Arab Emirates",
			image: "/assets/icons/uae-round.png",
		},
		{
			id: 166,
			name: "  Saudi Arabia",
			image: "/assets/icons/ksa-round.png",
		},
		{ id: 58, name: "  Egypt", image: "/assets/icons/egypt-round.png" },
	];

	cities;
	loginForm = new FormGroup({
		ContactCode: new FormControl(null, [Validators.required]),
		ContactNumber: new FormControl(null, [Validators.required]),
	});
	maxContactLength: number;
	loading = true;
	email = null;
	id = null;
	model: any = null;
	showMap: boolean = false;
	toggleMap() {
		this.showMap = !this.showMap;
	}

	constructor(
		private accountService: AccountService,
		private workerService: WorkersService,
		private activatedRoute: ActivatedRoute,
		private http: HttpClient,
		private router: Router,
		private toastr: ToastrService
	) {
		this.filterAccounts(null);
		this.options = { concurrency: 1, maxUploads: 3 };
		this.files = []; // local uploading files array
		this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
		this.humanizeBytes = humanizeBytes;
	}

	ngOnInit() {
		this.activatedRoute.queryParams.subscribe((params) => {
			this.id = this.activatedRoute.snapshot.paramMap.get("id");
			//console.log(this.email);

			if (this.id > 0) {
				this.workerService
					.getWorker(this.email, this.id)
					.then((worker) => {
						//worker.birthdate =  new DatePipe('en-US').transform(worker.birthdate, 'MM/dd/yyyy');

						this.model = worker;

						this.buildBreadCrumb();

						this.loading = false;
					});
			} else {
				this.loading = false;
			}
		});

		this.http.get("assets/data.json").subscribe((data) => {
			this.nationalities = data;
		});
		this.http
			.get(
				`${environment.api_node_1}worker/workerid?worker_id=${this.id}`
				// `https://api.muhimmaplatform.com/node1-prod-v3/worker/workerid?worker_id=${this.id}`
			)
			.subscribe((res) => {
				this.cities = res;
				this.cities = this.cities.data.cities;
			});
	}
	onSubmit() {
		this.submitted = true;
	}
	getSubmissionStatus(status) {
		if (status === 0)
			return '<span class="badge badge-warning">Draft</span>';
		if (status === 1)
			return '<span class="badge badge-primary">Submitted</span>';
		if (status === 2)
			return '<span class="badge badge-success">Approved</span>';
		if (status === 3)
			return '<span class="badge badge-danger">Rejected</span>';

		return '<span class="badge badge-info">-</span>';
	}
	getURL(name) {
		return this.uploadUrl + this.model.name.replace(/\s/g, "") + name;
	}
	styleFunc(feature) {
		return {
			clickable: false,
			fillColor: feature.getProperty("color"),
			strokeWeight: 1,
		};
	}
	filterAccounts(search) {
		const p = new Page();
		p.pageNumber = 0;
		p.totalElements = 100;
		p.totalPages = 1;
		p.size = 100;
		this.accountService
			.getResult(p, this.email)
			.then((accounts) => (this.accounts = accounts.data));
	}
	buildBreadCrumb() {
		//Sprints? Sprint()>Task Forms ()
		this.crumbs = [];
		this.crumbs.push(new BreadCrumb("Home", "/"));

		this.crumbs.push(new BreadCrumb("Workers", "/workers"));
		this.crumbs.push(
			new BreadCrumb(
				this.model.first_name
					? this.model.first_name +
					  " " +
					  this.model.last_name +
					  "(" +
					  this.model.id +
					  ")"
					: "Worker",
				""
			)
		);
	}

	numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;

		const startPos =
			document.getElementById("phone_number")["selectionStart"];
		console.log(startPos, event.key, event.keyCode);
		if (
			this.model.phone_number.startsWith("+966") ||
			this.model.phone_number.startsWith("+971") ||
			this.model.phone_number.startsWith("+962")
		) {
			this.maxContactLength = 13;
		} else if (this.model.phone_number.startsWith("+20")) {
			this.maxContactLength = 13;
		} else if (
			this.model.phone_number.startsWith("+965") ||
			this.model.phone_number.startsWith("+968")
		) {
			this.maxContactLength = 12;
		}
		if (
			startPos === 0 &&
			(event.key === "+" ||
				event.key === "ArrowRight" ||
				event.key === "Backspace")
		) {
			return true;
		} else if (
			(startPos === 0 && event.key !== "+") ||
			((startPos === 3 || startPos === 4) && event.key === "0") ||
			// (charCode > 31 && (charCode < 48 || charCode > 57))
			// (charCode >= 65 && charCode <= 90) ||
			// (charCode >= 97 && charCode <= 122)
			[
				"a",
				"b",
				"c",
				"d",
				"e",
				"f",
				"g",
				"h",
				"i",
				"j",
				"k",
				"l",
				"m",
				"n",
				"o",
				"p",
				"q",
				"r",
				"s",
				"t",
				"u",
				"v",
				"w",
				"x",
				"y",
				"z",
				`!`,
				`"`,
				`#`,
				`$`,
				`%`,
				`&`,
				`'`,
				`(`,
				`)`,
				`*`,
				`+`,
				`,`,
				`-`,
				`.`,
				`/`,
				`:`,
				`;`,
				`<`,
				`=`,
				`>`,
				`?`,
				`@`,
				" ",
				// `[`,
				// `\`,
				// `]`,
				// `^`,
				// `_`,
				// ```,
				// 	`{`,
				// 	`|`,
				// 	`}`,
				// 	`~`,
			].includes(event.key)
		) {
			return false;
		}
		return true;
	}

	onUploadOutput(output: UploadOutput, c: string): void {
		switch (output.type) {
			case "allAddedToQueue":
				let file = c === "icon" ? this.iconFile : this.backroundFile;

				let name = file ? file.name : new Date().getTime() + ".jpeg";
				let reader = new FileReader();
				name = this.model.name.replace(/\s/g, "") + name;
				this.getBase64fromFile(file.nativeFile).then(
					(d: base64Response) => {
						const headers = new HttpHeaders().set(
							"Content-Type",
							"multipart/form-data"
						);
						headers.set("Accept", "application/json");
						// this.http.post(this.uploadUrl +name, d.body, { headers })
						//     .subscribe(
						//         (data: S3Response) => {
						//           if(c === 'icon') this.model.iconurl = data.Location;
						//           else this.model.backgroundurl = data.Location;
						//           this.toastr.success('File Uploaded');
						//         },
						//         error => { console.log('Error  in uoloaing file: '+error);}
						//     );
					}
				);
				break;
			case "addedToQueue":
				if (typeof output.file !== "undefined") {
					if (c === "icon") this.iconFile = output.file;
					else this.backroundFile = output.file;
				}
				break;
			case "uploading":
				if (typeof output.file !== "undefined") {
					// update current data in files array for uploading file
					const index = this.files.findIndex(
						(file) =>
							typeof output.file !== "undefined" &&
							file.id === output.file.id
					);
					this.files[index] = output.file;
				}
				break;
			case "removed":
				// remove file from array when removed
				this.files = this.files.filter(
					(file: UploadFile) => file !== output.file
				);
				break;
			case "dragOver":
				this.dragOver = true;
				break;
			case "dragOut":
			case "drop":
				this.dragOver = false;
				break;
			case "done":
				// console.log(output.file.response.Location);
				// if(c === 'icon') {this.model.iconurl = output.file.response.Location; }
				// else { this.model.backgroundurl = output.file.response.Location; }
				// // The file is downloaded
				// ////////////////////////////////////////////////////////////////////////
				// if((this.model.files === null || this.model.files.length === 0) ||
				// this.model.files.find(f => f.upload_type == (c=== 'icon' ? 0:1)) === undefined){
				//   this.model.files.push({ id: null, created_at: new Date(), uploadable_type:'Project', uploadable_id : this.model.id,
				// upload_type: (c=== 'icon' ? 0:1),url: output.file.response.Location, is_deleted: false, updated_at: new Date() });
				// }//end if new
				// else{
				//   this.model.files.find(f => f.upload_type == (c=== 'icon' ? 0:1)).url = output.file.response.Location;
				// }
				break;
		}
	}

	startUpload(): void {
		const event: UploadInput = {
			type: "uploadAll",
			url: this.uploadUrl,
			method: "POST",
			data: { name: "bar.jpeg" },
		};

		this.uploadInput.emit(event);
	}

	cancelUpload(id: string): void {
		this.uploadInput.emit({ type: "cancel", id: id });
	}

	removeFile(id: string): void {
		this.uploadInput.emit({ type: "remove", id: id });
	}

	removeAllFiles(): void {
		this.uploadInput.emit({ type: "removeAll" });
	}

	getBase64fromFile(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				console.log(`getBase64fromFile success.`);
				const spliced = reader.result.toString().split(",");
				const header = spliced[0];
				spliced.shift();
				resolve({
					header: header,
					body: spliced.join(""),
				});
			};
			reader.onerror = (err) => {
				console.log(`getBase64fromFile failed.`);
				reject(err);
			};
		});
	}
	back() {
		this.router.navigate(["/workers"]);
	}
	postForm() {
		if (!this.model.city_id) {
			this.toastr.error(
				"City is required please select a city from the cities dropdown!"
			);
		}

		if (this.model.phone_number.length < this.maxContactLength) {
			this.toastr.error(
				`Phone number must be at least ${this.maxContactLength}  digits long including + character and country code.`
			);
		} else {
			this.workerService
				.saveWorker(this.model)
				.then((res: ResponseType) => {
					if (res.response_code === 200) {
						this.router.navigate(["/workers"]);
					}
				})
				.catch((error) => {
					console.log({ error });
					this.toastr.error(error.message);
				});
		}
	}
}
