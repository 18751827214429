import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./../../auth/auth.guard";
import { SurveysComponent } from "./components/surveys/surveys.component";

const routes: Routes = [
	{
		path: "",
		component: SurveysComponent,
		canActivate: [AuthGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class SurveysRoutingModule {}
