import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "src/app/auth/auth.guard";
import { PaymentsComponent } from "./components/payments/payments.component";

const routes: Routes = [
	{
		path: "",
		component: PaymentsComponent,
		canActivate: [AuthGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class PaymentsRoutingModule {}
