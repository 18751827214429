// import { ProjectService } from './../services/projectService';
import { ProjectService } from "src/app/services/projectService";
import { RewardService } from "../service/rewardService";
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
// import { Reward, Sprint } from '../model/sprint';
import { Sprint, Reward } from "src/app/modules/sprints/model/sprintModel";
import { ToastrService } from "ngx-toastr";

@Component({
	selector: "app-rewards",
	templateUrl: "./rewards.component.html",
	styleUrls: ["./rewards.component.css"],
})
export class RewardsComponent implements OnInit {
	amount = 10;
	currency = 10;
	message: any = "";
	model: Reward = null;
	_sprint: Sprint = null;
	id: number = 0;
	@Input() sprintId: number;
	@Output("RewardSaved") valueChange: EventEmitter<Reward> =
		new EventEmitter<Reward>();

	@Input() rows: any[];
	//  @Input('Sprint')
	//   set sprint(value: Sprint) {
	//     this._sprint = value;
	//     //this.valueChange.emit(this._model);
	//   }

	constructor(
		private rewardService: RewardService,
		private toastr: ToastrService
	) {}

	ngOnInit() {
		this.getReward();
	}
	getReward() {
		if (this.id === 0 || this._sprint.rewards == null)
			this.model = {
				id: 0,
				title: "",
				project_id: this.sprintId,
				is_shown: true,
				amount: 0,
				currency_id: 10,
				created_at: new Date(),
				updated_at: new Date(),
				is_deleted: false,
			};
		else return this._sprint.rewards.find((r) => r.id === this.id);
	}
	postForm() {
		if (this.model.title == "") {
			var dismiss = document.getElementById("submitReward");
			dismiss.removeAttribute("data-dismiss");
			this.toastr.warning("Title is required");
			return false;
		}

		var dismiss = document.getElementById("submitReward");
		dismiss.setAttribute("data-dismiss", "modal");

		this.rewardService
			.saveReward(this.model)
			.then((data) => {
				this.model.id = data["id"];
				console.log("Saved.." + data);
				this.toastr.success("Saved Successfully!");
				this.valueChange.emit(this.model);
			})
			.catch((err) => {
				this.toastr.error("Error in saving reward");
				console.error(err);
			});
	}

	changeReward(value)
	{
		this.model = value;

	}

}
