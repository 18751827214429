import { Injectable } from "@angular/core";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { API } from "aws-amplify";

@Injectable({
	providedIn: "root",
})
export class SurveyService {
	constructor(private loader: LoadingBarService) {}
	public getAllUsersAndSprintsData(
		search: string,
		limit: number,
		page: number,
		startDate: string,
		endDate: string
	): Promise<any> {
		const apiName = "api_node_2";
		const path = `combineddata/?search=${
			search ? search : ""
		}&limit=${limit}&page=${page}&startDate=${startDate}&endDate=${endDate}`;

		console.log({ limit: limit, page });

		const myInit = {
			headers: {}, // OPTIONAL
			response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
			queryStringParameters: {},
		};
		const promise = new Promise((resolve, reject) => {
			this.loader.start();
			API.get(apiName, path, myInit)
				.then((response) => {
					this.loader.complete();
					resolve(response.data.data);
				})
				.catch((error) => {
					this.loader.complete();
					console.log("Error in invoiking API:" + error);
					reject(error);
				});
		});
		return promise;
	}
}
