import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SubmissionReqService } from "../../service/submissionReqService";

@Component({
	selector: "app-multimedia-model",
	templateUrl: "./multimedia-model.component.html",
	styleUrls: ["./multimedia-model.component.css"],
})
export class MultimediaModelComponent implements OnInit {

	// @Input()
	// get Files(): any {
	// 	return this._Files;
	// }
	// set Files(value: any) {
	// 	console.log(value);
	// 	this._Files =this.subService.mediaFile;
	// 	this.fileUrl= null;
	// 	if (value) {
	// 		this._Files = value || '';
	// 		this.fileUrl = value.url || '';
	// 		this.setAudioUrl(value);
	// 	}
	// }
	constructor(public subService: SubmissionReqService, public modal: NgbActiveModal) {
		// console.log(this.Files);
	}

	Files;
	fileUrl;
	ngOnInit() {
		this.Files = this.subService.mediaFile || '';
		this.fileUrl = this.subService.mediaFile.url || '';
		this.setAudioUrl(this.subService.mediaFile);
	}

	async setAudioUrl(file)
	{
		if (file.type == 32) {
			let audio:any = await document.getElementById("audioPlayer");
			if (audio) {
				audio.currentTime  = 0;
			}
		} else if (file.type == 33) {
			let video:any = await document.getElementById("video-container");
			if (video) {
				video.currentTime  = 0;
			}
		}
	}

	closeMultimediaModal()
	{
		if (this.Files.type == 32) {
			let audio:any = document.getElementById("audioPlayer");
			console.log("enetr audio");

			audio.pause();
		}
		this.subService.mediaFile= null;
	}
}
