
import { BulkEvaluation } from '../model/PaymentsRequestForm';
import { PaymentRequestItem } from '../model/PaymentsRequestItem';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PagedData } from 'src/app/model/paged-data';
import { Page } from 'src/app/modules/sprints/components/submissionFile/model/pageSubmissionModel';
import Amplify, { API, Auth } from 'aws-amplify';
import aws_exports from '../../../../aws-exports';

import { AuthService } from 'src/app/auth/auth.service';
import { ToastrService } from 'ngx-toastr';

/**
 * A server used to mock a paged data result from a server
 */
@Injectable({providedIn: "root"})
export class PaymentsRequestService {
    constructor(private auth: AuthService, public toastr: ToastrService) {

    }
    /**
     * A method that mocks a paged server response
     * @param page The selected page
     * @returns {any} An observable containing the employee data
     */
    public getResults(page: Page, search?: any): Observable<PagedData<PaymentRequestItem>> {
        return of(this.getPagedData(page, search));
    }


    public getResult(page: Page, email: string, status: number, from: Date, to: Date, keyword: string): Promise<any> {
      const pagedData = new PagedData<PaymentRequestItem>();
      const apiName = 'api_node_1';
      const s = status ? '&status=' + status : '';
      if(page.size === null || page.size === undefined) page.size = 100;
      let path;
      if (status == null && keyword == null) {
         path = `paymentrequests?limit=${page.size}&offset=${page.pageNumber * page.size}`;
      }
      else if (status != null && keyword != null) {
        keyword = keyword.toString();
        path = `paymentrequests?limit=${page.size}&offset=${page.pageNumber * page.size}&status=${status}&keyword=${keyword}`;
      }
      else if (status != null && keyword == null) {
        path = `paymentrequests?limit=${page.size}&offset=${page.pageNumber * page.size}&status=${status}`;
      }
      else if (status == null && keyword != null) {
        keyword = keyword+"";
        console.log(keyword)
        path = `paymentrequests?limit=${page.size}&offset=${page.pageNumber * page.size}&keyword=${keyword}`;
      }
      const myInit = {
            headers: {},
            response: true,
            queryStringParameters: {}
          };
      const promise = new Promise((resolve, reject) => {
          API.get(apiName, path, myInit)
          .then(response => {
            
            if (response.data.response_code == 200) {
              const data = response.data.data.data;
              console.log('data=>' ,  data);
              page.totalElements = response.data.data.count;
              page.totalPages = page.totalElements / page.size;
              const start = page.pageNumber * page.size;
              const end = Math.min((start + page.size), page.totalElements);
              pagedData.page = page;
              pagedData.data = data;
              resolve(pagedData);
            }
            else
            {
              return this.toastr.error(response.data.message);
            }
          })
          .catch(error => {
            this.toastr.error('Error in invoiking API:' + error);
            reject(error);
          });
      }); // end promoise
      return promise;
    } // end getresult

    public savePaymentRequestForm(form: BulkEvaluation) {
      const apiName = 'api_node_1';
      const path = `paymentrequests`;
      const myInit = {
        body: form,
        headers: {}
    };
      const promise = new Promise((resolve, reject) => {
        API.put(apiName, path, myInit)
          .then(response => {
            const data = response.data;
            resolve('OK');
          })
          .catch(error => {
            console.log(error.response);
            reject(error);
          });
      }); // end promoise
      return promise;
    }

    /**
     * Package PaymentRequestItem into a PagedData object based on the selected Page
     * @param page The page data used to get the selected data from PaymentRequestItem
     * @returns {PagedData<PaymentRequestItem>} An array of the selected data and page
     */
    private getPagedData(page: Page, search): PagedData<PaymentRequestItem> {
      const pagedData = new PagedData<PaymentRequestItem>();
      const apiName = 'api_node_1';
      const path = `paymentrequests?email=${search}&limit=${page.size}&offsert=${page.pageNumber * page.size}`;
      const myInit = {
            headers: {},
            response: true,
            queryStringParameters: {}
          };
      API.get(apiName, path, myInit)
      .then(response => {
        const data = response.data;
        page.totalElements = data.count;
        page.totalPages = page.totalElements / page.size;
        const start = page.pageNumber * page.size;
        const end = Math.min((start + page.size), page.totalElements);
        pagedData.data = data.data;
      })
      .catch(error => {
        console.log('Error in invoiking API:' + error);
      });
        pagedData.page = page;
        return pagedData;
    }

}
