// import { TagService } from '../../services/tag.service';
import { Component, OnInit } from "@angular/core";
import { BreadCrumb } from "src/app/model/BreadCrumb";
import { TagService } from "src/app/services/tag.service";
// import { Task, Sprint, Question, Form,Type } from 'src/app/model/sprint';
import { Form, Question, Sprint, Type } from "../../../model/sprintModel";
// import { Page } from '../../model/page';
// import { ProjectService } from '../../services/projectService';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SprintService } from "src/app/modules/sprints/service/sprintService";
import { GoogleObj, GoogleService } from "src/app/services/google.services";

@Component({
	selector: "app-task-form",
	templateUrl: "./taskform-detail.component.html",
	styleUrls: ["./taskform-detail.component.css"],
})
export class TaskFormComponent implements OnInit {
	formData: FormData;
	humanizeBytes: Function;
	dragOver: boolean;
	submitted = false;
	Sprint: Sprint = null;
	loading = true;
	email = null;
	id: number = null;
	projectId: number = null;
	model: Form = null;
	Questions: Question[] = null;
	Question: Question;
	crumbs: BreadCrumb[];
	sprintTags = [];
	sprintList = [];
	taskFormList = [];
	taskSprintId = null;
	questionSprintId = null;
	sprintTaskFormtId = null;
	questionId = null;
	public googleObj: GoogleObj = new GoogleObj();

	constructor(
		private projectService: SprintService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private tagService: TagService,
		private _google: GoogleService
	) {}

	ngOnInit() {
		this.activatedRoute.queryParams.subscribe((params) => {
			this.projectId = +this.activatedRoute.snapshot.paramMap.get("id");
			this.id = +this.activatedRoute.snapshot.paramMap.get("form");
			this.GetSprint();
			this.getTages();
			this.getSprintLists();
		});
	} //end on init

	private GetSprint(id?: number) {
		if (id) {
			this.model.id = id;
			this.id = id;
			// console.log(
			// 	"The model id is:: " + this.model.id + " and the id is: " + id
			// );
		}
		this.projectService
			.getSprint(this.email, this.projectId)
			.then((sprint) => {
				this.Sprint = sprint;
				if (this.id > 0) {
					this.model = this.Sprint.forms.find(
						(t) => t.id === this.id
					);
					this.Questions = this.model.questions.sort((q1, q2) => {
						if (q1.position > q2.position) {
							return 1;
						}
						if (q1.position < q2.position) {
							return -1;
						}
						return 0;
					});
					this.projectService.parentQuestionTagID =
						this.model.tag_question_parent;
					this.model.questions = this.Questions;
					console.log("update ", this.model.questions);
					this.projectService.getAllQuestions = this.model.questions;
				} //end id is greater than 0
				else {
					this.model = {
						id: null,
						title: "",
						project_id: this.Sprint.id,
						created_at: new Date(),
						updated_at: new Date(),
						complete_duration: 10,
						titlear: "",
					};
				}
				this.buildBreadCrumb();
				this.loading = false;
			});
	}

	onSubmit() {
		this.submitted = true;
	}

	getClass(id) {
		return this.QuestionTypes.find((qt) => qt.id === id).class;
	}

	buildBreadCrumb() {
		//Sprints? Sprint()>Task Forms ()
		this.crumbs = [];
		this.crumbs.push(new BreadCrumb("Home", "/"));

		this.crumbs.push(new BreadCrumb("Sprints", "/sprints"));
		this.crumbs.push(
			new BreadCrumb(this.Sprint.name, "/sprints/" + this.Sprint.id)
		);
		this.crumbs.push(
			new BreadCrumb(
				this.model.title ? this.model.title : "Task Form",
				""
			)
		);
	}

	addQuestion() {
		let p = 1;
		if (this.model.questions === null || this.model.questions === undefined)
			this.model.questions = [];
		if (this.model.questions.length > 0)
			p = this.model.questions.length + 1;

		this.Question = {
			files: [],
			name: "",
			task_form_id: this.model.id,
			question_type: null,
			question_category: 0,
			is_visible_client: true,
			max_answer_points: 0,
			constraints: {
				is_required: true,
				limit: { type: Type.Character, min: 10 },
				precondition: [],
				termination: [],
				jumps: [],
				validations: { minOpt: null, maxOpt: null },
				logic_selection: "row_select",
				files: {
					type: "",
					limit: 0,
					size: 0,
					duration: 0,
				},
			},
			description: "",
			id: 0,
			opts: null,
			label: null,
			labelar: null,
			position: p,
			answer_points: 0,
			is_required: true,
			is_deleted: false,
			is_random: false,
			jump_condition: null,
			terminate_condition: null,
			multiple_jump_condition: null,
			multiple_terminate_condition: null,
			is_cu_child: false,
			cu_parent_id: null,
			min_field_value: 1,
			max_field_value: 1,
			created_at: new Date(),
			updated_at: new Date(),
			question_image: null,
			is_dd_child: false,
			dd_parent_id: 0,
			is_tag_parent: true,
			is_tag_child: false,
			tag_condition: [],
			sprint_id: this.projectId,
		};
		console.log(this.Question);
	}

	postForm() {
		if (this.model.title == "") {
			this.toastr.error("Please add the English Name");
			return false;
		}
		if (this.model.titlear == "") {
			this.toastr.error("Please add the Arabic Title");
			return false;
		}
		this.projectService
			.saveForm(this.model)
			.then((data: any) => {
				//this.activatedRoute.snapshot.paramMap('form')
				this.model.id = data.id;
				console.log("Saved..", data);
				if (+this.activatedRoute.snapshot.paramMap.get("form") === 0) {
					const queryParams: Params = { form: this.model.id };
					// this.router.navigateByUrl('/sprints/'+this.model.project_id+'/taskform/'+this.model.id);
					// this.router.navigate(
					//   [],
					//   {
					//     relativeTo: this.activatedRoute,
					//     queryParams: queryParams,
					//     queryParamsHandling: 'merge', // remove to replace all query params by provided
					//   });
				}
				//  this.back();
			})
			.catch((err) => {
				this.toastr.error("Error in saving form");
				console.error(err);
			});
		//this.back();
	} //end post form

	back() {
		this.router.navigate(["sprints", this.Sprint.id]);
	}
	questionSaved(question) {
		//this.toastr.info(JSON.stringify(question));
		if (this.model.questions === undefined || this.model.questions === null)
			this.model.questions = [];
		if (question.id != null && question.id !== 0)
			this.model.questions.splice(
				this.model.questions.findIndex((q) => q.id === question.id),
				1,
				question
			);
		else this.model.questions.push(question);
		console.log("The question has been added:" + JSON.stringify(question));
		this.GetSprint(this.model.id);
		// this.GetSprint();
	}

	listSorted(list: any) {
		this.model.questions = [];
		for (let x = 0; x < list.length; x++) {
			list[x].position = x + 1;
			this.model.questions.push(list[x]);
		}
	}

	/////////////////////////////////////////////////////////////////////////////////////////////
	QuestionTypes = [
		{
			id: 0,
			name: "MultiChoice",
			namear: "MultiChoice",
			class: "far fa-check-square",
		},
		// { 'id': 1, 'name':'DropDownList', 'namear':'DropDownList', 'class':'fas fa-caret-square-down'},
		{ id: 2, name: "Number", namear: "Number", class: "fas fa-keyboard" },
		{
			id: 3,
			name: "Paragraph",
			namear: "Paragraph",
			class: "fas fa-paragraph",
		},
		{
			id: 4,
			name: "SingleSelection",
			namear: "SingleSelection",
			class: "fas fa-caret-square-down",
		},
		{
			id: 28,
			name: "LogicMatrix",
			namear: "LogicMatrix",
			class: "fa fa-table",
		},
		// { 'id': 29, 'name':'Slider Input', 'namear':'SliderInput', 'class':'fas fa-sliders-h'},
		// { 'id': 30, 'name':'Horizontal Scale', 'namear':'HorizontalScale', 'class':'fas fa-minus'},
		{
			id: 31,
			name: "Image Upload",
			namear: "رفع الصور",
			class: "fas fa-image",
		},
		{
			id: 32,
			name: "Audio Upload",
			namear: "تحميل الصوت",
			class: "fas fa-microphone",
		},
		{
			id: 33,
			name: "Video Upload",
			namear: "تحميل الفيديو",
			class: "fas fa-video",
		},
		{
			id: 34,
			name: "Current Location",
			namear: "الموقع الحالي",
			class: "fas fa-map-marker-alt",
		},
	];

	getspecificQuestion(item) {
		this.GetSprint();
		console.log({ item });
		this.Question = item;
	}

	translate() {
		this.doTranslate(this.model.title).then((d) => {
			this.model.titlear = d.toString();
		});
	}

	doTranslate(key) {
		return new Promise((resolve, reject) => {
			this._google.translate(this.googleObj, key).subscribe(
				(res: any) => {
					resolve(res.data.translations[0].translatedText);
				},
				(err) => {
					console.log(err);
					reject(err);
				}
			);
		});
	}

	numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 45 || charCode > 57)) {
			return false;
		}
		return true;
	}

	getTages() {
		this.projectService.getSprintTags(this.projectId).then(
			(tags) => {
				this.sprintTags = tags;
			},
			(err) => {
				this.toastr.error(err.message);
			}
		);
	}

	getSprintLists() {
		this.projectService.sprintLists().then((sprints) => {
			this.sprintList = sprints;
		});
	}

	dublicateData(type) {
		const taskFormId = type == 0 ? this.id : this.sprintTaskFormtId;
		const sprintId = type == 0 ? this.taskSprintId : this.questionSprintId;
		const question = type == 0 ? null : this.questionId;

		const request = {
			project_id: sprintId,
			task_form_id: taskFormId,
			type: type,
			question_id: question,
		};
		if (type == 1 && !question) {
			return this.toastr.error(
				"Please Select the question in case of question dublicate"
			);
		}
		if (sprintId && taskFormId) {
			this.projectService
				.dublicateTaskFormData(request)
				.then((data: any) => {
					// this.toastr.success('Success');
					this.sprintTaskFormtId = null;
					this.taskSprintId = null;
					this.questionSprintId = null;
					this.questionId = null;

					this.GetSprint();
				})
				.catch((err) => {
					this.toastr.error("Error in saving form");
					console.error(err);
				});
		} else {
			this.toastr.error(
				sprintId ? "Select the task form" : "Select the sprint"
			);
		}
	}

	changeTaskSprint(event) {
		console.log(event[0].value);

		const sprintList = this.sprintList.filter((sprint) => {
			return sprint.id == event[0].value;
		});

		this.taskFormList = sprintList[0].task_forms;
		console.log("this.taskFormList ==>", this.taskFormList);
	}
}
