
import { Component, OnInit } from '@angular/core';
import { Page} from '../model/page';
import { WorkerItem} from '../model/WorkerItem';
import { WorkerService } from './../services/workerService';
import { CsvModule } from '@ctrl/ngx-csv';

@Component({
  selector: 'app-users',
   templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {


  page = new Page();
  rows = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  search = '';
  users: WorkerItem[]  = [];
  loading: any = true;
  constructor(private workerService: WorkerService) {
    this.page.pageNumber = 0;
    this.page.size = 20;
  }

  ngOnInit() {
       this.setPage({ offset: 0, pageSize:20 });

  } // end on init
  filter() {
    this.workerService.getResult(this.page, this.search).then(pagedData => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      this.loading = false;
    });
  }

  setPage(pageInfo) {
    console.log('Page Info:'+JSON.stringify(pageInfo) );
    this.page.size = pageInfo.pageSize;
    this.page.pageNumber = pageInfo.offset;
    //this.page
    this.workerService.getResult(this.page, this.search).then(pagedData => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      this.loading = false;
      this.loadingIndicator = false;
    });
  }
}
