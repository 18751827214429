import { BulkEvaluation } from '../../model/PaymentsRequestForm';
// import { PaymentRequestService } from '../../services/paymentRequestService';
import { PaymentsRequestService } from '../../service/paymentRequestService';
// import { PaymentRequestItem } from '../../model/PaymentsRequestItem';
import { PaymentRequestItem } from '../../model/PaymentsRequestItem';
import { WorkersService } from 'src/app/modules/workers/service/workersService';
import { Component, OnInit, ViewChild } from '@angular/core';
// import { Page} from '../model/page';
// import { Page } from 'src/app/submissionFile/model/pageSubmissionModel';
import { Page } from 'src/app/modules/sprints/components/submissionFile/model/pageSubmissionModel';
import { CsvModule } from '@ctrl/ngx-csv';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-paymentrequests',
   templateUrl: './paymentrequests.component.html',
  styleUrls: ['./paymentrequests.component.css']
})
export class PaymentRequestsComponent implements OnInit {
  page = new Page();
  selected = [];
  message = '';
  range = "0";
  search = '';
  expanded: any = {};
  @ViewChild('myTable') table: any;
  status = null;
  newstatus = 0;
  from  =  null;
  to = null;
  rows = [];
  temp = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  email = null;
  loading: any = true;
  sortcolumn = 'id';
  sortorder = 'desc';
  name = null;
  phone = null;
  agel  = null;
  ageg = null;
  userid = null;
  country = null;
  workertype = null;
  city = null;
  showAdvanced = false;
  nationalityid = null;
  device = null;
  userInfo = [];
  constructor(private workerService: WorkersService, private paymentRequestService: PaymentsRequestService, private toastr: ToastrService) {
    this.page.pageNumber = 0;
    this.page.size = 100;
  }

  ngOnInit() {
    this.setPage({ offset: 0, size: 100 }, null, null);

  } // end on init

  // filter() {
  //   this.userInfo = JSON.parse(localStorage.getItem('todoitems'));
  
  //   this.paymentRequestService.getResult(this.page, this.email, (this.status == -1 ? null : this.status),
  //                                          this.from, this.to).then(pagedData => {
  //     this.page = pagedData.page;
  //     this.rows = pagedData.data;
  //     this.loadingIndicator = false;
  //   });
  // }

  filter() {

    
    this.selected = []
    this.setPage({ offset: 0, size: this.page.size }, this.status, this.search);
    // this.page.size = this.page.size;
    // this.page.pageNumber = this.page.pageNumber;
    // this.workerService.getFullSearch(this.page, this.search, this.name, this.email , this.phone, this.nationalityid,
    //   // tslint:disable-next-line:max-line-length
    //   this.country, this.city, this.userid, this.status, this.agel, this.ageg, this.workertype, this.sortcolumn, this.sortorder, this.device).then(pagedData => {
    //   this.page = pagedData.page;
    //   this.rows = pagedData.data;
    //   this.loading = false;
    //   this.loadingIndicator = false;

    // });
  }
  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }
  getStatus(status){
    if(status===0) return '<span class="badge badge-warning">Requested</span>';
    if(status===1) return '<span class="badge badge-success">Approved</span>';
    if(status===2) return '<span class="badge badge-danger">Rejected</span>';
    if(status===3) return '<span class="badge badge-primary">Paid</span>';

    return '<span class="badge badge-info">-</span>';
  }
  setDateRange() {
    let dt = new Date();
    let month = dt.getMonth(), year = dt.getFullYear();
    if(this.range === "0" ) this.from = new Date(year, month, 1);
    if(this.range === "0" ) this.to = new Date(year, month + 1, 0);
    if(this.range === "1" ) this.from = new Date(year, month -1, 1);
    if(this.range === "1" ) this.to = new Date(year, month + 1, 0);
    if(this.range === "2" ) this.from = new Date(year, month -3, 1);
    if(this.range === "2" ) this.to = new Date(year, month + 1, 0);
    if(this.range === "3" ) this.from = new Date(year, 1, 1);
    if(this.range === "3" ) this.to = new Date(year + 1,1, 0);
    this.filter();
  }
  postForm() {

    let ids = [];
    this.selected.map(s => ids.push(s.id));
    let user_id = localStorage.getItem("admin_id");
    let form = new BulkEvaluation(ids, this.newstatus, this.message, Number(user_id), null);
    this.paymentRequestService.savePaymentRequestForm(form).then((d)=>{
      this.toastr.success('Operation Completed Successfully!');
      this.setPage({ offset: 0, size: 100 }, null, null);
	  this.selected = [];
      // this.filter();
    });

  }
  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);

	// this.selected = [];
    // this.selected.splice(0, this.selected.length);
    this.selected = selected;
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }
  setPage(pageInfo, status, keyword) {
    console.log(pageInfo );
    this.page.size = pageInfo.size;
    this.page.pageNumber = pageInfo.offset;
    if (keyword == "") {
      keyword = null;
    }
    console.log("keyword",keyword)
    console.log("status",status)
    this.paymentRequestService.getResult(this.page, this.email,  status,
            this.from, this.to, keyword).then(pagedData => {
              console.log("response data ==>",pagedData)
      this.page = pagedData.page;
      this.rows = pagedData.data;
      console.log(this.rows)
      this.loadingIndicator = false;
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;

  }
}
