// import { Sprint } from './../model/sprint';
import { Component, OnInit } from "@angular/core";
// import { Page} from '../model/page';
// import { Page } from 'src/app/model/page';
import { Page } from "src/app/modules/sprints/components/submissionFile/model/pageSubmissionModel";
// import { ProjectService } from './../services/projectService';
import { ToastrService } from "ngx-toastr";
import { SprintService } from "../../service/sprintService";

@Component({
	selector: "app-sprint-list",
	templateUrl: "./sprint-list.component.html",
	styleUrls: ["./sprint-list.component.css"],
})
export class SprintListComponent implements OnInit {
	allowed = true;
	page = new Page();
	rows = [];
	loadingIndicator: boolean = true;
	reorderable: boolean = true;
	search = "";
	loading: any = true;
	range = "0";
	expanded: any = {};
	status = 1;
	newstatus = 1;
	country = null;
	from = null;
	to = null;
	account = null;
	sort = null;
	innerWidth = 0;
	searchCount = 0;

	constructor(
		private sprintService: SprintService,
		public toastr: ToastrService
	) {
		this.page.pageNumber = 0;
		this.page.size = 30;
	}

	ngOnInit() {
		this.innerWidth = window.innerWidth;
		this.setPage({ offset: 0, pageSize: 30 });
	}

	setPage(pageInfo) {
		this.page.size = pageInfo.pageSize;
		this.page.pageNumber = pageInfo.offset;
		this.loadingIndicator = true;
		this.filter();
	}
	filter() {
		if (this.search && this.searchCount === 0) {
			this.searchCount = this.searchCount + 1;
			this.page.pageNumber = 0;
		}
		this.loadingIndicator = true;
		this.sprintService
			.getResult(
				this.page,
				null,
				this.search.trim(),
				this.country,
				this.account,
				this.status
			)
			.then((pagedData) => {
				this.page = pagedData.page;
				if (!this.page.size) this.page.size = 30;
				if (pagedData.data.response_code == 200) {
					let record = pagedData.data.data.data;
					this.rows = record;
					this.loading = false;
					this.loadingIndicator = false;
					console.log(this.rows);
				} else if (pagedData.data.response_code == 422) {
					this.loading = false;
					this.loadingIndicator = false;
					return this.toastr.error(pagedData.data.message);
				} else if (pagedData.data.response_code == 500) {
					this.loading = false;
					this.loadingIndicator = false;
					return this.toastr.error(pagedData.data.message);
				}
			});
	}

	setDateRange() {
		let dt = new Date();
		let month = dt.getMonth(),
			year = dt.getFullYear();
		if (this.range === "0") this.from = new Date(year, month, 0);
		if (this.range === "0") this.to = new Date(year, month + 1, 0);
		if (this.range === "1") this.from = new Date(year, month - 1, 1);
		if (this.range === "1") this.to = new Date(year, month + 1, 0);
		if (this.range === "2") this.from = new Date(year, month - 3, 1);
		if (this.range === "2") this.to = new Date(year, month + 1, 0);
		if (this.range === "3") this.from = new Date(year, 1, 1);
		if (this.range === "3") this.to = new Date(year + 1, 1, 0);
		//console.log(this.range, this.from, this.to);
		this.filter();
	}

	onSort(event) {
		// event was triggered, start sort sequence
		console.log("Sort Event", event);
		this.loading = true;
		setTimeout(() => {
			this.loading = false;
		}, 1000);
	} //end sort

	getWidth(required, completed) {
		// console.log("x ==>",required,completed)
		// let width = 0;
		// if((completed*1) > 0) {width = (required*1)/(completed*1);}
		// if(width>100 ) width=100;
		// width = Math.ceil(width);
		// return width;

		let width = 0;
		if (completed * 1 > 0) {
			width = ((completed * 1) / (required * 1)) * 100;
		}
		if (width > 100) width = 100;
		width = Math.ceil(width);
		return width;
	}
	getWidthTotals(submitted, accepted, rejected, a) {
		let width = 0;
		let total = accepted * 1 + rejected * 1 + submitted * 1;
		if (total === 0) return 0;
		if (a === 1) width = submitted / total; //;
		if (a === 2) width = accepted / total; //;
		if (a === 3) width = rejected / total; //;

		width = Math.ceil(width * 100);
		return width;
	}
	getCompletion(required, completed) {
		let html = "";
		let width = 0;
		if (completed * 1 > 0) {
			width = (required * 1) / (completed * 1);
		}
		if (width > 100) width = 100;
		width = Math.ceil(width);
		// html       = `<div class="w3-light-grey">
		//                 <div class="w3-container w3-green w3-center" style='width:${width}%'>${width}%</div>
		//               </div>`;
		html =
			'<div class="progress"><div class="progress-bar" role="progressbar" style="width:' +
			width +
			'%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div></div>';
		return html;
	}

	getStatus(status) {
		if (status === 0 || status === "Draft")
			return '<span class="badge badge-secondary">Draft</span>';
		if (status === 1 || status === "Available")
			return '<span class="badge badge-success">Available</span>';
		if (status === 2 || status === "NotAvailable")
			return '<span class="badge badge-warning">Not Available</span>';
	}
}
