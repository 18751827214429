import { WorkerItem } from './../model/WorkerItem';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PagedData } from '../model/paged-data';
import { Page } from '../model/page';
import Amplify, { API, Auth } from 'aws-amplify';
import aws_exports from './../../aws-exports';
import { AuthService } from '../auth/auth.service';
import { TransactionItem } from '../model/transactionItem';
import { SubmisssionItem } from '../model/submissionItem';
import { BulkEvaluation } from '../model/PaymentRequestForm';
import { Submission } from '../model/tomtom';
import { LoadingBarService } from '@ngx-loading-bar/core';


/**
 * A server used to mock a paged data result from a server
 */
@Injectable()
export class SubmissionService {
  constructor(private auth: AuthService, public loader: LoadingBarService) {

  }
  /**
   * A method that mocks a paged server response
   * @param page The selected page
   * @returns {any} An observable containing the employee data
   */
  // public getResults(page: Page, email: string, worker_id: number, status:number, type: number ): Observable<PagedData<SubmisssionItem>> {
  //     return of(this.getPagedData(page, email, worker_id, status, type));
  // }
  public getById(email: string, id: number): Promise<any> {
    const apiName = 'api';
    const path = `submission?email=${email}&id=${id}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {
      API.get(apiName, path, myInit)
        .then(response => {
          const data = response.data;
          this.loader.complete();
          resolve(data);
        })
        .catch(error => {
          console.log('Error in invoiking API:' + error);
          reject(error);
        });
    }); // end promoise
    return promise;
  }
  public getResult(page: Page, email: any, worker_id: number, status: number, type: number, project: number, reportable: boolean, from: Date, to: Date): Promise<any> {
    const pagedData = new PagedData<SubmisssionItem>();
    if(page.size === null || page.size === undefined)page.size=100;
    const apiName = 'api';

    let s = (status ? '&status=' + status : '') + (worker_id ? '&worker=' + worker_id : '') +
      (project ? '&project=' + project : '') + (reportable ? '&reportable=' + reportable : '') +
      (from ? '&from=' + from : '') + (to ? '&to=' + to : '') + (type ? '&type=' + type : '');
    const path = `submissions?1=1${s}&limit=${page.size}&offset=${page.pageNumber * page.size}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    this.loader.start();

    const promise = new Promise((resolve, reject) => {
      API.get(apiName, path, myInit)
        .then(response => {
          const data = response.data;
          this.loader.complete();
          page.totalElements = data.count;
          page.totalPages = page.totalElements / page.size;
          const start = page.pageNumber * page.size;
          const end = Math.min((start + page.size), page.totalElements);
          pagedData.page = page;
          pagedData.data = data.data;
          resolve(pagedData);
        })
        .catch(error => {
          console.log('Error in invoiking API:' + error);
          reject(error);
        });
    }); // end promoise
    return promise;
  } // end getresult

  //   public getTomTomResults(page: Page,worker_id: number, status:number, project: number ): Observable<PagedData<Submission>> {
  //     return of(this.getTomtomPagedData(page, worker_id, status, project));
  // }

  public getTomtomResult(page: Page, worker_id: number, status: number, project: number, prod:boolean): Promise<any> {
    const pagedData = new PagedData<Submission>();
    const apiName = 'api';
    let s = (status ? '&status=' + status : '') + (worker_id ? '&worker=' + worker_id : '') + (project ? '&project=' + project : '');
    const path = `submissions/tomtom?1=1&prod=${prod}&sortorder=desc&sortcolumn=id${s}&limit=${page.size}&offset=${page.pageNumber * page.size}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {
      API.get(apiName, path, myInit)
        .then(response => {
          this.loader.complete();
          const data = response.data;
          page.totalElements = data.count;
          page.totalPages = page.totalElements / page.size;
          const start = page.pageNumber * page.size;
          const end = Math.min((start + page.size), page.totalElements);
          pagedData.page = page;
          pagedData.data = data.data;
          resolve(pagedData);
        })
        .catch(error => {
          console.log('Error in invoiking API:' + error);
          reject(error);
        });
    }); // end promoise
    return promise;
  }
  public getNearBy(longitude, latitude): Promise<any> {
    const apiName = 'geo';
    const path = `nearby?longitude=${longitude}&latitude=${latitude}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {

      API.get(apiName, path, myInit)
        .then(response => {
          this.loader.complete();
          resolve(response);
        })
        .catch(error => {
          this.loader.complete();
          console.log('Error in invoiking API:' + error);
          reject(error);
        });
    }); // end promoise
    return promise;
  }
  // private getPagedData(page: Page, email: string, worker_id: number, staus:number, type: number ): PagedData<SubmisssionItem> {
  //   const pagedData = new PagedData<SubmisssionItem>();
  //   const apiName = 'api';
  //   let s = (status ? '&status=' + status : '') + (worker_id ? '&worker='+worker_id: '')+(type ? '&type='+type: '');
  //   const path = `submissions?email=${email}${s}&limit=${page.size}&offset=${page.pageNumber * page.size}`;      const myInit = {
  //         headers: {}, // OPTIONAL
  //         response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  //         queryStringParameters: {}
  //       };
  //   API.get(apiName, path, myInit)
  //   .then(response => {
  //     const data = response.data;
  //     page.totalElements = data.count;
  //     page.totalPages = page.totalElements / page.size;
  //     const start = page.pageNumber * page.size;
  //     const end = Math.min((start + page.size), page.totalElements);
  //     pagedData.data = data.data;
  //   })
  //   .catch(error => {
  //     console.log('Error in invoiking API:' + error);
  //   });
  //     pagedData.page = page;
  //     return pagedData;
  // }
  public saveSubmissions(form: BulkEvaluation) {
    const apiName = 'api_node_1';
    const path = `submissions`;
    const myInit = {
      body: form,
      headers: {}
    };
    const promise = new Promise((resolve, reject) => {
      this.loader.start();

      API.post(apiName, path, myInit)
        .then(response => {
          const data = response.data;
          this.loader.complete();
          resolve('OK');
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }

  public saveTomtomSubmissions(form: BulkEvaluation, prod:boolean) {
    const apiName = 'api';
    const path = `submissions/tomtom/submissions?prod=${prod}`;
    const myInit = {
      body: form,
      headers: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then(response => {
          const data = response.data;
          this.loader.complete();
          resolve('OK');
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }
  public saveTomtomSubmission(id: number, status: number, agent_id: number, message: string,
    long: string, lat: string, name: string, namear: string, categoryid: number,
    subcategory: string, brand: string, openinghours: string, prod:boolean) {
    name = name.replace('\'', '');
    if(namear === null) namear='';
    namear = namear.replace('\'', '');
    const apiName = 'api';
    const path = `submissions/tomtom?submission_id=${id}&agent=${agent_id}&categoryid=${categoryid}&status=${status}&message=${message}&longitude=${long}&latitude=${lat}&longitude=${long}&name=${name}&namear=${namear}&telephone=${subcategory}&website=${brand}&openinghours=${openinghours}&prod=${prod}`;
    const myInit = {
      //   body: form,
      headers: {}
    };
    this.loader.start();

    const promise = new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then(response => {
          this.loader.complete();
          const data = response.data;
          resolve('OK');
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }
  public saveSubmission(id: number, agent_id: number, status: number) {
    const apiName = 'api';
    const path = `submission?submission=${id}&agent=${agent_id}&status=${status}`;
    const myInit = {
      //   body: form,
      headers: {}
    };
    const promise = new Promise((resolve, reject) => {
      this.loader.start();

      API.put(apiName, path, myInit)
        .then(response => {
          const data = response.data;
          this.loader.complete();
          resolve('OK');
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }



}
